import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { ServiceAdvisorService } from "src/app/services/service-advisor/service-advisor.service";
import { ServiceAdvisorModel } from "src/app/services/service-advisor/service-advisor.model";
import { ConfigService } from "src/app/services/config/config.service";
import { UserService } from "src/app/services/user-account/user/user.service";
import {
  BookingModelTitan,
  BookingService,
  TitanJobModel,
} from "src/app/services/booking/booking.service";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { ErrorService } from "src/app/services/error/error.service";

@Component({
  selector: "app-service-advisor",
  templateUrl: "./service-advisor.component.html",
  styleUrls: ["./service-advisor.component.less"],
})
export class ServiceAdvisorComponent implements OnInit {
  serviceAdvisorList: ServiceAdvisorModel[] = [];
  advisorIndex: number;
  showAdvisorImages: boolean;
  allowAnyServiceAdvisor: boolean;
  selectedServiceAdvisorId: string;
  anyServiceAdvisor: boolean;
  serviceAdvisorText: string;
  numberOfAdvisorsToDisplay = 3;
  switchAdvisorWorkflow: boolean;
  submitted: boolean;
  isRtl: boolean;
  isSingapore = false;
  singaporeBrandURl = "http://www.mini.com.sg/privacy-policy/"
  brand = "MINI"
  isTitan = false
  euroKarsDealer = "Habitat"
  validationRequiredKeyProp = "service-advisor-required"

  @Output() servicesSelected = new EventEmitter<string>();
  @Output() previousState = new EventEmitter<string>();

  constructor(
    private readonly serviceAdvisorService: ServiceAdvisorService,
    private readonly configService: ConfigService,
    private readonly userService: UserService,
    private readonly bookingService: BookingService,
    private readonly router: Router,
    private readonly sanitationService: DomSanitizer,
    private readonly translateService: TranslateService,
    private readonly errorService: ErrorService,


  ) {}

  ngOnInit() {
    this.isTitan = this.userService.selectedDealer.TitanDealer;
    this.isSingapore = this.configService.configSettings.language.indexOf('sg') > -1
  //  debugger
    if (this.configService.configSettings.oemId === "BMW" && this.isSingapore) {
      this.singaporeBrandURl =  "https://www.bmw.com.sg/en/footer/metanavigation/privacy-policy-pool/privacy-policy.html"
      this.brand = this.configService.configSettings.oemId;
      this.euroKarsDealer = "Auto"
    }
    this.isSingapore = this.configService.configSettings.language === "en_sg";
    this.switchAdvisorWorkflow =
      this.configService.configSettings.switchAdvisorWorkflow;
    this.getScreenSize();
    this.translateService
      .get("service-advisor")
      .subscribe((result) => (this.serviceAdvisorText = result));
    this.translateService.onLangChange.subscribe((result) => {
      this.isRtl = result.lang.includes('ar_');

      this.translateService
        .get("service-advisor")
        .subscribe((result) => (this.serviceAdvisorText = result));
    });

    if (this.configService.configSettings.switchAdvisorWorkflow) {
      this.userService.ServiceOptionsReady$.subscribe((dates) => {
        if (dates && this.serviceAdvisorList.length === 0) {
          this.getAvailableAdvisors("10:00-10:15");
          this.getAvailableAdvisors("08:00-08:15");
          this.getAvailableAdvisors("08:15-08:30");
          this.getAvailableAdvisors("08:45-09:00");
          this.getAvailableAdvisors("09:00-09:15");
          this.getAvailableAdvisors("09:30-09:45");
          this.getAvailableAdvisors("09:45-10:00");
          // Hack Jedda comes back with advisors on 3rd call.
          // Hack One of Dubais's dealers comes back with advisors on 4th or 5th call.
          this.getAvailableAdvisors("10:00-10:15");
          this.getAvailableAdvisors("11:00-11:15");
        }
      });
    } else {
      if (
        this.userService.selectedDealer &&
        this.userService.selectedDealer.TitanDealer
      ) {
        this.getAvailableAdvisorsTitan();
      } else {
        this.userService.SelectedServiceSlot$.subscribe((slot) => {
          if (slot) {
            this.getAvailableAdvisors();
          }
        });
      }
    }
  }

  getAvailableAdvisorsTitan() {
    this.allowAnyServiceAdvisor = this.configService.configSettings.anyAdvisor;
    this.showAdvisorImages = this.configService.configSettings.advisorImgs;
    this.serviceAdvisorService
      .getServiceAdvisorsTitan()
      .pipe(take(1))
      .subscribe((advisors) => {
        this.serviceAdvisorList = advisors;
        if (this.userService.serviceAdvisor) {
          this.selectAdvisor(this.userService.serviceAdvisor, true);
        }
      });
  }

  // getFirstAvailableServiceAdvisorTitan() {
  //   this.serviceAdvisorService.getFirstAvailableServiceAdvisor().pipe(take(1)).subscribe((advisor) => {
  //     // console.log("firstAva-->", advisor);

  //   })
  // }

  getAvailableAdvisors(timeSlot?: string) {
    this.allowAnyServiceAdvisor = this.configService.configSettings.anyAdvisor;
    this.showAdvisorImages = this.configService.configSettings.advisorImgs;
    this.serviceAdvisorService
      .getServiceAdvisors(0, timeSlot, false)
      .pipe(take(1))
      .subscribe((advisors) => {
        if (this.configService.configSettings.switchAdvisorWorkflow) {
          // HACK: we make two calls to this service for some reason and if the second one comes back with an empty list set advisor to that which is wrong
          this.serviceAdvisorList = this.serviceAdvisorList.concat(advisors);
          this.serviceAdvisorList = this.serviceAdvisorList.filter(
            (advisor, index) =>
              this.serviceAdvisorList.findIndex(
                (a) => a.advisorID === advisor.advisorID
              ) === index
          );
        } else {
          this.serviceAdvisorList = advisors;
        }

        if (this.userService.serviceAdvisor) {
          this.selectAdvisor(this.userService.serviceAdvisor, true);
        }

        if (this.showAdvisorImages) {
          if (!advisors.length) return;

          const advisorIds: string[] = [];
          advisors.forEach((advisor) => {
            advisorIds.push(advisor.advisorID);
          });

          const pictureSubscription = this.serviceAdvisorService
            .getAdvisorPictures(advisorIds)
            .subscribe((pictures) => {
              pictureSubscription.unsubscribe();
              pictures.forEach((x) => {
                const index = this.serviceAdvisorList.findIndex(
                  (y) => x.advisorId === y.advisorID
                );
                if (index > -1) {
                  this.serviceAdvisorList[index].advisorPictureData =
                    x.advisorPicture;
                  this.serviceAdvisorList[index].advisorPicture =
                    this.sanitationService.bypassSecurityTrustResourceUrl(
                      `data:image/jpg;base64,${x.advisorPicture}`
                    );
                }
              });
            });
        }
      });
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.numberOfAdvisorsToDisplay = window.innerWidth < 575 ? 2 : 3;
  }

  setAnyServiceAdvisor() {
    this.selectedServiceAdvisorId = null;
    this.serviceAdvisorList.forEach((x) => {
      x.selected = false;
    });
  }

  selectServiceAdvisor(advisorId) {
    this.anyServiceAdvisor = false;
    this.selectedServiceAdvisorId = advisorId;
    this.selectAdvisor(
      this.serviceAdvisorList.find((x) => x.advisorID === advisorId),
      false
    );
  }

  submit() {
    // debugger
    if (this.serviceAdvisorList.length === 0) {
      return;
    }
    this.submitted = true;
    if (this.isAdvisorSelected()) {
      this.userService.serviceAdvisor = this.serviceAdvisorList.filter(
        (advisor) => advisor.advisorID === this.selectedServiceAdvisorId
      )[0];
      if (
        this.userService.selectedDealer &&
        this.userService.selectedDealer.TitanDealer
      ) {
        const jobsList: TitanJobModel[] = [];
        let jobline = 1;
        this.userService.selectedServices.forEach((serviceItem) => {
          jobsList.push({
            ServiceOperationCodeId: serviceItem.id,
            Description: serviceItem.jobDescription,
            ServiceOperationCodeServiceCode: serviceItem.jobCode.toString(),
            JobLineId: jobline,
          });
          jobline++;
        });
        // debugger
        const model: BookingModelTitan = {
          Booking: {
            CustomerId: this.userService.userInfo.CustomerId,
            IsFromDMS: false,
            IsSendConfirmEmail: true,
            LocationId: this.userService.selectedLocation.Id,
            ServiceAdvisorId: parseInt(
              this.userService.serviceAdvisor.advisorID
            ),
            // ServiceAdvisorId: parseInt(
            //    "8"
            // ),
            ReservationToken: this.userService.reservationToken,
            VehicleId: this.userService.selectedVehicle.vehicleId,
            IsSendConfirmationBooking: true,
            ServiceAdvisor: {
              Id: parseInt(this.userService.serviceAdvisor.advisorID),
              // Id: 8, // parseInt(this.userService.serviceAdvisor.advisorID),
              LocationId: this.userService.selectedLocation.Id,
            },
            Jobs: jobsList,
          },
        };
        if (this.userService.appointmentIdToUpdate)
          model.Booking.Id = this.userService.appointmentIdToUpdate;
        this.bookingService
          .makeBookingTitan(model)
          .pipe(take(1))
          .subscribe((result) => {
            this.userService.wipNumber = result;
            this.router.navigateByUrl("/booking-confirmation");
          });
      } else {
        this.bookingService
          .updateBooking()
          .pipe(take(1))
          .subscribe(() => {
            this.bookingService
              .confirmBooking()
              .pipe(take(1))
              .subscribe((result) => {
                if (result) {
                  this.userService.wipNumber = result;
                  this.router.navigateByUrl("/booking-confirmation");
                } else {
                  this.errorService.show(992, false);
                }
              });
          });
      }
    }
  }

  next() {
    this.submitted = true;
    if (this.isAdvisorSelected()) {
      this.userService.serviceAdvisor = this.serviceAdvisorList.filter(
        (advisor) => advisor.advisorID === this.selectedServiceAdvisorId
      )[0];
      this.servicesSelected.emit("steps-service-advisor");
    }
  }

  previous() {
    this.previousState.emit("steps-service-advisor");
  }

  selectAdvisor(advisor: ServiceAdvisorModel, selectAdvisor: boolean) {
    this.serviceAdvisorList.forEach((x) => {
      x.selected = x.advisorID === advisor.advisorID;
    });
    if (selectAdvisor) this.selectServiceAdvisor(advisor.advisorID);
  }

  isAdvisorSelected() {
    return (
      this.anyServiceAdvisor ||
      this.serviceAdvisorList.filter((x) => x.selected).length > 0
    );
  }
}
