import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { CustomerInformationResult } from 'src/app/services/user-account/user/user.model';
import { ConfigService } from 'src/app/services/config/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.less']
})

export class ProfileComponent implements OnInit {
    userAccount: CustomerInformationResult;
    customerId: string;
    isSingapore = false;
    isRtl: boolean;
    singaporeBrandURl = "http://www.mini.com.sg/privacy-policy/";
    brand = "MINI";
    euroKarsDealer = "Habitat";
    constructor(private readonly userService: UserService, private readonly configService: ConfigService, private readonly translationService: TranslateService,) {
    }

    ngOnInit() {
      this.translationService.onLangChange.subscribe(result => {
        this.isRtl = result.lang.includes('ar_');
        // this.getNameSwitch(result.lang);
      });

            this.isSingapore = this.configService.configSettings.language.indexOf('sg') > -1

        if (this.configService.configSettings.oemId === "BMW" && this.isSingapore) {
            this.singaporeBrandURl =  "https://www.bmw.com.sg/en/footer/metanavigation/privacy-policy-pool/privacy-policy.html"
            this.brand = this.configService.configSettings.oemId;
            this.euroKarsDealer = "Auto"
          }
        // debugger
        this.isSingapore = this.configService.configSettings.language === "en_sg";
        this.customerId = this.userService.customerId;
        this.userAccount = this.userService.userInfo;
    }
}
