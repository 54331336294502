import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-bmw-font',
    template: '<div></div>',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./../../../styles/typography-bmw.less']
})
export class BMWFontComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}