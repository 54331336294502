import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user-account/user/user.service';
import { BookingService } from '../../../services/booking/booking.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/user-account/login/login.service';
import { DealerService } from '../../../services/dealer/dealer.service';
import { DealerModel } from '../../../services/dealer/dealer.model';

@Component({
  selector: 'app-select-dealer',
  templateUrl: './select-dealer.component.html',
  styleUrls: ['./select-dealer.component.less']
})
export class SelectDealerComponent implements OnInit {
  @Output() dealerSelected: EventEmitter<string> = new EventEmitter();
  @Output() previousState: EventEmitter<string> = new EventEmitter();
  isRtl: boolean;
  selectedDealerName: string;
  dealerList: DealerModel[];

  constructor(
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
    private readonly bookingService: BookingService,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly dealerService: DealerService
  ) {
  }

  ngOnInit() {
    this.isRtl = this.translateService.currentLang.includes('ar_');
    this.translateService.onLangChange.subscribe(result => {
      this.isRtl = result.lang.includes('ar_');
    });
    this.selectedDealerName = this.userService.selectedDealer.DealershipName;
    this.dealerList = this.dealerService.dealerList;
  }

  previous() {
    this.previousState.emit('steps-dealer-selection');
  }

  continue() {
    this.dealerSelected.emit('steps-dealer-selection');
  }

  selectDifferentDealer() {
    if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
      this.bookingService.clearBookingData();
      this.router.navigateByUrl('/select-dealer');
    } else {
      this.bookingService.clearBookingData();
      this.loginService.logOut().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('/select-dealer');
        }
      },
        () => {
          this.router.navigateByUrl('/select-dealer');
        });
    }
  }

}
