export class BookingModel {
    CustomerAppointments: CustomerAppointments[];
    Result: Result;
}

class Result {
    ErrorCode: number;
}

export class CustomerAppointments {
    ActualMileage?: number;
    Address1?: string;
    Address2?: string;
    Address3?: string;
    Address4?: string;
    Address5?: string;
    AdvisorDropOffTimeCode: string;
    AdvisorId: string;
    AdvisorPickUpTimeCode?: string;
    AppointmentId: number;
    CanBeAmended: boolean;
    CanBeDeleted: boolean;
    CommunityId?: string;
    CustomerId?: string;
    EmailAddress?: string;
    EngineSize?: string;
    FirstAppAdvisorName?: string;
    FirstAppTime?: string;
    FirstName?: string;
    FuelCode?: string;
    FuelDesc?: string;
    HomeTelNo?: string;
    HomeTelNoAreaCode?: string;
    IsFixItNowAppointment?: number;
    JobDate: string;
    Jobs: JobModel[];
    LastRegisteredDate?: string;
    MobileTelNo?: string;
    PostalCode?: string;
    RepeatRepair?: number;
    RoofTopId: string;
    SecondAppAdvisorName?: string;
    SecondAppTime?: string;
    SecondTransAddress1?: string;
    SecondTransAddress2?: string;
    SecondTransAddress3?: string;
    SecondTransAddress4?: string;
    SecondTransAddress5?: string;
    SecondTransAddress6?: string;
    SecondTransAddress7?: string;
    SecondTransAddress8?: string;
    SecondTransAddress9?: string;
    SecondTransAddress10?: string;
    SecondTransAddress11?: string;
    SecondTransAddress12?: string;
    SecondTransAddress13?: string;
    SecondTransAddress14?: string;
    SecondTransAddress15?: string;
    SecondTransAddress16?: string;
    SecondTransPostalCode?: string;
    SurName?: string;
    Title?: string;
    TransAddress1?: string;
    TransAddress2?: string;
    TransAddress3?: string;
    TransAddress4?: string;
    TransAddress5?: string;
    TransAddress6?: string;
    TransAddress7?: string;
    TransAddress8?: string;
    TransAddress9?: string;
    TransAddress10?: string;
    TransAddress11?: string;
    TransAddress12?: string;
    TransAddress13?: string;
    TransAddress14?: string;
    TransAddress15?: string;
    TransAddress16?: string;
    TransPostalCode?: string;
    TransmissionCode?: string;
    TransmissionDesc?: string;
    TransportMethod?: string;
    TransportMethodOut?: string;
    VehColour?: string;
    VehFreeDesc?: string;
    VehMakeCode?: string;
    VehMakeDesc?: string;
    VehModelCode?: string;
    VehModelDesc?: string;
    VehTrim?: string;
    VehVariantCode?: string;
    VehVariantDesc?: string;
    VehicleChassisNo?: string;
    VehicleRegistrationNumber?: string;
    WIPNo: number;
    WorkTelNo?: string;
    WorkTelNoAreaCode?: string;
    WorkTelNoExt?: string;
}

export class JobModel {
    JobCode: number;
    JobComments: string;
    JobDescription: string;
    JobPrice: number;
    ParentJobCode: string;
    ProductCode: string;
    VHCId: string;
    JobLineId?: number;
}
