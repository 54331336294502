import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-select-options',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.less']
})
export class SelectComponent implements OnChanges {
    @Input() description: string;
    @Input() itemList: object[];
    @Input() valueItem: string;
    @Input() valueDescription: string;
    @Input() required: boolean = false;
    @Input() isRtl: boolean = false;
    @Input() form;
    @Input() disabled: boolean;
    @Input() selectedValue: string;
    @Input() canFilter: boolean;
    @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

    optionSelected: boolean;
    @Input() validationRequiredKey: string;
    // @Input() validationRequiredKey = 'validation-required'

    constructor(
      private readonly translateService: TranslateService,
  ) {
  }

    ngOnInit() {
      this.translateService.onLangChange.subscribe((result) => {
        this.isRtl = result.lang.includes('ar_');
      });
    }
    ngOnChanges() {
        if (this.selectedValue && this.valueItem !== '' && this.itemList && this.itemList.length > 0) {
            const value = this.itemList.filter((x: any) => x.shortOption === this.selectedValue || x[this.valueDescription] === this.selectedValue);
            if (value.length > 0) {
                this.selectedValue = value[0][this.valueItem];
            }
        }
    }

    onChange(value: any) {
        this.optionSelected = true;
        this.valueChanged.emit(value.target.value);
    }
}
