import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user-account/user/user.service';
import { MakeModelVariant } from './makeModelVariant.model';
import { GetModelList, Model } from './model.model';
import { TitanVehicleModel } from './titan-vehicle.model';
import { VehicleModel } from './vehicle.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  makeModelVariantList: MakeModelVariant[] = [];
  currentBrand: string;
  zaVehicles = new BehaviorSubject(JSON.parse(sessionStorage.getItem("vehicleList") ));
  zaSelectedVehicle = new BehaviorSubject({});
  zaSelectedVehicleImage = new BehaviorSubject('');
  vehicleServiceContracts = new BehaviorSubject([]);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly userService: UserService,
    private readonly configService: ConfigService
  ) { }

  private readonly VehicleList = new BehaviorSubject<VehicleModel[]>(undefined);
  readonly VehicleList$ = this.VehicleList.asObservable();


  get vehicleList(): VehicleModel[] {
    return JSON.parse(sessionStorage.getItem('vehicleList'));
  }

  set vehicleList(value: VehicleModel[]) {
    sessionStorage.setItem('vehicleList', JSON.stringify(value));
    this.VehicleList.next(value);
    this.zaVehicles.next(value);
  }

  private readonly VehicleAdded = new BehaviorSubject<VehicleModel>(undefined);
  readonly VehicleAdded$ = this.VehicleAdded.asObservable();

  get vehicleAdded(): VehicleModel {
    return this.VehicleAdded.getValue();
  }

  set vehicleAdded(value: VehicleModel) {
    this.VehicleAdded.next(value);
  }

  private readonly VehicleDeleted = new BehaviorSubject<VehicleModel>(undefined);
  readonly VehicleDeleted$ = this.VehicleDeleted.asObservable();

  get vehicleDeleted(): VehicleModel {
    return this.VehicleDeleted.getValue();
  }

  set vehicleDeleted(value: VehicleModel) {
    this.VehicleDeleted.next(value);
  }

  addVehicleToVehicleList(value: VehicleModel) {
    this.vehicleAdded = value;
    const vehicleListTemp = this.vehicleList;
    vehicleListTemp.push(value);
    this.vehicleList = vehicleListTemp;
  }

  removeVehicle(vehicleId: number) {
    this.vehicleList = this.vehicleList.filter(x => x.vehicleId !== vehicleId);
  }

  getVehicleList(): Observable<VehicleModel[]> {
    if (this.vehicleList && this.vehicleList.length > 0)
      return of(this.vehicleList);

    return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetCustomerVehicleList`
      , new GetVehicleListModel(this.userService)
    ).pipe(map(result => {
      if (result.Result.ErrorCode === 0) {
        const vList: VehicleModel[] = [];
        result.CustomerVehicleList.forEach(vehicle => {
          if (vehicle.MakeName.toLowerCase().includes(this.configService.brand.toLowerCase()) ||
            vehicle.MakeCode.toLowerCase().includes(this.configService.configSettings.oemId.toLowerCase())
          )
            vList.push(new VehicleModel(vehicle));
        });
        this.vehicleList = vList;
        return this.vehicleList;
      }
    }));
  }

  getVehicleListForZA(customerIdFromURL, brand): Observable<VehicleModel[]> {
    if (this.vehicleList && this.vehicleList.length > 0)
      return of(this.vehicleList);
// debugger
    return this.httpClient.post<any>(`${environment.solZAAPIUrl}/api/v1/getVehicleinfo?userId=${customerIdFromURL}&brand=${brand}`
      , new GetVehicleListModelForZA(this.userService, customerIdFromURL)
    ).pipe(map(result => {


      // debugger
      if (result.Result.ErrorCode === 0) {
        const vList: VehicleModel[] = [];
        // debugger
        result.CustomerVehicleList.forEach(vehicle => {
          if (vehicle.MakeName.toLowerCase().includes(this.configService.brand.toLowerCase()) ||
            vehicle.MakeCode.toLowerCase().includes(this.configService.configSettings.oemId.toLowerCase())
          )
            vList.push(new VehicleModel(vehicle));
        });
        this.vehicleList = vList;
        // debugger
        return this.vehicleList;
      }
    }));
  }

  getVehicleListTitan(customerId: number): Observable<VehicleModel[]> {
    if (this.vehicleList && this.vehicleList.length > 0)
      return of(this.vehicleList);
    const vList: VehicleModel[] = [];
    return this.httpClient.get<TitanVehicleModel[]>(`${environment.titanUrl}Vehicle?ownerCustomerId=${customerId}`).pipe(map(result => {
      result.forEach(vehicle => {
        let titanVehicleCode = vehicle.Family;

        if (vehicle.Model) {
          titanVehicleCode = vehicle.Model.Code;
        }

        vList.push({
          chassisNo: vehicle.Vin,
          colour: vehicle.Colour,
          lastRegisteredDate: vehicle.ManufactureDate ? vehicle.ManufactureDate.toString() : null,
          freeDesc: vehicle.Description,
          makeCode: "none",
          makeName: vehicle.Description,
          modelCode: "none",
          modelName: vehicle.Description,
          modelTextDescription: vehicle.Description,
          modelYear: vehicle.ManufactureDate ? new Date(vehicle.ManufactureDate).getFullYear().toString() : null,
          registrationNumber: vehicle.RegoNo,
          variantCode: titanVehicleCode,
          variantName: vehicle.Description,
          vehicleId: vehicle.Id,
          makeId: vehicle.MakeId
        });
      });
      this.vehicleList = vList;
      return vList;
    }));
  }

  getVehicleCosyImageUrl(vehicle: VehicleModel, width?: number): Observable<any> {
    if (!this.configService.configSettings.isCRM) {

    if (!vehicle.variantCode) return;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body: URLSearchParams = new URLSearchParams();
    let modelCode = '';
    if (this.configService.configSettings.useAlternateModelCode) {
      let serviceModelGroup = this.makeModelVariantList.filter(x => {
        return x.modelCode === vehicle.modelCode && x.serviceModelGroup !== '' && x.variantCode === vehicle.variantCode;
      });

      modelCode = serviceModelGroup.length > 0 ? serviceModelGroup[0].serviceModelGroup :
        vehicle.variantCode.substring(0, 4);
    } else {
      if (vehicle.chassisNo && this.configService.configSettings.useVinForVariantCode) {
        modelCode = vehicle.chassisNo.toUpperCase().substring(3, 7);
      } else {
        modelCode = this.configService.configSettings.variant === 'VariantCode' ? vehicle.variantCode.substring(0, 4) :
          vehicle.modelCode.substring(0, 4);
      }
    }

    body.set('jsonReq', JSON.stringify(
      {
        brand: environment.brandLookUpCosy[vehicle.makeCode] || environment.brandLookUpCosy[vehicle.makeId],
        modelCode: modelCode,
        viewPoint: 'FRONTSIDE',
        width: width ? width.toString() : '300'
      }));
      // debugger
    return this.httpClient.post(environment.cosyImageApi, body.toString(), { headers });
    }
  }

  getVehicleCRMCosyImageUrl(vehicle: VehicleModel): Observable<any> {
    if (this.configService.configSettings.isCRM) {

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json'
      });

    return this.httpClient.post(`${environment.solZAAPIUrl}/api/v1/cosyimages`, {chasisNumber: vehicle.chassisNo}, { headers });
    }
  }

  getVehicle(registrationNumber: string, vin: string): Observable<any[]> | undefined {
    const promises = [];

    this.configService.configSettings.dealerInclusion.forEach(dealer => {
      promises.push(
        this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ChassisPrePopulation`
          , new GetChassisPrePopulation(this.userService, registrationNumber, vin, dealer.dealerCode))
      );
    });
    return forkJoin(promises);
  }

  getVehicleTitan(registrationNumber: string, vin: string): Observable<VehicleModel[]> {
    const vehicleList: VehicleModel[] = [];
    let searchParams = `?Rego=${registrationNumber}&Vin=${vin}`;

    return this.httpClient.get<TitanVehicleModel[]>(`${environment.titanUrl}Vehicle${searchParams}`).pipe(map(result => {
      result.forEach(vehicle => {
        let makeCode = null;
        let modelCode = null;

        if (vehicleList.find(x => x.chassisNo === vehicle.Vin && x.registrationNumber === vehicle.RegoNo) === undefined) {
          if(vehicle.MakeId) {
            makeCode = vehicle.MakeId.toString();
          }
          if(vehicle.ModelId) {
            modelCode = vehicle.ModelId.toString();
          }
          vehicleList.push({
            chassisNo: vehicle.Vin,
            freeDesc: vehicle.Description,
            makeCode, //:  "78",//"vehicle.MakeId.toString()",
            makeName: this.configService.brand,
            modelCode, //:  "87",//vehicle.ModelId.toString(),
            modelName: vehicle.Description,
            modelTextDescription: vehicle.Description,
            registrationNumber: vehicle.RegoNo,
            variantCode: vehicle.Family,
            variantName: vehicle.Description,
            vehicleId: vehicle.Id,
            makeId: vehicle.MakeId
          });
        }
      });
      // debugger
      return vehicleList;
    }));
  }

  getMakeListTitan(): Observable<TitanMakeModel[]> {
    return this.httpClient.get<TitanMakeModel[]>(`${environment.titanUrl}Vehicle/Makes`);
  }

  getModelListTitan(make: string): Observable<TitanModelModel[]> {
    return this.httpClient.get<TitanModelModel[]>(`${environment.titanUrl}Vehicle/Models?makeId=${make}`)
  }

  getModelList(brand: string): Model[] {
    return new GetModelList().getModelList(brand);
  }

  getMakeModelVariant(brand: string, modelCode?: string): Observable<MakeModelVariant[]> {

    // console.trace("-->", brand, modelCode) // debugger
    if (this.makeModelVariantList.length > 0 && this.currentBrand === brand && !modelCode)
      return of(this.makeModelVariantList);
    // console.log("userService-->", this.userService);
    if (this.configService.configSettings.isCRM) {
      this.userService.languageSelected = "en_za";
    }
    // debugger


    if (this.configService.configSettings.isCRM) {
      // if (modelCode.indexOf('-') > -1){
      //   modelCode = modelCode.slice(0, 1) + modelCode.slice(2);
      // }
    }

    return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetMakeModelVariant`
      , new GetMakeModelVariant(this.userService, brand, this.configService, modelCode)
    ).pipe(map(result => {
      if (!modelCode) {
        this.makeModelVariantList = [];
        this.currentBrand = brand.charAt(0);
        if (result.Result.ErrorCode === 0) {
          result.Results.MakeModelData.forEach(x => {
            this.makeModelVariantList.push(new MakeModelVariant(x));
          });
        }
        return this.makeModelVariantList;
      } else {
        const varientList: MakeModelVariant[] = [];
        result.Results.MakeModelData.forEach(x => {
          varientList.push(new MakeModelVariant(x));
        });
        return varientList;
      }
    }));
  }

  addVehicle(vehicle: VehicleContract): Observable<AddVehicleContract> | undefined {
    let vehicleContract = new AddVehicleContract(this.userService, vehicle);
    return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/AddCustomerVehicle`
      , vehicleContract
    ).pipe(map(result => {
      if (result.Result.ErrorCode === 0) {
        // START HERE
        // TODO - fix add vehicle

        vehicleContract.VehicleId = result.VehicleId;
        // console.log("ADD CAR-->",vehicleContract);
        vehicleContract = {...vehicleContract, VehicleId: result.VehicleId}
        // console.log("ADD CAR", vehicleContract);

        return vehicleContract;
      }
      return undefined;
    }));
  }

  linkVehicleTitan(vehicleId: number,) {
    const body =
    {
      VehicleId: vehicleId,
      OwnerCustomerId: this.userService.userInfo.CustomerId
    }
    return this.httpClient.post(`${environment.titanUrl}Vehicle/AddOwner`, body);
  }

  addVehicleTitan(vehicleModel: TitanAddVehicleContract): Observable<any> {
    return this.httpClient.post(`${environment.titanUrl}Vehicle`, vehicleModel);
  }

  addVehicleLevelOne(vehicle: VehicleContract): Observable<AddVehicleContract> | undefined {
    const vehicleContract = new AddVehicleContract(this.userService, vehicle);
    return this.httpClient.post<any>(`${environment.LevelOneApiUrl}addVehicle`, vehicleContract).pipe(map(result => {
      vehicleContract.VehicleId = result;
      return vehicleContract;
    }));
  }

  deleteVehicleTitan(model, vehicle: VehicleModel): Observable<any> {
    return this.httpClient.post(`${environment.titanUrl}Vehicle/RemoveOwner`, model).pipe(map(() => {
      this.vehicleDeleted = vehicle;
      return true;
    }));
  }

  deleteVehicle(vehicle: VehicleModel, reason: string): Observable<boolean> | undefined {
    return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/DeleteCustomerVehicle`
      , new DeleteVehicleContract(this.userService, vehicle, reason)
    ).pipe(map(result => {
      if (result.ErrorCode === 0) {
        this.vehicleDeleted = vehicle;
        return true;
      }
      return undefined;
    }));
  }

  // getCRMVehicles(customerId: string) {
  //   return this.httpClient.get<any[]>(`${environment.solZAAPIUrl}/api/v1/getCustomerVehicles?userId=${customerId}`).pipe(map(result => {

  //     result.forEach(vehicles => {
  //     //  this.VehicleList = vehicles
  //     });
  //     // return vehicleList;
  //   })
  //   ).subscribe((cars) => {

  //   });
  // }
}

export class TitanAddVehicleContract {
  DealershipId: number;
  Vin: string;
  RegoNo: string
  Description: string;
  MakeId: number;
  ModelId: number;
  ManufactureId: string;
  Family: string;
  OwnerCustomerId: number;
}

class TitanModelModel {
  Id: number;
  ModelTypeId: number;
  Code: string;
  Description: string;
  Badge: string;
  Series: string;
  Engine: string;
  Transmission: string;
  ReleasedDate: Date;
  DiscontinuedDate: string;
  Year: number;
  VehicleTypeCode: number;
  EngineSize: string;
  FuelConsumption: string;
  NoOfPassengers: string;
  NoOfDoors: string;
  DmsModelKey: number;
  ModelType: TitanModelTypeModel;
}

class TitanModelTypeModel {
  Id: number;
  Code: string;
  MakeId: number;
  Description: string;
  DmsModelTypeKey: string;
}

class TitanMakeModel {
  Id: number;
  MakeId: string;
  Description: string;
  MakeCode: number;
  DmsMakeKey: number
}

class DeleteVehicleContract {
  constructor(userService: UserService, vehicle: VehicleModel, reason: string) {
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.CustomerId = userService.customerId;
    this.VehicleId = vehicle.vehicleId;
    this.DeleteReason = reason;
  }
  RooftopId: string;
  CustomerId: string;
  VehicleId: number;
  DeleteReason: string;
}

export class VehicleContract {
  constructor(vehicle: VehicleContract) {
    this.MakeCode = vehicle.MakeCode;
    this.MakeName = vehicle.MakeName;
    this.ModelCode = vehicle.ModelCode;
    this.VariantCode = vehicle.VariantCode;
    this.VariantName = vehicle.VariantName;
    this.FreeDesc = vehicle.FreeDesc;
    this.ModelTextDescription = vehicle.ModelTextDescription;
    this.RegistrationNumber = vehicle.RegistrationNumber;
    this.ChassisNo = vehicle.ChassisNo;
  }

  MakeCode: string;
  MakeName: string;
  ModelCode: string;
  VariantCode: string;
  VariantName: string;
  FreeDesc: string;
  ModelTextDescription: string;
  RegistrationNumber: string;
  ChassisNo: string;
}

class AddVehicleContract extends VehicleContract {
  constructor(userService: UserService, vehicle: VehicleContract) {
    super(vehicle);
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.CustomerId = userService.customerId;
  }
  VehicleId: number;
  RooftopId: string;
  CustomerId: string;
}

class GetMakeModelVariant {
  constructor(userService: UserService, brand: string, configService: ConfigService, modelCode?: string) {
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.Language = userService.languageSelected.substring(0, 2);
    this.GetAllFlag = configService.configSettings.getMakeModelVariantAll !== undefined ? configService.configSettings.getMakeModelVariantAll : true;
    this.GetMakeFlag = false;
    this.GetModelFlag = modelCode ? false : true;
    this.GetVariantFlag = false; //true;
    this.MakeCode = brand;
    this.ModelCode = modelCode;
  }




  Language: string;
  RooftopId: string;
  GetAllFlag: boolean;
  GetMakeFlag: boolean;
  GetModelFlag: boolean;
  GetVariantFlag: boolean;
  MakeCode: string;
  ModelCode: String;
}

class GetChassisPrePopulation {
  constructor(userService: UserService, registrationNumber: string, vin: string, dealerCode?: string) {
    this.CustomerId = userService.customerId;
    this.RooftopId = dealerCode ? dealerCode : userService.selectedDealer.RooftopId;
    this.RegNumber = registrationNumber;
    this.VIN = vin;
  }
  CustomerId: string;
  RooftopId: string;
  RegNumber: string;
  VIN: string;
}

class GetVehicleListModel {
  constructor(userService: UserService) {
    this.CustomerId = userService.customerId;
    this.RooftopId = userService.selectedDealer.RooftopId;
  }
  CustomerId: string;
  RooftopId: string;
}

class GetVehicleListModelForZA {
  constructor(userService: UserService, customerIdFromURl) {
    // debugger
    this.CustomerId = customerIdFromURl;
    this.RooftopId = userService.selectedDealer.RooftopId || "2870 " ;
  }
  CustomerId: string;
  RooftopId: string;
}
