import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../config/config.service';
import { ConfigSettings } from '../config/config.model';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AppLoadService {

    constructor(
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private httpClient: HttpClient,
        private configService: ConfigService) { }

    getCommunitySettings(): Promise<any> {
        const community = window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('serviceonline') > -1 ?
            environment.localCommunity :
            window.location.host;
        this.configService.brand = community.indexOf('bmw') > -1 || community.indexOf('munichauto') > -1 ? 'BMW' : 'MINI';

        // console.log(this.configService);
        

        const element = this._document.getElementById('appFavicon');
        switch (this.configService.brand) {
            case 'BMW':
                element.setAttribute('href', './../../../assets/images/favicon.ico');
                break;
            case 'MINI':
                element.setAttribute('href', './../../../assets/images/favicon-mini.png');
                break;
        }


// TODO: Refactor
const promise = this.httpClient.get(`${environment.solAPIUrl}/api/v1/${community}/getCommunity`)
.toPromise()
.then(settings => {
    
    this.configService.configSettings = new ConfigSettings(settings, '','');
    // debugger
    return settings;
});
return promise;
    }

    getCommunitySettingsFile(): Promise<any> {
        const community = window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('serviceonline') > -1 ?
            environment.localCommunity :
            window.location.host;
        this.configService.brand = community.indexOf('bmw') > -1 ? 'BMW' : 'MINI';

        const element = this._document.getElementById('appFavicon');
        switch (this.configService.brand) {
            case 'BMW':
                element.setAttribute('href', './../../../assets/images/favicon.ico');
                break;
            case 'MINI':
                element.setAttribute('href', './../../../assets/images/favicon-mini.png');
                break;
        }



        const promise = this.httpClient.get(`${environment.serviceOnlineService}/assets/communities/community.${community}.json`)
            .toPromise()
            .then(settings => {
                this.configService.configSettings = new ConfigSettings(settings);
                return settings;
            });
        return promise;
    }
    
}
