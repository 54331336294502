import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './../../services/loader/loader.service';


@Injectable()

export class LoaderInterceptor implements HttpInterceptor {
    promiseList: string[] = [];

    constructor(public loaderService: LoaderService) { }
    intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        if (req.url.indexOf('GetAdvisorPictures') === -1) {
            this.loaderService.show();
            this.promiseList.push(req.url);
        }

        return next.handle(req).pipe(
            finalize(() => {
                const index = this.promiseList.indexOf(req.url);
                this.promiseList.splice(index, 1);
                if (this.promiseList.length === 0) {
                    this.loaderService.hide()
                }
            })
        );
    }
}
