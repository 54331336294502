import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { CustomDatepickerI18n } from 'src/app/services/customDatepickerI18n.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selected-options',
  templateUrl: './selected-options.component.html',
  styleUrls: ['./selected-options.component.less']
})

export class SelectedOptionsComponent implements OnInit {
  selectedServiceDate: string;
  selectedServiceSlot: string;
  dayChar: string;

  constructor(
    private readonly userService: UserService,
    private readonly dateService: CustomDatepickerI18n,
    private readonly translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.userService.SelectedServiceDate$.subscribe(result => {
      if (result) {
        this.selectedServiceDate = this.getServiceDate(result);
      } else {
        this.selectedServiceDate = undefined;
      }
    });

    this.translateService.get('day-char').subscribe(result => {
      this.dayChar = result ? result !== 'day-char' ? result : '' : '';
    });
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('day-char').subscribe(result => {
        this.dayChar = result ? result !== 'day-char' ? result : '' : '';
        this.selectedServiceDate = this.getServiceDate(this.userService.selectedServiceDate);
      });
    });

    if (this.userService.selectedServiceSlot) {
      if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
        this.selectedServiceSlot =
          `${this.padTime(new Date(this.userService.selectedServiceSlot).getHours())}:${this.padTime(new Date(this.userService.selectedServiceSlot).getMinutes())}`;
      } else {
        this.selectedServiceSlot = this.userService.selectedServiceSlot.substring(0, 5);
      }
    }

    this.userService.SelectedServiceSlot$.subscribe(result => {
      if (result) {
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
          this.selectedServiceSlot =
            `${this.padTime(new Date(this.userService.selectedServiceSlot).getHours())}:${this.padTime(new Date(this.userService.selectedServiceSlot).getMinutes())}`;
        } else {
          this.selectedServiceSlot = result.substring(0, 5);
        }
      }
    });

    this.selectedServiceDate = this.getServiceDate(this.userService.selectedServiceDate);
  }

  padTime(time: number): string {
    return time.toString().length === 1 ? `0${time}` : time.toString();
  }

  getServiceDate(date: Date) {
    if (date) {
      date = new Date(date);
      if (this.dateService.weekdays.length > 0) {
        return `${date.getDate()} ${this.dayChar ? this.dayChar : ''}${this.dateService.getMonthFullName(date.getMonth() + 1)} ${date.getFullYear()}`;
      } else {
        setTimeout(() => {
          return `${date.getDate()} ${this.dayChar ? this.dayChar : ''}${this.dateService.getMonthFullName(date.getMonth() + 1)} ${date.getFullYear()}`;
        }, 1000);
      }
    }
  }
}
