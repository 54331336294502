import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.less']
})
export class AccordianComponent implements OnInit {
  @Input() step: any;
  @Output() stepStateChanges = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  setOpenState() {

  }
}
