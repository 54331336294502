import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { ServiceItemModel } from 'src/app/services/services/service-item.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-selected-services',
    templateUrl: './selected-services.component.html',
    styleUrls: ['./selected-services.component.less']
})
export class SelectedServicesComponent implements OnInit {
    selectedServices: ServiceItemModel[];
    serviceNotes: string;
    isRtl: boolean;
    selectedServiceSubscription;

    constructor(
        private readonly userService: UserService,
        private readonly translateService: TranslateService
        ) {
    }

    ngOnInit() {



        this.selectedServiceSubscription = this.userService.SelectedServices$.subscribe(result => {
          // debugger
            if (result) {
                this.selectedServices = result;
            } else {
              this.selectedServices = undefined;
            }
        });

        this.userService.ServiceNotes$.subscribe(result => {
            this.serviceNotes = result ? result : '';
        });

        this.isRtl = this.translateService.currentLang.includes('ar_');
        this.translateService.onLangChange.subscribe(result => {
            this.isRtl = result.lang.includes('ar_');
        });
    }

    hasAdditionalItems() {
        return this.selectedServices &&
        this.selectedServices.filter(item => item.productCode.includes('S_')).length > 0;
    }

    ngOnDestroy() {
      // this.userService.SelectedServices.unsubscribe()
      this.selectedServiceSubscription.unsubscribe()
  }
}
