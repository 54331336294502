import { Component, OnInit } from '@angular/core';
import { BookingService, ServiceItems, LevelOneBookingModel } from 'src/app/services/booking/booking.service';
import { Tranlsation, TitleService } from 'src/app/services/title/title.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Model } from 'src/app/services/vehicle/model.model';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExclusionDates } from 'src/app/services/config/config.model';

@Component({
    selector: 'app-level-one-booking',
    templateUrl: './level-one-booking.component.html',
    styleUrls: ['./level-one-booking.component.less']
})
export class LevelOneBookingComponent implements OnInit {
    isLebanon: boolean;

    exlusionDates: ExclusionDates[]
  isSelected = true;
  timeText: string;
  isLanguageThai = false;
    isLanguageArabic = false;
    showDatePicker: boolean = true;
  isRtl: boolean = false;
    titleList: Tranlsation[];
    mobilePattern: string;
    titleText: string;
    timeSlotText: string;
    modelText: string;
    startDate: NgbDateStruct;
    modelList: Model[];
    showBookingSent: boolean;
    serviceItemsList: ServiceItems[] = [];
    validationRequiredKeyProp = "validation-required"
    hasSelectedTime = false
    hasSelectedModel = false
    hasSelectedTitle = false
    hasSelectedDate = false

    model: LevelOneBookingModel = {
        email: '',
        firstName: '',
        mobile: '',
        surname: '',
        title: '',
        registration: '',
        modelYear: '',
        serviceDate: new Date(),
        notes: '',
        model: '',
        serviceItems: [],
        timeSlot: ''
    }
    timeSlotList = [{value: "Choose a date first"}];

    constructor(
        private readonly titleService: TitleService,
        private readonly translateService: TranslateService,
        private readonly bookingService: BookingService,
        private readonly configService: ConfigService,
        private readonly loaderService: LoaderService,
        private readonly router: Router,
        private readonly calendar: NgbCalendar,
    ) { }

    ngOnInit() {

    //   this.formBuilder.group({
    //     email: ['', [Validators.required]],
    //     firstName: ['', [Validators.required]],
    //     mobile: ['', [Validators.required]],
    //     surname: ['', [Validators.required]],
    //     title: ['', [Validators.required]],
    //     registration: ['', [Validators.required]],
    //     modelYear: ['', [Validators.required]],
    //     serviceDate: ['', [Validators.required]],
    //     notes: ['', [Validators.required]],
    //     model: ['', [Validators.required]],
    //     serviceItems: [[], [Validators.required]],
    //     timeSlot: ['', [Validators.required]]
    // })


      // console.log("-->", this.model.levelOneForm)
      this.exlusionDates = this.configService.configSettings.nonAutoline.exclusionDates;
      this.isRtl = this.translateService.currentLang.includes('ar_');
      this.translateService.onLangChange.subscribe((result) => {

        this.isRtl = result.lang.includes('ar_');
        this.isLanguageThai = result.lang === "th";
        this.isLanguageArabic = result.lang.includes('ar_');
          this.translateService.get('time').subscribe(result => this.timeText = result);
          this.showDatePicker = false;
          setTimeout(() => this.showDatePicker = true, 1000);
      })
      

      // this.translateService.onLangChange.subscribe((result) => {
      //   // this.translateValues()
      //   this.isRtl = result.lang.includes('ar_');
      // });
        const today = new Date();
        today.setDate(today.getDate() + 3);
        this.startDate = {
            day: today.getDate(),
            month: today.getMonth() + 1,
            year: today.getFullYear()
        };
        this.bookingService.isLevelOneBooking = true;
        this.mobilePattern = this.configService.configSettings.formValidation.mobileTelNo;
        this.translateFields();
        this.getTitelList();
        this.translateService.onLangChange.subscribe(() => {
            this.translateFields();
            this.getTitelList();
            this.getServiceItemList();
        });
        this.modelList = this.bookingService.getStaticModelList().filter(x => x.makeName === this.configService.brand);
        this.getServiceItemList();
        // this.model.model = this.modelList
    }

    isDisabled = (date: NgbDateStruct, current: { month: number }) => {
        return this.getIsDisabled(date);
    }

    getIsDisabled(dateToCheck: NgbDateStruct) {
        const date: Date = new Date(dateToCheck.year, dateToCheck.month - 1, dateToCheck.day);
        const today = new Date();
        today.setDate(today.getDate() + 2);
    
        const jan = [{year: 2024, month: 4, day: 18},{year: 2024, month: 4, day: 19}];

        // if ( date.getDay() === 0 || date.getDay() === 6) return true;  // Middle East Countries do not work on Friday and Saturday. They work on Sunday.
        
        // Your Solution after removing Weekend check this is more explicit and readable
        // if (date < today) return true;
        // else if (this.blockNonWorkingDays(dateToCheck, this.exlusionDates)) return true;
        // else false;

        // My Solution that is functional Programming
        return date < today || this.blockNonWorkingDays(dateToCheck, this.exlusionDates);
    }

    selectTitle(event) {
        this.translateService.get(event).subscribe(result => {
            this.model.title = result;
        });
        this.hasSelectedTitle = !!event
    }

    selectModel(event) {
        this.model.model = event;
        this.hasSelectedModel = !!event
    }

    isServiceSelected() {
        return this.serviceItemsList.find(x => x.selected) !== undefined || this.model.notes !== '';
    }

    selectTimeSlot(event) {
        this.model.timeSlot = event;
        this.hasSelectedTime = !!event;
    }

    translateFields() {
        this.translateService.get(['title', 'model', 'timeslot']).subscribe(result => {
            this.titleText = result.title;
            this.modelText = result.model;
            this.timeSlotText = result.timeslot;
        });
    }

    getTitelList() {
        this.loaderService.show();
        setTimeout(() => {
            this.titleList = this.titleService.getShortTitles();
            if (!this.configService.configSettings.hideMoreTitles) {
                this.translateService.get('more').subscribe(result => {
                    this.titleList.push({ key: 'more', value: `${result}...` });
                    this.loaderService.hide();
                });
            } else {
                this.loaderService.hide();
            }
        }, 2000);
    }

    submitBooking() {

        this.model.serviceItems = [];
        this.serviceItemsList.forEach(item => {
            if (item.selected)
                this.model.serviceItems.push(item.description);
        });
        this.bookingService.submitLevelOneBooking({
            email: this.model.email,
            firstName: this.model.firstName,
            mobile: this.model.mobile,
            model: this.model.model,
            modelYear: this.model.modelYear,
            notes: this.model.notes,
            registration: this.model.registration,
            serviceDate: this.model.serviceDate,
            surname: this.model.surname,
            timeSlot: this.model.timeSlot,
            title: this.model.title,
            serviceItems: this.model.serviceItems
        }).pipe(take(1)).subscribe(() => {
            this.showBookingSent = true;
            setTimeout(() => this.router.navigateByUrl('/select-dealer'), 4000);
        });
    }

    cancel() {
        this.router.navigateByUrl('/select-dealer')
    }

    onDateChange(event) {
      this.hasSelectedDate = !!event;
        // this.model.serviceDate = new Date(event.year, event.month - 1, event.day);

        this.timeSlotList = this.getTimeSlotList(event);
    }

    goToDealer() {
        this.router.navigateByUrl('/select-dealer');
    }



    getServiceItemList() {
        this.serviceItemsList = [];
        this.configService.configSettings.nonAutoline.serviceItems.forEach(x => {
            this.translateService.get(x.translation).subscribe(result => {
                this.serviceItemsList.push({
                    description: result,
                    code: x.code
                });
            });
        });

    }


    ///////////////////////////////////////////////
    /*
    * Willem pick one from the 3 solutions you prefere
    */ 
    blockNonWorkingDays(date: NgbDateStruct, listOfDates) {
        // Your Solution
        // for (let i = 0;i<listOfDates.length; i++){
        //     if( date.year === listOfDates[i].year && date.month === listOfDates[i].month && date.day === listOfDates[i].day) return true;
        // }
        // return false;

        // My First Solution optimized for readaility
        // for (let dateInList of listOfDates) {
        //     if( date.year === dateInList.year && date.month === dateInList.month && date.day === dateInList.day) return true;
        // }
        // return false
        // My Solution oprimised for Functional Programming
        return listOfDates.some(d => d.year === date.year && d.month === date.month && d.day === date.day);
}

    getTimeSlotList(dateToCheck: NgbDateStruct) {
        this.isLebanon = this.configService.configSettings.language === 'ar_lb'|| this.configService.configSettings.language === 'en_lb';

        const date = new Date(dateToCheck.year, dateToCheck.month - 1, dateToCheck.day);

        if (this.isLebanon&& date.getDay() === 6){
            this.timeSlotList = [
                { value: '07:00 - 07:30' },
                { value: '07:30 - 08:00' },
                { value: '08:00 - 08:30' },
                { value: '08:30 - 09:00' },
                { value: '09:00 - 09:30' },
                { value: '09:30 - 10:00' },
                { value: '10:00 - 10:30' },
                { value: '10:30 - 11:00' }
            ];

            return this.timeSlotList;
        }

            this.timeSlotList = [
        { value: '07:00 - 07:30' },
        { value: '07:30 - 08:00' },
        { value: '08:00 - 08:30' },
        { value: '08:30 - 09:00' },
        { value: '09:00 - 09:30' },
        { value: '09:30 - 10:00' },
        { value: '11:00 - 11:30' },
        { value: '11:30 - 12:00' },
        { value: '13:00 - 13:30' },
        { value: '13:30 - 14:00' },
        { value: '15:30 - 16:00' },
        { value: '16:00 - 16:30' }
    ];

        return this.timeSlotList;
    }
}

