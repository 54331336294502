import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { BookingService } from 'src/app/services/booking/booking.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  brand: string;
  isLevelOneBooking: boolean;
  constructor(
    private readonly configServce: ConfigService,
    private readonly bookingService: BookingService
  ) { }

  ngOnInit() {
    this.brand = this.configServce.brand;
    this.bookingService.isLevelOneBooking$.subscribe(result => {
      this.isLevelOneBooking = result;
    });
  }

}
