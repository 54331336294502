export class ServiceAdvisorPictureModel {
    constructor(value) {
        this.advisorId = value.AdvisorId;
        this.advisorName = value.AdvisorName;
        this.advisorPicture = value.AdvisorPicture;
    }
    advisorId: string;
    advisorName: string;
    advisorPicture: string;
}
