import { Component, OnInit } from '@angular/core';
import { CustomerInformationResult } from 'src/app/services/user-account/user/user.model';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from 'src/app/services/booking/booking.service';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.less']
})

export class ProfileViewComponent implements OnInit {
  userAccount: CustomerInformationResult;
  customerId: string;
  confirmDelete: boolean;
  isTitanDealer: boolean;
  nameSwitch: boolean;
  isRtl: boolean;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService,
    private readonly bookingService: BookingService
    ) {

  }

  ngOnInit() {
    this.customerId = this.userService.customerId;
    this.userAccount = this.userService.userInfo;
    this.isTitanDealer = this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer;
    this.getNameSwitch(this.translateService.currentLang);

    this.translateService.onLangChange.subscribe(result => {
      this.getNameSwitch(result.lang);
      this.isRtl = result.lang.includes('ar_');
    });
  }

  cancelDelete() {
    this.confirmDelete = false;
  }

  bookService() {
    this.bookingService.clearBookingData(true);
    this.router.navigateByUrl('/select-service');
  }

  deleteProfile() {
    this.confirmDelete = true;
  }

  getNameSwitch(setLang: string) {
    this.nameSwitch = this.configService.configSettings.languages.find(x => x.language === setLang && x.switch) !== undefined;
  }

  doDeleteProfile() {
    this.userService.deleteAccount().subscribe(result => {
      if (result) {
        sessionStorage.clear();
        this.cancelDelete();
        this.router.navigateByUrl('/user-account/login');
      }
    });
  }

  updateProfile() {
    this.router.navigateByUrl('/profile/edit');
  }
}
