import { Injectable } from '@angular/core';
import { DealerModel, LocationModelTitan } from '../../dealer/dealer.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { VehicleModel } from '../../vehicle/vehicle.model';
import { ServiceItemModel } from '../../services/service-item.model';
import { ServiceAdvisorModel } from '../../service-advisor/service-advisor.model';
import { UserAccountModel, CustomerInformationResult } from './user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../../config/config.service';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ServiceDatesModel } from '../../options/service-dates.model';
import { TitanCustomerModel } from '../login/login.service';
declare const require: any;
const jsSHA = require('jssha');

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly SelectedServices = new BehaviorSubject<ServiceItemModel[]>(undefined);
  readonly SelectedServices$ = this.SelectedServices.asObservable();

  private readonly SelectedVehicle = new BehaviorSubject<VehicleModel>(undefined);
  readonly SelectedVehicle$ = this.SelectedVehicle.asObservable();

  private readonly ServiceAdvisor = new BehaviorSubject<ServiceAdvisorModel>(undefined);
  readonly ServiceAdvisor$ = this.ServiceAdvisor.asObservable();

  private readonly SelectedServiceDate = new BehaviorSubject<Date>(undefined);
  readonly SelectedServiceDate$ = this.SelectedServiceDate.asObservable();

  private readonly _selectedDealer = new BehaviorSubject<boolean>(undefined);
  readonly selectedDealer$ = this._selectedDealer.asObservable();

  private readonly _selectedLocation = new BehaviorSubject<boolean>(undefined);
  readonly selectedLocation$ = this._selectedLocation.asObservable();

  private readonly SelectedServiceSlot = new BehaviorSubject<string>(undefined);
  readonly SelectedServiceSlot$ = this.SelectedServiceSlot.asObservable();

  private readonly Token = new BehaviorSubject<string>(undefined);
  readonly Token$ = this.Token.asObservable();

  private readonly ServiceNotes = new BehaviorSubject<string>(undefined);
  readonly ServiceNotes$ = this.ServiceNotes.asObservable();

  private readonly ServiceDates = new BehaviorSubject<ServiceDatesModel>(undefined);
  readonly ServiceDates$ = this.ServiceDates.asObservable();

  private readonly ServiceOptionsReady = new BehaviorSubject<Boolean>(undefined);
  readonly ServiceOptionsReady$ = this.ServiceOptionsReady.asObservable();

  AdvisorsWithUrls = new BehaviorSubject([]);

  crmUserToken = new BehaviorSubject('')
  crmSelectedVehicle = new BehaviorSubject('')

  usersVehicleList = new BehaviorSubject([]);
  usersVehicleCurrentIndex = new BehaviorSubject({});

  serviceNotesItem: ServiceItemModel;

  languageSelected: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService
  ) {
    if (sessionStorage.getItem('selectedServices')) {

      this.SelectedServices.next(JSON.parse(sessionStorage.getItem('selectedServices')));
    }

    if ( window.location.pathname === "/select-dealer") {
      this.SelectedServices.next(undefined);
    }
  }

  private readonly UserInfo = new BehaviorSubject<CustomerInformationResult>(null);
  readonly userInfo$ = this.UserInfo.asObservable();

  get userInfo(): CustomerInformationResult {
    return JSON.parse(sessionStorage.getItem('userInfo'));
  }

  set userInfo(value: CustomerInformationResult) {
    this.UserInfo.next(value);
    sessionStorage.setItem('userInfo', JSON.stringify(value));
  }

  setUserInfo(value: CustomerInformationResult) {
    this.userInfo = value;
  }

  get appointmentIdToUpdate(): number {
    return JSON.parse(sessionStorage.getItem('appointmentIdToUpdate')) ? JSON.parse(sessionStorage.getItem('appointmentIdToUpdate')) : null;
  }

  set appointmentIdToUpdate(value: number) {
    if (value)
      sessionStorage.setItem('appointmentIdToUpdate', value.toString());
  }

  get wipNumberToUpdate(): number {
    return JSON.parse(sessionStorage.getItem('wipNumberToUpdate')) ? JSON.parse(sessionStorage.getItem('wipNumberToUpdate')) : null;
  }

  set wipNumberToUpdate(value: number) {
    if (value)
      sessionStorage.setItem('wipNumberToUpdate', value.toString());
  }

  get selectedVehicle(): VehicleModel {
    return JSON.parse(sessionStorage.getItem('selectedVehicle'));
  }

  set selectedVehicle(value: VehicleModel) {
    if (value !== undefined) {
      sessionStorage.setItem('selectedVehicle', JSON.stringify(value));
      this.SelectedVehicle.next(value);
    } else {
      sessionStorage.removeItem('selectedVehicle');
      this.SelectedVehicle.next(value);
    }
  }

  get serviceOptionsReady(): Boolean {
    return this.ServiceOptionsReady.getValue();
  }

  set serviceOptionsReady(value: Boolean) {
    this.ServiceOptionsReady.next(value);
  }

  get serviceDates(): ServiceDatesModel {
    return this.ServiceDates.getValue();
  }

  set serviceDates(value: ServiceDatesModel) {
    this.ServiceDates.next(value);
  }

  get bookingId(): number {
    return parseInt(sessionStorage.getItem('bookingId'), 10);
  }

  set bookingId(value: number) {
    sessionStorage.setItem('bookingId', value ? value.toString() : undefined);
  }

  get token(): string {
    return sessionStorage.getItem('token');
  }

  set token(value: string) {
    if (value !== undefined) {
      sessionStorage.setItem('token', value);
      this.Token.next(value);
    }
  }

  get expiry(): number {
    return JSON.parse(sessionStorage.getItem('expiry'));
  }

  set expiry(value: number) {
    if (value !== undefined)
      sessionStorage.setItem('expiry', value.toString());
  }

  get customerId(): string {
    return sessionStorage.getItem('customerId');
  }

  set customerId(value: string) {
    if (value !== undefined)
      sessionStorage.setItem('customerId', value);
  }

  get reservationToken(): string {
    return sessionStorage.getItem('reservationToken') === 'undefined' ?
      undefined : sessionStorage.getItem('reservationToken');
  }

  set reservationToken(value: string) {
    if (value === undefined)
      sessionStorage.removeItem('reservationToken');
    else
      sessionStorage.setItem('reservationToken', value);
  }


  get serviceNotes(): string {
    return sessionStorage.getItem('serviceNotes');
  }

  set serviceNotes(value: string) {
    sessionStorage.setItem('serviceNotes', value);
    this.ServiceNotes.next(value);
  }

  get dataHubHash(): string {
    return sessionStorage.getItem('dataHubHash');
  }

  set dataHubHash(value: string) {
    if (value !== undefined)
      sessionStorage.setItem('dataHubHash', value);
  }

  get selectedDealer(): DealerModel {
    return JSON.parse(sessionStorage.getItem('selectedDealer'));
  }

  set selectedDealer(value: DealerModel) {
    // console.trace("selectedDealer-->" )
    if (value !== undefined) {
      sessionStorage.setItem('selectedDealer', JSON.stringify(value));
      this._selectedDealer.next(true);
    }
    else {
      sessionStorage.removeItem('selectedDealer');
      this._selectedDealer.next(false);
    }
  }

  get selectedLocation(): LocationModelTitan {
    return JSON.parse(sessionStorage.getItem('selectedLocation'));
  }

  set selectedLocation(value: LocationModelTitan) {
    if (value !== undefined) {
      sessionStorage.setItem('selectedLocation', JSON.stringify(value));
      this._selectedLocation.next(true);
    }
    else {
      sessionStorage.removeItem('selectedLocation');
      this._selectedLocation.next(false);
    }
  }

  get selectedServiceDate(): Date {
    return JSON.parse(sessionStorage.getItem('selectedServiceDate'));
  }

  set selectedServiceDate(value: Date) {
    this.SelectedServiceDate.next(value);
    if (value !== undefined) {
      // console.log("VALUE->", value);
      // console.trace(value)
      sessionStorage.setItem('selectedServiceDate', JSON.stringify(value));
    }
    else {
      sessionStorage.removeItem('selectedServiceDate');
    }
  }

  get selectedServiceSlotToken(): Date {
    return JSON.parse(sessionStorage.getItem('selectedServiceSlotToken'));
  }

  set selectedServiceSlotToken(value: Date) {
    // this.selectedServiceSlotToken.next(value);
    if (value !== undefined) {
      sessionStorage.setItem('selectedServiceSlotToken', JSON.stringify(value));
    }
    else {
      sessionStorage.removeItem('selectedServiceSlotToken');
    }
  }

  get selectedServiceMobilityDeliveryOptionAddress(): string {
    return JSON.parse(sessionStorage.getItem('selectedServiceMobilityDeliveryOptionAddress'));
  }

  set selectedServiceMobilityDeliveryOptionAddress(value: string) {
    if (value !== undefined) {
      sessionStorage.setItem('selectedServiceMobilityDeliveryOptionAddress', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('selectedServiceMobilityDeliveryOptionAddress');
    }
  }

  get selectedServiceMobilityOptionAddress(): string {
    return JSON.parse(sessionStorage.getItem('selectedServiceMobilityOptionAddress'));
  }

  set selectedServiceMobilityOptionAddress(value: string) {
    if (value !== undefined) {
      sessionStorage.setItem('selectedServiceMobilityOptionAddress', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('selectedServiceMobilityOptionAddress');
    }
  }

  get selectedServiceSlot(): string {
    return JSON.parse(sessionStorage.getItem('selectedServiceSlot'));
  }

  set selectedServiceSlot(value: string) {
    if (value !== undefined) {
      sessionStorage.setItem('selectedServiceSlot', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('selectedServiceSlot');
    }
    this.SelectedServiceSlot.next(value);
  }

  get selectedServiceMobilityOption(): string {
    return JSON.parse(sessionStorage.getItem('selectedServiceMobilityOption'));
  }

  set selectedServiceMobilityOption(value: string) {
    if (value !== undefined) {
      sessionStorage.setItem('selectedServiceMobilityOption', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('selectedServiceMobilityOption');
    }
  }

  get selectedServices(): ServiceItemModel[] {
    return JSON.parse(sessionStorage.getItem('selectedServices'));
  }

  set selectedServices(value: ServiceItemModel[]) {
    if (value !== undefined) {
      sessionStorage.setItem('selectedServices', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('selectedServices');
    }
    this.SelectedServices.next(value);
  }

  get serviceAdvisor(): ServiceAdvisorModel {
    return JSON.parse(sessionStorage.getItem('serviceAdvisor'));
  }

  set serviceAdvisor(value: ServiceAdvisorModel) {
    if (value !== undefined) {
      sessionStorage.setItem('serviceAdvisor', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('serviceAdvisor');
    }
    this.ServiceAdvisor.next(value);
  }

  get wipNumber(): number {
    return parseInt(sessionStorage.getItem('wipNumber'), 10);
  }

  set wipNumber(value: number) {
    if (value !== undefined)
      sessionStorage.setItem('wipNumber', value.toString());
  }

  getCustomerInformation(): Observable<UserAccountModel> {
    return this.httpClient.post<UserAccountModel>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetCustomerInformation`
      , new GetCustomerInformationContract(this)
    );
  }

  deleteAccount(): Observable<boolean> {
    return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/RetireAccount`
      , new RetireAccountContract(this)
    ).pipe(map(result => {
      if (result.Result.ErrorCode === 0)
        return true;
      return false;
    }));
  }

  generateAuthorizationHeader(token: string, customerId: string, password: string): HttpHeaders {
    const sha = new jsSHA('SHA-384', 'TEXT');
    // This exposes the ey for the market - must be off
    sha.setHMACKey(this.configService.configSettings.sharedKey, 'TEXT');
    // DO this
    sha.update(token);
    this.dataHubHash = btoa(`${customerId}:${sha.getHMAC('HEX')}:${password}`);
    this.dataHubHash = this.dataHubHash;
    return new HttpHeaders({
      Authorization: `DataHub-Hash ${this.dataHubHash}`
    });
  }

  generateAuthorizationHeaderSecure(token: string, customerId: string, password: string): HttpHeaders {
    // TODO: Security Fixes
    return new HttpHeaders({
      Authorization: this.dataHubHash
    });
  }

  // REplace with new Secure password
  // Then Call checkpassword

  // Replace sharedkey with key from secureAPI

  //Remove
  // REquest Token
  // Checkpassword
  // use the same token from the secure request

  requestToken(customerId?: string): Observable<TokenResponse> {
    // console.log("Conf", this.configService.configSettings);
    // https://nodeexpresssolapi.azurewebsites.net/requestAdminToken
    // TODO check if AGMC is test
    // this.configService.configSettings.communityId === "AGMC"
    // http://localhost:4000
    // || this.configService.configSettings.communityId === "BMWOM"
    // || this.configService.configSettings.communityId === "BMSGEKHT"
    if (this.configService.configSettings.isCRM|| this.configService.configSettings.hasSolServer || this.configService.configSettings.communityId === "BMWKSAL" || this.configService.configSettings.communityId === "BMWOM" ) {
      return this.httpClient.post<TokenResponse>(`${environment.solAPIUrl}/api/v1/requestAdmin
      `
      , new  RequestAdminToken(this.configService));

    }



    else {
      this.customerId = customerId ? customerId : this.generateGuid();
    return this.httpClient.post<TokenResponse>(`${environment.solServerUrl}/api/serviceOnline/request-admin-token`
      , new  RequestAdminToken(this.configService));
      // return this.httpClient.post<TokenResponse>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/RequestToken`
      // , new RequestToken(this.configService, this.customerId));
    }

  }

  activateToken(token: string): Observable<Result> {
    const headers = this.generateAuthorizationHeader(token, this.customerId, 'ActivateToken');
    return this.httpClient.post<Result>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ActivateToken`
      , new ActivateTokenContract(this.configService), { headers });
  }


  registerAccount(accountModel: RegisterAccountContract): Observable<any> {
    return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/RegisterCustomer`
      , accountModel
    );
  }

  getMachineToken(): Observable<any> {
    return this.httpClient.get<any>(`${environment.solAPIUrl}/api/v1/auth/getWebToken`
    );
  }

  registerAccountTitan(password: string, accountModel: RegisterAccountContract): Observable<any> {
    const body: TitanRegisterModel = {
      UserName: accountModel.EmailAddress,
      Email: accountModel.EmailAddress,
      Password: password,
      ConfirmPassword: password,
      DealershipId: parseInt(accountModel.RooftopId),
      FirstName: accountModel.FirstName,
      Surname: accountModel.SurName,
      AllowNotification: true,
      PhoneNumber: accountModel.MobileTelNo,
      Customer: {
        DealershipId: parseInt(accountModel.RooftopId),
        Email: accountModel.EmailAddress,
        FirstName: accountModel.FirstName,
        LastName: accountModel.SurName,
        MiddleName: '',
        Name: accountModel.FirstName,
        Mobile: accountModel.MobileTelNo
      }
    }
    const headers: HttpHeaders = new HttpHeaders({ DealerId: this.configService.configSettings.titanCommunity });
    return this.httpClient.post<any>(`${environment.titanUrl}Account/Register`, body, { headers });
  }

  generateGuid() {
    function S4() {
      return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }
    return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
  }

  updateCustomerInformation(customerInfo: UpdateCustomerInformationContract): Observable<Result> {
    return this.httpClient.post<Result>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/UpdateCustomerInformation`
      , customerInfo
    );
  }

  updateCustomerInformationTitan(customerInfo: UpdateCustomerInformationContract): Observable<any> {
    const customer: TitanCustomerModel = {
      Id: this.userInfo.CustomerId,
      Email: customerInfo.EmailAddress,
      FirstName: customerInfo.FirstName,
      HomePhone: customerInfo.HomeTelNo.toString(),
      LastName: customerInfo.SurName,
      DealershipId: parseInt(this.selectedDealer.RooftopId),
      WorkPhone: customerInfo.WorkTelNo.toString(),
      Name: customerInfo.FirstName,
      MiddleName: '',
      Mobile: customerInfo.MobileTelNo.toString(),
      IsOrganisation: false,
      Note: '',
      IsSendConfirmEmail: false,
      ContactCustomer: null,
      StreetAddress: {
        StreetNo: customerInfo.Address1,
        StreetLine1: customerInfo.Address2,
        StreetLine2: customerInfo.Address3,
        PostCode: customerInfo.PostalCode,
        State: customerInfo.Address2,
        Suburb: customerInfo.Address1
      }
    };
    return this.httpClient.post(`${environment.titanUrl}Customer`, customer);
  }

  resetPassword(email: string): Observable<Result> {
    return this.httpClient.post<Result>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ResetPassword`
      , new ResetPasswordContract(this.configService, email, this.translateService)
    );
  }

  resetPasswordTitan(email: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ DealerId: this.configService.configSettings.titanCommunity });
    return this.httpClient.post(`${environment.titanUrl}Account/ForgotPassword`, { Email: email }, { headers });
  }

  updateUserInfo(customerInfo: UpdateCustomerInformationContract) {
    const info = this.userInfo;
    info.EmailAddress = customerInfo.EmailAddress;
    info.FirstName = customerInfo.FirstName;
    info.HomeTelNo = customerInfo.HomeTelNo;
    info.MobileTelNo = customerInfo.MobileTelNo;
    info.PostalCode = customerInfo.PostalCode;
    info.SurName = customerInfo.SurName;
    info.Address1 = customerInfo.Address1;
    info.Address2 = customerInfo.Address2;
    info.Address3 = customerInfo.Address3;
    info.Title = customerInfo.Title;
    info.WorkTelNo = customerInfo.WorkTelNo;
    this.setUserInfo(info);
  }

  doResetPassword(token: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.requestToken(this.configService.configSettings.activationUser).pipe(take(1)).subscribe((result: any) => {
        this.resetPasswordApi(token, result.token, password).pipe(take(1)).subscribe(activateResult => {
          resolve(true);
        });

      });
    });
  }


  doResetPasswordTitan(token: string, password: string, email: string): Observable<any> {
    const body = {
      Email: email,
      Password: password,
      ConfirmPassword: password,
      Code: token
    }
    const headers: HttpHeaders = new HttpHeaders({ DealerId: this.configService.configSettings.titanCommunity });
    return this.httpClient.post<any>(`${environment.titanUrl}Account/ResetPassword`, body, { headers });
  }

  resendActivationMail(email: string): Observable<any> {
    return this.httpClient.post(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/SendActivationEmail`
      , new ResendActivationContract(this.configService, email, this));
  }


  activateUser(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.requestToken(this.configService.configSettings.activationUser).pipe(take(1)).subscribe((result: any) => {
        // console.log(result);
        this.activateUserApi(token, result.token).subscribe((result) => {
          resolve(true);
        })
      });
    });
  }

  unlockUser(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.requestToken(this.configService.configSettings.activationUser).pipe(take(1)).subscribe((result: any) => {
        // console.log(result);
        this.unlockUserApi(token, result.token).subscribe((result) => {
          resolve(true);
        })
      });
    });
  }

  activateUserTitan(token: string, email: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ DealerId: this.configService.configSettings.titanCommunity });
    return this.httpClient.get<any>(
      `${environment.titanUrl}Account?code=${token}&email=${email}&DealerId=${this.configService.configSettings.titanCommunity}`
    );
  }

  resetPasswordApi(token: string, authToken: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `DataHub-Token ${authToken}`
    });
    return this.httpClient.post<Result>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ResetPasswordConfirmation`
      , new DoResetPasswordContract(token, password, this.translateService, this.configService.configSettings.dealerInclusion[0].dealerCode), { headers });
  }

  activateUserApi(token: string, authToken: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `DataHub-Token ${authToken}`
    });
    return this.httpClient.post<Result>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ActivateCustomer`
      , new ActivateUserContract(token, this.translateService), { headers });
  }

  unlockUserApi(token: string, authToken: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `DataHub-Token ${authToken}`
    });
    return this.httpClient.post<Result>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/Unlock`
      , new ActivateUserContract(token, this.translateService), { headers });
  }

  checkPassword(token: string, userName: string, password: string): Observable<any> {
    const headers = this.generateAuthorizationHeader(token, userName, password);

    return this.httpClient.post(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/CheckPassword`
      , new CheckPassword(this.configService), { headers });
  }
}

class TitanRegisterModel {
  Id?: string;
  UserName: string;
  DealershipId: number;
  Email: string;
  PasswordHash?: string;
  PhoneNumber: string;
  CommenceDate?: Date;
  TerminationDate?: Date;
  AllowNotification: boolean;
  FirstName: string;
  Surname: string;
  Password: string;
  ConfirmPassword: string;
  SupervisorUserName?: string;
  DmsUserId?: string;
  LocationId?: number;
  StatusCode?: number;
  IsIntegration?: boolean;
  CreationUserName?: string;
  CreationTimestamp?: Date;
  LastUpdateUserName?: string;
  LastUpdateTimestamp?: Date;
  RoleIds?: string[];
  RoleId?: string;
  IsInternal?: boolean;
  AppRoleIds?: string[];
  AppRoleId?: string;
  CustomerId?: number;
  Customer: {
    Id?: number;
    DealershipId: number;
    StatusCode?: number;
    Name: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Email: string;
    ThirdPartyCustomerId?: string;
    Note?: string;
    HomePhone?: string;
    Mobile?: string;
    WorkPhone?: string;
    DmsCustomerKey?: number;
    StreetAddress?: {
      StreetNo: string;
      AddressType: number;
      StreetLine1: string;
      StreetLine2: string;
      Suburb: string;
      State: string;
      PostCode: string;
      CountryCode: number;
      SubDistrict: string
    };
    PostalAddress?: {
      StreetNo: string;
      AddressType: number;
      StreetLine1: string;
      StreetLine2: string;
      Suburb: string;
      State: string;
      PostCode: string;
      CountryCode: number;
      SubDistrict: string
    };
    IsOrganisation?: true;
    IsSendConfirmEmail?: true;
  }
}

class ActivateUserContract {
  constructor(token: string, translate: TranslateService) {
    this.Token = token;
    this.Language = translate.currentLang//.substring(0, 2);
  }
  Token: string;
  Language: string;
}

class DoResetPasswordContract {
  constructor(token: string, password: string, translate: TranslateService, roofTopId) {
    this.Token = token;
    this.Password = password;
    this.Language = translate.currentLang//.substring(0, 2);
    this.RooftopId = roofTopId
  }
  Token: string;
  Language: string;
  Password: string;
  RooftopId: string;
}

class CheckPassword {
  constructor(configService: ConfigService) {
    this.RoofTopId = configService.configSettings.dealerInclusion[0].dealerCode;
  }
  RoofTopId: string;
}

class ResendActivationContract {
  constructor(configService: ConfigService, email: string, userService: UserService) {
    this.CustomerId = email;
    this.RoofTopId = configService.configSettings.dealerInclusion[0].dealerCode;



    if (userService.languageSelected === "en_ae") {
      this.Language = userService.languageSelected.substring(0, 2) + '-ZA';
    } else {
      this.Language = userService.languageSelected.substring(0, 2);
    }
  }
  RoofTopId: string;
  CustomerId: string;
  Language: string;
}

class ResetPasswordContract {
  constructor(configService: ConfigService, email: string, translate: TranslateService) {
    this.CustomerId = email;
    this.RedirectUrl = configService.configSettings.dealerInclusion[0].url;
    this.RooftopId = configService.configSettings.dealerInclusion[0].dealerCode;
    this.Language = translate.currentLang.includes('ja-JP') || translate.currentLang.includes('en-JM') ? translate.currentLang : translate.currentLang.substring(0, 2);
  }
  Application: number = 0;
  CustomerId: string;
  Language: string;
  RedirectUrl: string;
  RooftopId: string;
}

export class UpdateCustomerInformationContract {
  Address1: string;
  Address2: string;
  Address3: string;
  CustomerId: string;
  EmailAddress: string;
  FirstName: string;
  HomeTelNo: number | string;
  Language: string;
  MobileTelNo: number | string;
  PostalCode: string;
  RooftopId: string;
  SurName: string;
  Title: string;
  WorkTelNo: number | string;
}

class TokenResponse {
  Result: Result;
  Token: string;
}

export class SecureTokenResponse {
  Result: Result;
  errorCode: string | number;
  token: {token: string} | string;
}

class Result {
  ErrorCode: number;
}

class ActivateTokenContract {
  constructor(configService: ConfigService) {
    this.RoofTopId = configService.configSettings.dealerInclusion[0].dealerCode;
  }
  RoofTopId: string;
}

class RequestToken {
  constructor(configService: ConfigService, customerId: string) {
    this.CustomerId = customerId;
    this.PartnerId = configService.configSettings.partnerId;
    this.Version = configService.configSettings.version;
    this.CommunityId = configService.configSettings.communityId;
  }

  CustomerId: string;
  PartnerId: string;
  Version: string;
  CommunityId: string;
}

export class RegisterAccountContract {
  CustomerId: string;
  CustomerIdType: number;
  EmailAddress: string;
  FirstName: string;
  Language: string;
  MobileTelNo: string;
  Password: string;
  RedirectUrl: string;
  RooftopId: string;
  SurName: string;
  Title: string;
  Address1?: string;
  Address2?: string;
  Address3?: string;
  PostalCode?: string;
}

class RetireAccountContract {
  constructor(userService: UserService) {
    this.CustomerId = userService.customerId;
    this.Language = this.Language = userService.languageSelected.substring(0, 2);
  }

  RooftopId: string = '';
  CustomerId: string;
  DeleteOpenAppointments: boolean = true;
  Language: string;
}

class GetCustomerInformationContract {
  constructor(userService: UserService) {
    this.CustomerId = userService.customerId;
  }
  CustomerId: string;
}

class RequestAdminToken {
  constructor(configService: ConfigService) {
    this.partnerId = configService.configSettings.partnerId;
    this.version = configService.configSettings.version;
    this.communityId = configService.configSettings.communityId;
    this.roofTopId = configService.configSettings.dealerInclusion[0].dealerCode;

  }


  customerId: string;
  partnerId: string;
  version: string;
  communityId: string;
  password: string;
  roofTopId: string;
}





