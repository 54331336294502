import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { BookingService } from 'src/app/services/booking/booking.service';
import { UserService } from 'src/app/services/user-account/user/user.service';

@Component({
    selector: 'app-cancel-booking',
    templateUrl: './cancel-booking.component.html',
    styleUrls: ['./cancel-booking.component.less']
})

export class CancelBookingComponent implements OnInit {
    wipNumber: number;
    appointmentId: number;
    rooftopId: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly bookingService: BookingService,
        private readonly userService: UserService
    ) {

    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: Params) => {
            if (params && params.params && params.params.wipNumber) {
                this.wipNumber = params.params.wipNumber;
                this.appointmentId = params.params.appointmentId;
                this.rooftopId = params.params.rooftopId;
            }
        });
    }

    closePopup() {
        this.router.navigate([{ outlets: { popup: null } }]);
    }

    deleteBooking() {
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.bookingService.deleteBookingTitan(parseInt(this.appointmentId.toString())).pipe(take(1)).subscribe(result => {
                if (result) {
                    this.bookingService.clearBookingData(true);
                    this.router.navigate([{ outlets: { popup: null } }]);
                }
            });
        } else {
            this.bookingService.deleteBooking(this.wipNumber, this.appointmentId, this.rooftopId).subscribe(result => {
                if (result)
                    this.router.navigate([{ outlets: { popup: null } }]);
            });
        }
    }
}
