import { SafeResourceUrl } from '@angular/platform-browser';

export class ServiceAdvisorModel {
    constructor(value) {
        this.advisorID = value.AdvisorID;
        this.advisorName = value.AdvisorName;
    }
    advisorID: string;
    advisorName: string;
    advisorPicture?: SafeResourceUrl;
    advisorPictureData?: string;
    selected: boolean;
}
