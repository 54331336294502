import { Component, OnInit } from '@angular/core';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { DealerModel } from 'src/app/services/dealer/dealer.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { take } from 'rxjs/operators';
import { LoginService } from 'src/app/services/user-account/login/login.service';
import { LevelOneDealerUrl } from 'src/app/services/config/config.model';
import { InactivityService } from 'src/app/services/user-account/inactivity/inactivity.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.less']
})
export class DealerComponent implements OnInit {
  dealerList: DealerModel[];
  selectedDealer: DealerModel | any;
  selectedDealerId: string;
  latitude = -28.68352;
  longitude = -147.20785;
  mapType = 'roadmap';
  selectedMarker: any;
  brand: string;
  zoom: number = 6;
  canFilterDealers: boolean;
  CustomerIDFromParams: string;
  levelOneDealers:  LevelOneDealerUrl[];
  showCrmProfileNotFound = false;
  loginMessage = "Service Online Profile not found."
  /**
   * add a flag in the community file if another country supports level1 dealers
   */
  // hasLevelOneDealers = this.configService.configSettings.language === "ja-JP";
  selectedLevelOneDealer = false;
  selectedLevelOneDealerToVisit = "";
  crmUser: string;
  isRtl: boolean;

  constructor(
    private readonly dealerService: DealerService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly bookingService: BookingService,
    private readonly configService: ConfigService,
    private readonly loginService: LoginService,

    private readonly route: ActivatedRoute,
    private readonly inactivityService: InactivityService,
    private readonly vehicleService: VehicleService,
    private readonly translationService: TranslateService,
  ) { }

  ngOnInit() {

    this.translationService.onLangChange.subscribe(result => {

      this.isRtl = result.lang.includes('ar_');
    });
    if (this.configService.configSettings.language.indexOf("sg") > -1) {

      this.zoom = this.configService.configSettings.zoom
    }
    if (this.configService.configSettings.isCRM) {
      // debugger
      this.zoom = this.configService.configSettings.zoom
      sessionStorage.removeItem("vehicleList")
      sessionStorage.removeItem("customerId")
      sessionStorage.removeItem("selectedVehicle")

      sessionStorage.removeItem("selectedServices")
      sessionStorage.removeItem("selectedServiceMobilityOption")
      sessionStorage.removeItem("selectedServiceMobilityOptionAddress")
      sessionStorage.removeItem("selectedServiceMobilityDeliveryOptionAddress")
      sessionStorage.removeItem("selectedServiceSlot")
      // sessionStorage.removeItem("selectedServiceSlot")
      sessionStorage.removeItem("selectedServiceDate")
      sessionStorage.removeItem("wipNumber")
      sessionStorage.removeItem("serviceAdvisor")

      this.vehicleService.zaVehicles.next([])
    }
    this.brand = this.configService.brand;
    this.canFilterDealers = this.configService.configSettings.canFilterDealers;
    // this.getDealerList();
    this.getDealerListSecurely();

    this.route.queryParams.subscribe(params => {
      this.crmUser = params.userId || sessionStorage.getItem("userId");
      sessionStorage.setItem("userId", params.userId)


      // TODO use from one source
    //  this.userService.crmUserToken.next(params.userId);
     this.userService.crmUserToken.next(this.crmUser);
    });

    // if (this.configService.configSettings.isCRM) {
    //   this.zoom = 15
    //   // this.zoom = 10
    // }
  }

  getDealerList() {
    const includeDealers = this.configService.configSettings.dealerInclusion.map(x => x.dealerCode);
    if (this.configService.configSettings.nonAutolineDealers) {
      this.loginService.login(this.configService.configSettings.activationUser, this.configService.configSettings.activationPassword).then(() => {
        this.getDealerListCall(includeDealers);
      });
    } else {
      this.getDealerListCall(includeDealers);
    }

  }

  getDealerListSecurely() {
    const includeDealers = this.configService.configSettings.dealerInclusion.map(x => x.dealerCode);
    // TODO: Security Refactor

    if (this.configService.configSettings.nonAutolineDealers) {
      this.loginService.loginToMapSecurely().then(() => {
        this.getDealerListCall(includeDealers);
      });
    } else {
      this.getDealerListCall(includeDealers);
    }
  }

  getDealerListCall(includeDealers) {

    this.dealerService.getDealerList().pipe(take(1)).subscribe(result => {
      this.dealerList = result.filter(x => x.SOLEnabled &&
        includeDealers.includes(x.RooftopId.trim()));

      if (this.configService.configSettings.nonAutolineDealers) {
        this.dealerService.getNonAutolineDealerList().pipe(take(1)).subscribe(nonAutolineDealers => {
          nonAutolineDealers.forEach(dealer => {
            this.dealerService.dealerList.push(dealer);
          });
        });
      }

      if (this.configService.configSettings.hasTitanDealers) {
        this.dealerService.getTitanToken().pipe(take(1)).subscribe(token => {
          this.dealerService.getTitanDealerList(token).pipe(take(1)).subscribe(result => {
            result.forEach(dealer => {
              this.dealerService.dealerList.push(dealer);
            });
          });
        });

      }

      this.dealerService.dealerList = this.dealerList.sort((a, b) => a.DealershipName < b.DealershipName ? -1 : 1);
      this.latitude = Number.parseFloat(result[0].Latitude);
      this.longitude = Number.parseFloat(result[0].Longitude);
    });
  }

  selectMarker(event, dealer) {
    this.latitude = event.latitude;
    this.longitude = event.longitude;
    this.selectedDealer = dealer;
    this.selectedDealerId = dealer.RooftopId;
    this.zoom = 15;
  }

  selectDealer(event) {
    if (!event) return;
    this.selectedDealerId = event.target.value;
    this.selectedDealer = this.dealerList.filter(dealer => dealer.RooftopId == this.selectedDealerId)[0];

    if (this.selectedDealer.Latitude)
      this.latitude = Number.parseFloat(this.selectedDealer.Latitude);
    if (this.selectedDealer.Longitude)
      this.longitude = Number.parseFloat(this.selectedDealer.Longitude);

    // this.zoom = this.configService.configSettings.language.indexOf('sg') > -1 ? 20 : 15;
    this.zoom = 20; // toDo when sg adds new dealers refactor

  }

  deSelectDealer() {
    this.selectedDealer = undefined;
    this.selectedDealerId = undefined;
  }

   async startBooking() {
    // debugger
    if (this.configService.configSettings.isCRM && this.crmUser) {

      try {
       const crmUSerDetails = await this.loginService.loginToCRM(this.crmUser);

      //  console.log("CRMUserDetails-->", crmUSerDetails);
       this.userService.userInfo = crmUSerDetails
        // debugger
         this.userService.selectedDealer = this.selectedDealer;
        //  console.log("THISCUSTOMER-->", this.CustomerIDFromParams);
        //  console.log("userServe-->", this.userService);

         this.loginService.loginSecure(crmUSerDetails.CustomerInformationResult.EmailAddress, "").then(result => {
          // debugger
           if (result) {
             if (this.route.snapshot.queryParams.dealer || this.route.snapshot.queryParams.userId) {
              //  debugger
               this.dealerService.getDealerList().pipe(take(1)).subscribe(result => {
                if (!this.configService.configSettings.isCRM) {
                  this.userService.selectedDealer = result.find(dealer => dealer.RooftopId.trim() === this.route.snapshot.queryParams.dealer);
                }
                 this.inactivityService.setInactivityTimer(25);
                 if (!this.configService.configSettings.isCRM) {
                 this.userService.getCustomerInformation().pipe(take(1)).subscribe(userResult => {
                  // debugger
                   if (userResult.Result.ErrorCode === 0) {
                     this.userService.userInfo = userResult.CustomerInformationResult;
                    //  console.log("NOW-->", this.userService.userInfo);

                   } else {
                    // console.log("USER==>", userResult);

                   }
                 })
                  } ;
               },
               (error) => {
                //  console.log("ERR", error);
                 this.showCrmProfileNotFound = true;
                //  console.log("err===>", );
                 this.loginMessage = error.message

               }
               );
             }
           }

         })
         this.createBooking(() => this.navigateToSelectService());
         return

      } catch (error) {
        // console.log("CATCH ERROR-->", error);

        if (error.message || !error.isActivated) {
          this.loginMessage = error.message
        }
        this.showCrmProfileNotFound = true;
        return
      }

    }


  
    if (this.selectedLevelOneDealer) {
      window.open(this.selectedLevelOneDealerToVisit, '_newtab');
    } else {
      this.userService.selectedDealer = this.selectedDealer;
      if (this.configService.configSettings.nonAutolineDealers || this.configService.configSettings.hasTitanDealers) {
        if (this.selectedDealer.SOLEnabled || this.selectedDealer.TitanDealer) {
          this.router.navigateByUrl('/user-account/login?doLogin=true');
        } else {
          this.router.navigateByUrl('/level-one-booking');
        }
      } else {
        this.createBooking(() => this.navigateToSelectService());
      }
    }

  }

  createBooking(callback) {
    this.bookingService.createBooking().pipe(take(1)).subscribe(result => {
      // debugger
       // TODO: investigate further as to why this booking id was set
      // this.userService.bookingId = result;
      callback();
    });
  }

  navigateToSelectService() {
    this.router.navigateByUrl('/select-service');
  }
}
