import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(
        private readonly userService: UserService
    ) { }
    intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        // TODO: FIX WITH TSHEDISO
        if (this.userService.token &&
            request.url.indexOf('CheckPassword') === -1 &&
            request.url.indexOf('ActivateToken') === -1 &&
            request.url.indexOf('ActivateCustomer') === -1 &&
            request.url.indexOf(environment.titanUrl) === -1) {
            let tokenString;
            tokenString = `DataHub-Token ${this.userService.token}`;
            // tokenString = `DataHub-Token ${this.userService.token}`;
            const req = request.clone({
                headers: request.headers.set('Authorization', tokenString)
            });
            return next.handle(req);
        } else if (request.url.indexOf('Dealership') === -1 && request.url.indexOf(environment.titanUrl) !== -1) {
            let tokenString;
            tokenString = `Bearer ${this.userService.token}`;
            const req = request.clone({
                headers: request.headers.set('Authorization', tokenString)
            });
            return next.handle(req);
        }

        return next.handle(request);
    }
}
