import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VehicleService} from 'src/app/services/vehicle/vehicle.service';
import {VehicleModel} from 'src/app/services/vehicle/vehicle.model';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from 'src/app/services/user-account/user/user.service';
import {take} from "rxjs/operators";

@Component({
  selector: 'app-delete-vehicle',
  templateUrl: './delete-vehicle.component.html',
  styleUrls: ['./delete-vehicle.component.less']
})
export class DeleteVehicleComponent implements OnInit {
  vehicle: VehicleModel;
  deleteReason: Reasons[];
  reasonSelected: string;
  reasonForDeletion: string;
  validationRequiredKeyProp = "validation-required"

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly vehicleService: VehicleService,
    private readonly translateService: TranslateService,
    private readonly userService: UserService
  ) {
  }

  ngOnInit() {
    this.translateService.get(['label_vehiclesold', 'label_vehiclewrittenoff', 'label_vehicle_stolen', 'delete_vehicle_reason_label']).subscribe(result => {
      this.deleteReason = [
        {
          id: 'Sold',
          description: result.label_vehiclesold
        },
        {
          id: 'Scrapped',
          description: result.label_vehiclewrittenoff
        },
        {
          id: 'Stolen',
          description: result.label_vehicle_stolen
        }
      ];
      this.reasonForDeletion = result.delete_vehicle_reason_label;
    });

    this.route.paramMap.subscribe(params => {
      this.vehicle = this.vehicleService.vehicleList.find(x => x.registrationNumber === params.get('registrationNumber'));
    });
  }

  deleteVehicle() {
    if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
      const model = {
        VehicleId: this.vehicle.vehicleId,
        OwnerCustomerId: this.userService.userInfo.CustomerId
      };
      this.vehicleService.deleteVehicleTitan(model, this.vehicle).subscribe(() => {
        this.vehicleService.removeVehicle(this.vehicle.vehicleId);

        this.userService.userInfo$.subscribe(result => {
          if (result)
            this.vehicleService.getVehicleListTitan(result.CustomerId).pipe(take(1)).subscribe(result => {
              if (!result || result.length === 0) {
                this.router.navigate([{outlets: {popup: ['add-vehicle']}}]);
              } else {
                this.router.navigate([{outlets: {popup: null}}]);
              }
            });
        });
      });
    } else {
      this.vehicleService.deleteVehicle(this.vehicle, this.reasonSelected).subscribe(result => {
        if (result) {
          this.vehicleService.removeVehicle(this.vehicle.vehicleId);
        }
        this.vehicleService.getVehicleList().subscribe(vehicleResult => { // do not unsubscribe
          if (!vehicleResult || vehicleResult.length === 0) {
            this.router.navigate([{outlets: {popup: ['add-vehicle']}}]);
          } else {
            this.router.navigate([{outlets: {popup: null}}]);
          }
        });
      });
    }
  }

  resonChanged(reason) {
    this.reasonSelected = reason;
  }

  closePopup() {
    this.router.navigate([{outlets: {popup: null}}]);
  }

}

class Reasons {
  id: string;
  description: string;
}
