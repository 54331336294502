export class OptionsModel {
    constructor(value) {
        this.advisorsPhotos = value.AdvisorsPhotos;
        this.defaultOption = value.DefaultOption;
        this.firstAddressLabel = value.FirstAddressLabel;
        this.initialAppFieldName = value.InitialAppFieldName;
        this.initialAppTimeRequired = value.InitialAppTimeRequired;
        this.jobCodes = value.JobCodes;
        this.optionAdvisor = value.OptionAdvisor;
        this.optionDescription = value.OptionDescription;
        this.optionDisplayName = value.OptionDisplayName;
        this.optionID = value.OptionID;
        this.optionPrice = value.OptionPrice;
        this.requestAddress = value.RequestAddress;
        this.requestPostCode = value.RequestPostCode;
        this.requestSecondAddress = value.RequestSecondAddress;
        this.requestSecondPostCode = value.RequestSecondPostCode;
        this.secondAddressLabel = value.SecondAddressLabel;
        this.secondAppFieldName = value.SecondAppFieldName;
        this.secondAppTimeRequired = value.SecondAppTimeRequired;
        this.timeCode = value.TimeCode;
        this.timeCodeDate = value.TimeCodeDate;
    }
    advisorsPhotos: boolean;
    defaultOption: boolean;
    firstAddressLabel: string;
    initialAppFieldName: string;
    initialAppTimeRequired: boolean;
    jobCodes: string[];
    optionAdvisor: string;
    optionDescription: string;
    optionDisplayName: string;
    optionID: string;
    optionPrice: number;
    requestAddress: boolean;
    requestPostCode: boolean;
    requestSecondAddress: boolean;
    requestSecondPostCode: boolean;
    secondAddressLabel: string;
    secondAppFieldName: string;
    secondAppTimeRequired: boolean;
    timeCode: string[];
    timeCodeDate: string;
    selected?: boolean;
}
