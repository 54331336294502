import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    weekdays: string[] = [];
    months: string[] = [];

    constructor(
        private readonly translateService: TranslateService
    ) {
        super();
        this.translateValues();
        this.translateService.onLangChange.subscribe(() => {
            this.translateValues();
        });
    }

    translateValues() {
        this.weekdays = [];
        this.months = [];
        this.translateService.get(['label_mon', 'label_tue', 'label_wed', 'label_thu', 'label_fri', 'label_sat', 'label_sun']).subscribe(result => {
            Object.values(result).forEach((dayName: string) => {
                this.weekdays.push(dayName);
            });
        });

        this.translateService.get(['label_january', 'label_february', 'label_march', 'label_april', 'label_may',
            'label_june', 'label_july', 'label_august', 'label_september', 'label_october', 'label_november', 'label_december']).subscribe(result => {
                Object.values(result).forEach((monthName: string) => {
                    this.months.push(monthName);
                });
            });
    }

    getWeekdayShortName(weekday: number): string {
        return this.weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return this.months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}