export class Model {
    constructor() {
    }
    modelName: string;
    makeCode: string;
    makeName: string;
    modelCode?: string;
    variantCode?: string;
    variantName?: string;
    id?:number;
}

export class GetModelList {
    getModelList(makeCode: string): Model[] {
        const modelList = [
            {
                modelName: '1 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: '2 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: '3 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: '4 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: '5 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: '6 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: '7 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: '8 Series',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'M2',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'M3',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'M4',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'M5',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'X1',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'X2',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'X3',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'X4',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'X5',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'X6',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'X7',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'Z3',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'Z4',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'Other',
                makeCode: 'B',
                makeName: 'BMW'
            },
            {
                modelName: 'i3',
                makeCode: 'E',
                makeName: 'BMW'
            },
            {
                modelName: 'i8',
                makeCode: 'E',
                makeName: 'BMW'
            },
            {
                modelName: 'MINI Cooper Clubman',
                makeCode: 'M',
                makeName: 'MINI'
            },
            {
                modelName: 'MINI Cooper Countryman',
                makeCode: 'M',
                makeName: 'MINI'
            },
            {
                modelName: 'MINI Cooper D Countryman',
                makeCode: 'M',
                makeName: 'MINI'
            },
            {
                modelName: 'MINI Cooper S Clubman',
                makeCode: 'M',
                makeName: 'MINI'
            },
            {
                modelName: 'MINI Cooper S Countryman',
                makeCode: 'M',
                makeName: 'MINI'
            },
            {
                modelName: 'MINI John Cooper Works ALL4 Countryman',
                makeCode: 'M',
                makeName: 'MINI'
            },
            {
                modelName: 'MINI John Cooper Works Clubman',
                makeCode: 'M',
                makeName: 'MINI'
            },
            {
                modelName: 'Other',
                makeCode: 'M',
                makeName: 'MINI'
            }
        ];

        return modelList.filter(x => x.makeCode === makeCode);
    }
}
