import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { ServiceContractService } from './service-contract.service';
import { ServiceContracts } from './service-contact.model';

@Component({
  selector: 'app-service-contract',
  templateUrl: './service-contract.component.html',
  styleUrls: ['./service-contract.component.less'],
})
export class ServiceContractComponent implements OnInit {
  serviceContractItems: ServiceContracts[] = [];
  usersVehicles = [];
  isArabic = false;

  constructor(
    private readonly vehicleService: VehicleService,
    private readonly translateService: TranslateService,
    private readonly serviceContractService: ServiceContractService
  ) {}

  ngOnInit() {
    this.vehicleService.vehicleServiceContracts.subscribe((vehicleContract) => {
      this.serviceContractItems = vehicleContract;
    });

    // this.translateService.onDefaultLangChange.subscribe(result => console.log(result))
this.translateService.setTranslation
    this.isArabic = this.translateService.currentLang.includes('ar_');
    this.translateService.onLangChange.subscribe(result => {

      this.isArabic = result.lang.includes('ar_');

  //     this.serviceContractService
  // .getServiceContractItems(this.serviceContractItems[0].vin17)
  // .subscribe((vehicleContract) => {
  //   this.vehicleService.vehicleServiceContracts.next(vehicleContract);
  // });

    });
  }
}


// debugger
// this.serviceContractService
//   .getServiceContractItems(this.serviceContractItems[0].vin17)
//   .subscribe((vehicleContract) => {
//     this.vehicleService.vehicleServiceContracts.next(vehicleContract);
//   });
