import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config/config.service';
import { ErrorService } from 'src/app/services/error/error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly configService: ConfigService,
        private readonly errorService: ErrorService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(evt => {
                if (this.configService.configSettings &&
                    req.url.includes(this.configService.configSettings.serverUrl) &&
                    !req.url.includes('ChassisPrePopulation') &&
                    evt instanceof HttpResponse) {
                    if (evt.body &&
                        (evt.body.Result || evt.body.ErrorCode) &&
                        (evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode) !== 0 &&
                        (evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode) !== 101 &&
                        (evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode) !== 105 &&
                        (evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode) !== 400 &&
                        (evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode) !== 112 &&
                        (evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode) !== 506 &&
                        (evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode) !== 38
                    ) {
                        this.errorService.show(parseInt(evt.body.Result ? evt.body.Result.ErrorCode : evt.body.ErrorCode));
                    } else {
                        this.errorService.hide();
                    }
                }
            })
        );
    }
}