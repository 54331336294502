import { Component, OnInit } from '@angular/core';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { VehicleModel } from 'src/app/services/vehicle/vehicle.model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CustomerAppointments } from 'src/app/services/booking/booking.model';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ServiceAdvisorService } from 'src/app/services/service-advisor/service-advisor.service';
import { ServiceItemModel } from 'src/app/services/services/service-item.model';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.less']
})

export class BookingsComponent implements OnInit {
    vehicleList: VehicleModel[];
    selectedVehicle: VehicleModel;
    bookingsList: CustomerAppointments[];
    bookingsListTitan: CustomerAppointments[];
    isNonAutoline: boolean;
    vehicleText: string;
    isRtl: boolean;
    brand = "BMW";
    isSingapore = false;
    validationRequiredKeyProp = "validation-required"


    constructor(
        private readonly vehicleService: VehicleService,
        private readonly bookingService: BookingService,
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly translateService: TranslateService,
        private readonly advisorService: ServiceAdvisorService,
        private readonly configService: ConfigService
    ) {
    }

    ngOnInit() {
      this.brand = this.configService.brand
      this.isSingapore = this.configService.configSettings.country === "Singapore"
        this.translateValues();
        this.translateService.onLangChange.subscribe((result) => {
          this.translateValues()
          this.isRtl = result.lang.includes('ar_');
        });
        this.vehicleList = this.vehicleService.vehicleList;
        this.bookingService.bookingDeleted$.subscribe(appointmentId => {
            if (this.bookingsList && this.bookingsList.length > 0) {
                const index = this.bookingsList.findIndex(x => x.AppointmentId.toString() === appointmentId.toString());
                if (index > -1)
                    this.bookingsList.splice(index, 1);
            }
        });
        //TODO: roof top is 1 from api we check for to was ===. i changed to !== to force it to work
        // ** pherhaps update the user service from 2 to be 1
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.bookingService.getListOfAppointmentsTitan(this.userService.userInfo.CustomerId).pipe(take(1)).subscribe(result => {
                this.bookingsListTitan = result.CustomerAppointments.filter(x => x.RoofTopId === this.userService.selectedDealer.RooftopId.toString() &&
                    new Date(x.JobDate) >= new Date());
            });
        }
        console.log("booking-->", this.bookingsList);

    }

    translateValues() {
        this.translateService.get('vahicle').subscribe(result => this.vehicleText = result);
    }

    selectVehicle(vehicleId: number) {
        this.selectedVehicle = this.vehicleList.filter(x => x.vehicleId.toString() === vehicleId.toString())[0];

        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.bookingsList = this.bookingsListTitan.filter(x => x.VehicleChassisNo === this.selectedVehicle.chassisNo && x.VehicleRegistrationNumber === this.selectedVehicle.registrationNumber);
        } else {
            this.bookingService.getListOfAppointments(vehicleId).pipe(take(1)).subscribe(result => {
                if (result.Result.ErrorCode === 0)
                    this.bookingsList = result.CustomerAppointments.filter(x => x.RoofTopId === this.userService.selectedDealer.RooftopId);
                else
                    this.bookingsList = [];
            });
        }
    }

    getJobDescription(job) {
        // return job.JobDescription.includes('Notes') ? job.JobComments : job.JobDescription;
        return  job.JobDescription;
    }

    getJobComments(job) {
        return job.JobComments;
    }

    bookService() {
        this.bookingService.clearBookingData(true);
        this.router.navigate(['/select-service', this.selectedVehicle.vehicleId]);
    }

    cancelBooking(wipNumber: number, appointmentId: number, rooftopId: string) {
        this.router.navigate([{
            outlets: {
                popup: ['cancel-booking', wipNumber, appointmentId, rooftopId]
            }
        }]);
    }

    updateBooking(booking: CustomerAppointments) {
        const jobsList: ServiceItemModel[] = [];
        booking.Jobs.forEach(job => {
            jobsList.push({
                jobCode: parseInt(job.JobCode.toString().trim()),
                jobDescription: job.JobDescription,
                jobPrice: job.JobPrice,
                jobTime: '',
                jobExtDescription: job.JobDescription,
                jobTypeCode: '',
                parentJobCode: job.ParentJobCode.trim(),
                productCode: job.ProductCode,
                selected: true,
                jobComments: job.JobComments ? job.JobComments : ''
            });
        });
        this.userService.selectedServices = [];
        this.userService.selectedServices = jobsList;
        const notes = booking.Jobs.filter(x => x.JobCode.toString().toUpperCase().includes('NOTES'));
        if (notes && notes.length > 0) {
            this.userService.serviceNotes = notes[0].JobComments;
        }

        // if(booking.TransportMethod !== "STAN") {
          this.userService.selectedServiceMobilityDeliveryOptionAddress = booking.SecondTransAddress1;
          this.userService.selectedServiceMobilityOptionAddress = booking.TransAddress1;
          this.userService.selectedServiceMobilityOption = booking.TransportMethod;
        // }


        this.userService.selectedServiceDate = new Date(booking.JobDate);
        this.userService.selectedServiceSlot = booking.FirstAppTime;
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.advisorService.getServiceAdvisorsTitan().subscribe(result => {
                const advisor = result.find(advisor => advisor.advisorID === booking.AdvisorId);
                advisor.selected = true;
                this.userService.serviceAdvisor = advisor;
                this.router.navigate([{ outlets: { popup: ['update-booking', booking.AppointmentId, this.selectedVehicle.vehicleId, booking.WIPNo, booking.RoofTopId] } }]);
            });
        } else {
            this.advisorService.getServiceAdvisors(booking.WIPNo).pipe(take(1)).subscribe(result => {
                this.userService.serviceAdvisor = result.find(advisor => advisor.advisorID === booking.AdvisorId);
                this.router.navigate([{ outlets: { popup: ['update-booking', booking.AppointmentId, this.selectedVehicle.vehicleId, booking.WIPNo, booking.RoofTopId] } }]);
            });
        }
    }
}
