import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-mini-font-ar',
    template: '<div></div>',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./../../../styles/typography-mini-ar.less']
})
export class MINIFontArComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}