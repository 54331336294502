import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { take } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less']
})

export class ResetPasswordComponent implements OnInit {
    emailAddress: string;
    token: string;
    showSuccess: boolean;
    isSingapore: boolean;

    constructor(
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly route: ActivatedRoute,
        private configService: ConfigService
    ) {

    }

    ngOnInit() {
        this.isSingapore = this.configService.configSettings.language.indexOf('sg') > -1;
        const paramMap = this.route.snapshot.queryParams;
        if (paramMap.token)
            this.token = paramMap.token;
    }

    resetPasswordDone() {
        this.showSuccess = true;
        setTimeout(() => {
            this.router.navigateByUrl('/');
        }, 6000);
    }

    resetPassword() {
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.userService.resetPasswordTitan(this.emailAddress).pipe(take(1)).subscribe(() => {
                this.resetPasswordDone();
            });
        } else {
            this.userService.resetPassword(this.emailAddress).pipe(take(1)).subscribe(result => {
                if (result.ErrorCode === 0)
                    this.resetPasswordDone();
            });
        }
    }

    navigateToLogin() {
        this.router.navigateByUrl('/');
    }
}
