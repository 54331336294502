import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user-account/user/user.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServiceItemModel } from './service-item.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly userService: UserService,
    private readonly configService: ConfigService,
    private readonly translate: TranslateService
  ) { }

  getLevelOneServices(): Observable<ServiceItemModel[]> {
    return this.httpClient.get<ServiceItemModel[]>(`${environment.serviceOnlineService}/assets/serviceItems/${this.configService.configSettings.communityId}.json`);
  }

  getRecommendedService(): Observable<ServiceItemModel[]> {
    const servicesList: ServiceItemModel[] = [];
    return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetRecommendedService`
      , new GetRecomendedServiceModel(this.userService, this.translate)
    ).pipe(map(result => {
      if (result.Result.ErrorCode === 0) {
        result.Results.PriceListData.forEach(serviceItem => {
          servicesList.push(new ServiceItemModel(serviceItem));
        });
      }
      return servicesList;
    }));
  }



  getServiceItemsTitan(): Observable<ServiceItemModel[]> {
    const serviceItemList: ServiceItemModel[] = [];
    return this.httpClient.get<ServiceCodesTitan[]>(`${environment.titanUrl}Booking/ServiceOperationCodes?makeId=${this.configService.configSettings.oemId}&locationCode=${this.userService.selectedLocation.LocationCode}`).pipe(map(serviceItems => {
      serviceItems.forEach(item => {
        if (serviceItemList.length > 10)
          return serviceItemList;
        serviceItemList.push({
          id: item.Id,
          jobCode: item.ServiceCode,
          jobDescription: item.Name,
          jobExtDescription: item.Name,
          jobPrice: 10,
          jobTime: '',
          jobTypeCode: item.DmsCodebookSourceCode.toString(),
          parentJobCode: '',
          productCode: item.ServiceCode,
          selected: false,
          jobComments: ''
        });
      });
      return serviceItemList;
    }));
  }
}

class ServiceCodesTitan {
  Id: number;
  DealershipId: number;
  ServiceType: number;
  Name: string;
  StatusCode: number;
  DmsCodebookSourceCode: number;
  ServiceCode: string;
  DmsOperationCode: string;
  StandardPrice: number;
  StandardHour: number;
  SectionCode: number;
  ClassCode: number;
  Description: string;
  ServiceMaintenanceKms: number;
  ServiceMaintenanceMonth: number;
  MakeId: number;
}

class GetRecomendedServiceModel {
  constructor(userService: UserService, translate: TranslateService) {
    this.RooftopId = userService.selectedDealer.RooftopId.trim();
    this.Language = translate.currentLang.substring(0, 2);
    this.MakeCode = userService.selectedVehicle.makeCode;
    this.ModelCode = userService.selectedVehicle.variantCode || userService.selectedVehicle.modelCode;
    this.CurrencyCode = '';
    this.TransmissionCode = null;
    this.FuelCode = null;
    this.EngineSize = null;
    this.DisregardWipNo = userService.wipNumberToUpdate;
  }

  RooftopId: string;
  Language: string;
  MakeCode: string;
  ModelCode: string;
  CurrencyCode: string;
  TransmissionCode: string;
  FuelCode: string;
  EngineSize: string;
  DisregardWipNo: number;
}
