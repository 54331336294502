import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less']
})

export class FooterComponent implements OnInit {
    brand: string;
    expandMenu: boolean;
    homeUrl: string;
    servicesUrl: string;
    worldUrl: string;
    buildUrl: string;
    lifestyleUrl: string;
    allModelsUrl: string;
    brochureUrl: string;
    careersUrl: string;
    contactUrl: string;
    newsletterUrl: string;
    corporateSalesUrl: string;
    motorradUrl: string;
    cookieUrl: string;
    disclaimerUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    youtubeUrl: string;
    twitterUrl: string;
    lineUrl: string;

    //horrible
    miniFacebookUrl: string;
    miniInstagramUrl: string;
    miniYoutubeUrl: string;
    miniTwitterUrl: string;

    footer_link_contact_us_models: string;
    footer_link_contact_us_brochures: string;
    footer_link_contact_us_lifestyle: string;
    footer_link_contact_us_build: string;
    footer_link_contact_us_contact: string;
    footer_link_contact_us_careers: string;

    footer_link_explore_newsletter: string;
    footer_link_explore_sales: string;
    footer_link_explore_motorrad: string;

    footer_link_legal_cookies: string;
    footer_link_legal_disclaimer: string;

    footer_link_visit_us_facebook: string;
    footer_link_visit_us_instagram: string;
    footer_link_visit_us_youtube: string;
    footer_link_visit_us_twitter: string;
    footer_link_visit_us_line: string;



    constructor(
        private readonly configService: ConfigService,
        private readonly translateService: TranslateService
    ) { }

    ngOnInit() {
        this.brand = this.configService.brand;
        this.homeUrl = this.configService.configSettings.homeUrl;
        this.servicesUrl = this.configService.configSettings.servicesUrl;
        this.worldUrl = this.configService.configSettings.worldUrl;
        this.buildUrl = this.configService.configSettings.buildUrl;
        this.lifestyleUrl = this.configService.configSettings.lifestyleUrl;
        this.allModelsUrl = this.configService.configSettings.allModelsUrl;
        this.brochureUrl = this.configService.configSettings.brochureUrl;
        this.careersUrl = this.configService.configSettings.careersUrl;
        this.contactUrl = this.configService.configSettings.contactUrl;
        this.newsletterUrl = this.configService.configSettings.newsletterUrl;
        this.corporateSalesUrl = this.configService.configSettings.corporateSalesUrl;
        this.motorradUrl = this.configService.configSettings.motorradUrl;
        this.cookieUrl = this.configService.configSettings.cookieUrl;
        this.disclaimerUrl = this.configService.configSettings.disclaimerUrl;
        this.facebookUrl = this.configService.configSettings.facebookUrl;
        this.instagramUrl = this.configService.configSettings.instagramUrl;
        this.youtubeUrl = this.configService.configSettings.youtubeUrl;
        this.twitterUrl = this.configService.configSettings.twitterUrl;
        this.lineUrl = this.configService.configSettings.lineUrl;
        this.getMiniSocial();
        this.translateService.onLangChange.subscribe(() => this.getMiniSocial());
    }

    getMiniSocial() {
        this.translateService.get([
            'link_mini_social_share_1',
            'link_mini_social_share_2',
            'link_mini_social_share_3',
            'link_mini_social_share_4',
            'footer_link_contact_us_models',
            'footer_link_contact_us_brochures',
            'footer_contact_us_lifestyle',
            'footer_link_contact_us_lifestyle',
            'footer_link_contact_us_build',
            'footer_link_contact_us_contact',
            'footer_link_contact_us_careers',
            'footer_link_explore_newsletter',
            'footer_link_explore_sales',
            'footer_link_explore_motorrad',
            'footer_link_legal_cookies',
            'footer_link_legal_disclaimer',
            'footer_link_visit_us_facebook',
            'footer_link_visit_us_instagram',
            'footer_link_visit_us_youtube',
            'footer_link_visit_us_twitter',
            'footer_link_visit_us_line'
          ]).subscribe(result => {

                this.miniFacebookUrl = result.link_mini_social_share_1 === 'link_mini_social_share_1' ? '' : result.link_mini_social_share_1;
                this.miniTwitterUrl = result.link_mini_social_share_2 === 'link_mini_social_share_2' ? '' : result.link_mini_social_share_2;
                this.miniInstagramUrl = result.link_mini_social_share_3 === 'link_mini_social_share_3' ? '' : result.link_mini_social_share_3;
                this.miniYoutubeUrl = result.link_mini_social_share_4 === 'link_mini_social_share_4' ? '' : result.link_mini_social_share_4;
                this.footer_link_contact_us_models = result.footer_link_contact_us_models === 'footer_link_contact_us_models' ? '' : result.footer_link_contact_us_models ;
                this.footer_link_contact_us_brochures = result.footer_link_contact_us_brochures === 'footer_link_contact_us_brochures' ? '' : result.footer_link_contact_us_brochures;
                this.footer_link_contact_us_lifestyle = result.footer_link_contact_us_lifestyle === 'footer_link_contact_us_lifestyle' ? '' : result.footer_link_contact_us_lifestyle;
                this.footer_link_contact_us_build = result.footer_link_contact_us_build === 'footer_link_contact_us_build' ? '' : result.footer_link_contact_us_build;
                this.footer_link_contact_us_contact = result.footer_link_contact_us_contact === 'footer_link_contact_us_contact' ? '' : result.footer_link_contact_us_contact;
                this.footer_link_contact_us_careers = result.footer_link_contact_us_careers === 'footer_link_contact_us_careers' ? '' : result.footer_link_contact_us_careers;

                this.footer_link_explore_newsletter = result.footer_link_explore_newsletter === 'footer_link_explore_newsletter' ? '' : result.footer_link_explore_newsletter;
                this.footer_link_explore_sales = result.footer_link_explore_sales === 'footer_link_explore_newsletter' ? '' : result.footer_link_explore_sales;
                this.footer_link_explore_motorrad = result.footer_link_explore_motorrad === 'footer_link_explore_motorrad' ? '' : result.footer_link_explore_motorrad;
                this.footer_link_legal_cookies = result.footer_link_legal_cookies === 'footer_link_legal_cookies' ? '' : result.footer_link_legal_cookies;
                this.footer_link_legal_disclaimer = result.footer_link_legal_disclaimer === 'footer_link_legal_disclaimer' ? '' : result.footer_link_legal_disclaimer;
                this.footer_link_visit_us_facebook = result.footer_link_visit_us_facebook === 'footer_link_visit_us_facebook' ? '' : result.footer_link_visit_us_facebook;
                this.footer_link_visit_us_instagram = result.footer_link_visit_us_instagram === 'footer_link_visit_us_instagram' ? '' : result.footer_link_visit_us_instagram;
                this.footer_link_visit_us_youtube = result.footer_link_visit_us_youtube === 'footer_link_visit_us_youtube' ? '' : result.footer_link_visit_us_youtube;
                this.footer_link_visit_us_twitter = result.footer_link_visit_us_twitter === 'footer_link_visit_us_twitter' ? '' : result.footer_link_visit_us_twitter;
                this.footer_link_visit_us_line = result.footer_link_visit_us_line === 'footer_link_visit_us_line' ? '': result.footer_link_visit_us_line;
            });


    }
}
