import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { ServiceAdvisorModel } from 'src/app/services/service-advisor/service-advisor.model';
import { ConfigService } from 'src/app/services/config/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-selected-service-advisor',
    templateUrl: './selected-service-advisor.component.html',
    styleUrls: ['./selected-service-advisor.component.less']
})
export class SelectedServiceAdvisorComponent implements OnInit {
    advisorImages: boolean;
    selectedAdvisor: ServiceAdvisorModel;
    isRtl: boolean;

    constructor(
        private readonly userService: UserService,
        private readonly configService: ConfigService,
        private readonly sanitationService: DomSanitizer,
        private readonly translateService: TranslateService
        ) {
    }

    ngOnInit() {
        this.advisorImages = this.configService.configSettings.advisorImgs;

        this.userService.ServiceAdvisor$.subscribe(result => {
            if (result) {
                this.selectedAdvisor = result;
                this.selectedAdvisor.advisorPicture = this.sanitationService.bypassSecurityTrustResourceUrl(`data:image/jpg;base64,${this.selectedAdvisor.advisorPictureData}`);
            } else {
              this.selectedAdvisor = undefined;
            }
        });

        if (this.userService.serviceAdvisor) {
            this.selectedAdvisor = this.userService.serviceAdvisor;
            this.selectedAdvisor.advisorPicture = this.sanitationService.bypassSecurityTrustResourceUrl(`data:image/jpg;base64,${this.selectedAdvisor.advisorPictureData}`);
        }

        this.isRtl = this.translateService.currentLang.includes('ar_');
        this.translateService.onLangChange.subscribe(result => {
            this.isRtl = result.lang.includes('ar_');
        });
    }
}
