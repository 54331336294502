import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-bookings',
  templateUrl: './vehicle-bookings.component.html',
  styleUrls: ['./vehicle-bookings.component.less']
})
export class VehicleBookingsComponent implements OnInit {

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

  closePopup() {
    this.router.navigate([{ outlets: { popup: null } }]);
  }

}
