import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

  outsideClick(event) {
    if (event.target.className === 'modal' || event.target.className === 'cell')
      this.closeModal();
  }

  closeModal() {
    this.closeModalEvent.emit(true);
    this.router.navigate([{ outlets: { popup: null } }]);
  }
}
