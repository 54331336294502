import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-carousel-content',
  templateUrl: './carousel-content.component.html',
  styleUrls: ['./carousel-content.component.less']
})
export class CarouselContentComponent implements OnInit {
  @Input() numberOfItemsToShow: number = 1;
  screenWidth: number;
  constructor() { }

  ngOnInit() {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

}
