import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { UserService } from 'src/app/services/user-account/user/user.service';

@Component({
    selector: 'app-update-booking',
    templateUrl: './update-booking.component.html',
    styleUrls: ['./update-booking.component.less']
})

export class UpdateBookingComponent implements OnInit {
    appointmentId: number;
    vehicleId: number;
    wipNumber: number;
    isNewZealand = false
    hasLoanVehicle = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly bookigService: BookingService,
        private readonly userService: UserService,
        private readonly configService: ConfigService
    ) {

    }

    ngOnInit() {
      this.isNewZealand = this.configService.configSettings.language.indexOf('nz') > -1;
      this.hasLoanVehicle = this.userService.selectedServiceMobilityOption === "STANC";
      // console.log("ISNEwZealand-->", this.configService.configSettings);

        this.route.paramMap.subscribe((params: Params) => {
            if (params && params.params && params.params) {
                this.appointmentId = params.params.appointmentId;
                this.vehicleId = params.params.vehicleId;
                this.wipNumber = params.params.wipNumber;
            }
        });
    }

    updateBooking() {
        // debugger
        this.userService.appointmentIdToUpdate = this.appointmentId;
        this.userService.wipNumberToUpdate = this.wipNumber;
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.userService.bookingId = this.appointmentId;
            this.router.navigate(['/select-service', this.vehicleId, true]).then(() => {
                this.closePopup();
            });
        } else {
            this.bookigService.createBooking().subscribe(result => {
                if (result > 0) {
                    // debugger
                    sessionStorage.setItem("editBookingTouchPoint", "O3");
                    this.userService.bookingId = result;
                    this.router.navigate(['/select-service', this.vehicleId, true]).then(() => {
                        this.closePopup();
                    });
                }
            });
        }
    }

    closePopup() {
        this.router.navigate([{ outlets: { popup: null } }]);
    }
}
