import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, RegisterAccountContract, SecureTokenResponse } from 'src/app/services/user-account/user/user.service';
import { TitleService, Tranlsation } from 'src/app/services/title/title.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader/loader.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {
  model: RegisterModel = {
    confirmPassword: '',
    email: '',
    firstName: '',
    mobile: '',
    password: '',
    surname: '',
    title: '',
    addressOne: '',
    addressTwo: '',
    addressThree: ''
  };
  titleList: Tranlsation[];
  registerComplete: boolean;
  confirmPasswordValid: boolean;
  passwordValidationRegex: RegExp;
  passwordValid: boolean;
  registrationError: boolean;
  titleText: string;
  mobilePattern: string;
  privacyPolicy: boolean;
  showPrivacyPolicy: boolean;
  privacyPolicyAccepted: boolean;
  privacyPolicyUrl: string;
  layout: string = 'default';
  isSupportingAddressOnReg = true;
  isRtl: boolean;
  maxNumChars = this.configService.configSettings.formValidation.maxMobileLength //formValidation.maxMobileLength;
  minNumChars = this.configService.configSettings.formValidation.minMobileLength;
  validationRequiredKeyProp = "validation-required"

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly titleService: TitleService,
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService,
    private readonly loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService.showManual();
    this.mobilePattern = this.configService.configSettings.formValidation.mobileTelNo;
    this.privacyPolicy = this.configService.configSettings.privacyPolicy;

    this.translateValues();
    this.getTitleList();
    this.translateService.onLangChange.subscribe((result) => {
      this.translateValues();
      this.getTitleList();
      this.isRtl = result.lang.includes('ar_');
    });

    this.translateService.get('privacy_policy_url').subscribe(result => {
      this.privacyPolicyUrl = result;
    });

    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('privacy_policy_url').subscribe(result => {
        this.privacyPolicyUrl = result;
      });
    });


      this.isSupportingAddressOnReg = this.configService.configSettings.language.indexOf('sg') > -1;

    this.passwordValidationRegex = new RegExp(this.configService.configSettings.formValidation.password);

    if (this.privacyPolicy && this.configService.configSettings.privacyPolicyLayout)
      this.layout = this.configService.configSettings.privacyPolicyLayout;
  }

  goToPrivacyPolicy() {
    this.showPrivacyPolicy = true;
  }

  acceptPolicyAccepted() {
    this.privacyPolicyAccepted = !this.privacyPolicyAccepted;
  }

  getTitleList() {
    setTimeout(() => {
      this.titleList = this.titleService.getShortTitles();
      if (!this.configService.configSettings.hideMoreTitles) {
        this.translateService.get('more').subscribe(result => {
          this.titleList.push({ key: 'more', value: `${result}...` });
          this.loaderService.hideManual();
        });
      } else {
        this.loaderService.hideManual();
      }
    }, 2000);
  }

  translateValues() {
    this.translateService.get('title').subscribe(result => this.titleText = result);
  }

  selectTitle(titleKey: string) {
    if (titleKey === 'more') {
      this.titleList = this.titleService.getExtendedTitles();
      this.translateService.get('less').subscribe(result => {
        this.titleList.push({ key: 'less', value: `${result}...` });
      });
    } else if (titleKey === 'less') {
      this.titleList = this.titleService.getShortTitles();
      this.translateService.get('more').subscribe(result => {
        this.titleList.push({ key: 'more', value: `${result}...` });
      });
    } else {
      this.model.title = this.titleList.filter(x => x.key === titleKey)[0].value;
    }
  }

  navigateToLogin() {
    this.router.navigateByUrl('/user-account/login');
  }

  clearHTMLTags = (strToSanitize) => {
    return strToSanitize.replace(/(<([^>]+)>)/gi, '');
  }

  register() {
    // debugger
    if (!this.passwordValid || !this.confirmPasswordValid) {
      return;
    }

    // console.log("LANG-->", this.translateService.currentLang);
    // console.log("LANG-->", this.translateService);


    const data: RegisterAccountContract = {
      CustomerId: this.model.email,
      CustomerIdType: 0,
      EmailAddress: this.model.email,
      FirstName: this.clearHTMLTags(this.model.firstName) ,
      SurName: this.clearHTMLTags(this.model.surname),
      Title: this.model.title,
      Password: this.model.password,
      MobileTelNo: this.model.mobile,
      Address1: this.model.addressOne,
      Address2: this.model.addressTwo,
      Address3: this.model.addressThree,
      PostalCode: this.model.postcode,
      Language: this.translateService.currentLang === "en_sg" ? "en-sg" : this.translateService.currentLang,
      RooftopId: this.configService.configSettings.dealerInclusion[0].dealerCode,
      RedirectUrl: this.configService.configSettings.dealerInclusion[0].url
    };


    if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
      data.RooftopId = this.userService.selectedDealer.RooftopId;
      this.userService.registerAccountTitan(
        this.model.password,
        data
      ).pipe(take(1)).subscribe(() => {
        this.registerComplete = true;
        this.registrationError = false;
      }, () => {
        this.registrationError = true;
        this.registerComplete = false;
      });
    } else {
      const tokenSubscription = this.userService.requestToken().subscribe((token: any) => {
        tokenSubscription.unsubscribe();
        if (token.errorCode === 0) {
          this.userService.token = token.token;
          const subscription = this.userService.registerAccount(data).subscribe(result => {
            subscription.unsubscribe();
            // debugger
            if (result.ErrorCode === 0) {
              this.registerComplete = true;
              this.registrationError = false;
            }
            if (result.ErrorCode === 11) {
              this.registrationError = true;
              this.registerComplete = false;
            }
          });

        }
      });
    }
  }

  registerNotSecure() {
    // debugger
    if (!this.passwordValid || !this.confirmPasswordValid) {
      return;
    }

    const data: RegisterAccountContract = {
      CustomerId: this.model.email,
      CustomerIdType: 0,
      EmailAddress: this.model.email,
      FirstName: this.model.firstName,
      SurName: this.model.surname,
      Title: this.model.title,
      Password: this.model.password,
      MobileTelNo: this.model.mobile,
      Language: this.translateService.currentLang,
      RooftopId: this.configService.configSettings.dealerInclusion[0].dealerCode,
      RedirectUrl: this.configService.configSettings.dealerInclusion[0].url,
    };


    if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
      data.RooftopId = this.userService.selectedDealer.RooftopId;
      this.userService.registerAccountTitan(
        this.model.password,
        data
      ).pipe(take(1)).subscribe(() => {
        this.registerComplete = true;
        this.registrationError = false;
      }, () => {
        this.registrationError = true;
        this.registerComplete = false;
      });
    } else {
      const tokenSubscription = this.userService.requestToken().subscribe(token => {
        // debugger
        tokenSubscription.unsubscribe();
        if (token.Result.ErrorCode === 0) {
          this.userService.token = token.Token;
          const activateSubscription = this.userService.activateToken(token.Token).subscribe(activate => {
            activateSubscription.unsubscribe();
            if (activate.ErrorCode === 0) {
              // TODO: REFACTOR
              if (data.Language === "en_ae") {
                data.Language = "en-ZA"
              }
              const subscription = this.userService.registerAccount(data).subscribe(result => {
                subscription.unsubscribe();
                if (result.ErrorCode === 0) {
                  this.registerComplete = true;
                  this.registrationError = false;
                }
                if (result.ErrorCode === 11) {
                  this.registrationError = true;
                  this.registerComplete = false;
                }
              });
            }
          });
        }
      });
    }
  }

  validatePassword() {
    this.passwordValid = this.passwordValidationRegex.test(this.model.password);

  }

  validateConfirmPassword() {
    this.confirmPasswordValid = this.model.password.length > 0 && this.model.password === this.model.confirmPassword;
  }
}

class RegisterModel {
  firstName: string;
  surname: string;
  email: string;
  mobile: string;
  password: string;
  confirmPassword: string;
  title: string;
  addressOne?: string;
  addressTwo?: string;
  addressThree?: string;
  postcode?: string;
}
