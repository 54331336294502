import { Injectable } from '@angular/core';
import { ConfigSettings } from './config.model';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  configSettings: ConfigSettings;
  brand: string;
}
