import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user-account/user/user.service';
import { ConfigService } from '../config/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceAdvisorModel } from './service-advisor.model';
import { DateFormatter } from 'src/app/helpers/date-format.helper';
import { ServiceAdvisorPictureModel } from './service-advisor-picture.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ServiceAdvisorService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly userService: UserService,
        private readonly configService: ConfigService
    ) { }

    getServiceAdvisors(disregardWipNumber?: number, dropOffTime?: string, ignoreAdvirorSwitch?: boolean): Observable<ServiceAdvisorModel[]> {
        const advisorList: ServiceAdvisorModel[] = [];
        return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetServiceAdvisors`
            , new GetServiceAdvisorsContract(this.userService, this.configService.brand, disregardWipNumber, this.configService, dropOffTime, ignoreAdvirorSwitch)
        ).pipe(map(result => {
            if (result.Result.ErrorCode === 0) {
                result.Results.AdvisorData.forEach(serviceItem => {
                    if (serviceItem.AdvisorID !== 'RECALL')
                        advisorList.push(new ServiceAdvisorModel(serviceItem));
                });
            }
            return advisorList;
        }));
    }

    getServiceAdvisorsTitan(): Observable<ServiceAdvisorModel[]> {
        const serviceAdvisorList: ServiceAdvisorModel[] = [];
        return this.httpClient.get<ServiceAdvisorModelTitan[]>(`${environment.titanUrl}Booking/ServiceAdvisors?locationCode=${this.userService.selectedLocation.LocationCode}`).pipe(map(result => {
            result.forEach(x => {
                serviceAdvisorList.push({
                    advisorID: x.Id.toString(),
                    advisorName: `${x.FirstName} ${x.LastName}`,
                    selected: false
                });
            });
            return serviceAdvisorList;
        }));
    }

    getFirstAvailableServiceAdvisor() {
        return this.httpClient.get(`${environment.titanUrl}Booking/ServiceAdvisors/FirstAvailable?locationCode=11601&bookingDateTime=2022-11-21T11:30:00`)
      }

    getAdvisorPictures(advisorIds: string[]): Observable<ServiceAdvisorPictureModel[]> {
        const advisorPictures: ServiceAdvisorPictureModel[] = [];
        return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetAdvisorPictures`
            , new GetServiceAdvisorPicturesContract(this.userService, advisorIds)
        ).pipe(map(result => {
            if (result.Result.ErrorCode === 0) {
                result.Advisors.forEach(x => {
                    advisorPictures.push(new ServiceAdvisorPictureModel(x));
                });

                return advisorPictures;
            }
            return undefined;
        }));
    }
}

class ServiceAdvisorModelTitan {
    Id: number;
    LocationId: number;
    StatusCode: number;
    Name: string;
    FirstName: string;
    LastName: string;
    MiddleName: string;
    DmsUserId: string;
    ImageUrls: string[];
}

class GetServiceAdvisorPicturesContract {
    constructor(userService: UserService, advisorIds: string[]) {
        this.RooftopId = userService.selectedDealer.RooftopId;
        this.AdvisorIdList = advisorIds;
    }
    RooftopId: string;
    AdvisorIdList: string[];
}

class GetServiceAdvisorsContract {
    constructor(userService: UserService, brand: string, disregardWipNumber: number, configService: ConfigService, dropOffTime?: string, ignoreAdvisorSwitch?: boolean) {
        this.RooftopId = userService.selectedDealer.RooftopId;
        if (configService.configSettings.switchAdvisorWorkflow && !ignoreAdvisorSwitch) {
            const serviceDate = userService.serviceDates.workingDates[userService.serviceDates.workingDates.length - 1];
            this.AppointmentDate = DateFormatter.formatDate(new Date(serviceDate));
        } else {
            this.AppointmentDate = DateFormatter.formatDate(new Date(userService.selectedServiceDate));
        }
        if (!dropOffTime)
            this.DropOffTime = configService.configSettings.switchAdvisorWorkflow ? '12:00-12:15' : userService.selectedServiceSlot;
        else
            this.DropOffTime = dropOffTime;
        this.TransportMethod = userService.selectedServiceMobilityOption;
        this.DisregardWipNo = userService.wipNumberToUpdate ? userService.wipNumberToUpdate : disregardWipNumber;
    }
    RooftopId: string;
    AppointmentDate: string;
    TransportMethod: string;
    DropOffTime: string;
    DisregardWipNo: number;
}
