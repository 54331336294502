import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/user-account/login/login.component';
import { RegisterComponent } from './components/user-account/register/register.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { DealerComponent } from './components/dealer/dealer.component';
import { SelectServiceComponent } from './components/select-service/select-service.component';
import { AddVehicleComponent } from './components/select-service/add-vehicle/add-vehicle.component';
import { DeleteVehicleComponent } from './components/select-service/delete-vehicle/delete-vehicle.component';
import { BookingConfirmedComponent } from './components/booking-confirmed/booking-confirmed.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { CancelBookingComponent } from './components/cancel-booking/cancel-booking.component';
import { UpdateBookingComponent } from './components/update-booking/update-booking.component';
import { ProfileComponent } from './components/user-account/profile/profile.component';
import { ProfileViewComponent } from './components/user-account/profile/profile-view/profile-view.component';
import { ProfileEditComponent } from './components/user-account/profile/profile-edit/profile-edit.component';
import { ResetPasswordComponent } from './components/user-account/reset-password/reset-password.component';
import { LevelOneBookingComponent } from './components/level-one/level-one-booking.component';
import { VehicleBookingsComponent } from './components/select-service/vehicle-bookings/vehicle-bookings.component';

const routes: Routes = [
  {
    path: '', component: UserAccountComponent, children: [
      { path: '', component: LoginComponent, },
      { path: 'login', component: RegisterComponent, }
    ]
  },
  { path: 'en/ssl/:deepLinkValues', component: LoginComponent },
  { path: 'scheduling', component: LoginComponent },
  { path: 'Account/Unlock', component: LoginComponent },
  { path: 'resetpassword', component: ResetPasswordComponent }, // only so we dont have to struggle with cdk
  {
    path: 'user-account', component: UserAccountComponent, children: [
      { path: 'login', component: LoginComponent, },
      { path: 'login:token', component: LoginComponent, },
      { path: 'register', component: RegisterComponent, },
      { path: 'reset-password', component: ResetPasswordComponent }
    ]
  },
  { path: 'level-one-booking', component: LevelOneBookingComponent },
  { path: 'select-dealer', component: DealerComponent },
  { path: 'select-dealer/:userId', component: DealerComponent },
  { path: 'select-service', component: SelectServiceComponent },
  { path: 'select-service/:vehicleId', component: SelectServiceComponent },
  { path: 'select-service/:vehicleId/:closeModal', component: SelectServiceComponent },
  { path: 'add-vehicle', component: AddVehicleComponent, outlet: 'popup' },
  { path: 'delete-vehicle/:registrationNumber', component: DeleteVehicleComponent, outlet: 'popup' },
  { path: 'booking-confirmation', component: BookingConfirmedComponent },
  { path: 'my-bookings', component: BookingsComponent },
  { path: 'cancel-booking/:wipNumber/:appointmentId/:rooftopId', component: CancelBookingComponent, outlet: 'popup' },
  { path: 'update-booking/:appointmentId/:vehicleId/:wipNumber/:rooftopId', component: UpdateBookingComponent, outlet: 'popup' },

  {
    path: 'profile', component: ProfileComponent, children: [
      { path: 'view', component: ProfileViewComponent },
      { path: 'edit', component: ProfileEditComponent }
    ]
  },
  { path: 'vehicle-bookings', component: VehicleBookingsComponent, outlet: 'popup' }
  // {
  //   matcher(url) {
  //     if (url.length >= 1 && url[1].path === "ssl") {
  //       return {
  //         consumed: url,
  //         posParams: {
  //           username: new UrlSegment('', {})
  //         }
  //       };
  //     }
  //     return null;
  //   },
  //   component: LoginComponent
  // }
];

@NgModule({
  imports: [BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
