export class ServiceItemModel {
    constructor(value) {
        this.jobCode = value.JobCode;
        this.productCode = value.ProductCode;
        this.jobDescription = value.JobDescription;
        this.jobTime = value.JobTime;
        this.jobPrice = value.JobPrice;
        this.jobTypeCode = value.JobTypeCode;
        this.jobExtDescription = value.JobExtDescription;
        this.parentJobCode = value.ParentJobCode;
    }
    id?:number;
    jobComments?: string;
    jobCode: number | string;
    productCode: string;
    jobDescription: string;
    jobTime: string;
    jobPrice: number;
    jobTypeCode: string;
    jobExtDescription: string;
    parentJobCode: string;
    selected: boolean;
}
