import { EventEmitter, Component, OnInit, Input, Output, OnChanges, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.less']
})

export class CarouselComponent implements OnInit, OnChanges {
  @Input() numberOfItems: number;
  @Input() currentIndex: number;
  @Output() currentItemNumber: EventEmitter<number> = new EventEmitter();
  @Input() numberOfItemsToShow: number = 1;
  pageNumber: number;
  carouselMargin: number;
  marginToAdjust: number;
  screenWidth: number;
  isRtl: boolean;

  constructor(
    private readonly translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getScreenSize();
    this.carouselMargin = 0;
    this.pageNumber = 0;
    this.currentIndex = this.currentIndex ? this.currentIndex : 0;
    this.marginToAdjust = this.numberOfItemsToShow > 1 ? this.screenWidth : 460;
    if (this.currentIndex > 0)
      this.setIndex(this.currentIndex);

    this.isRtl = this.translateService.currentLang.includes('ar_');
    this.translateService.onLangChange.subscribe(result => {
      this.isRtl = result.lang.includes('ar_');
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth < 575 ? window.innerWidth : 460;
  }

  ngOnChanges() {
    this.setIndex(this.currentIndex);
  }

  setIndex(index) {
    if (this.marginToAdjust) {
      this.carouselMargin = (index * this.marginToAdjust) * -1;
    }
  }

  moveLeft() {
    if (this.pageNumber > 0) {
      this.carouselMargin = this.carouselMargin + this.marginToAdjust;
      this.pageNumber = this.pageNumber - 1;
      this.outputCurrentItem();
    }
  }

  moveRight() {
    if (this.pageNumber < (this.numberOfItems / this.numberOfItemsToShow) - 1) {
      this.carouselMargin = this.carouselMargin - this.marginToAdjust;
      this.pageNumber = this.pageNumber + 1;
      this.outputCurrentItem();
    }
  }

  outputCurrentItem() {
    this.currentItemNumber.emit(this.pageNumber);
  }
}
