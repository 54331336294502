import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServiceContractService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService
  ) {}

  getServiceContractItems(vin: string) {
    const reqBody = {
      chasisNo: vin,
      country: this.configService.configSettings.region.toUpperCase(),
      language: this.translateService.currentLang.split('_')[0].toUpperCase(),
      contractStatus: 'active',
      token: sessionStorage.getItem('machineToken'),
    };

    return this.httpClient.post<any>(
      `${environment.solAPIUrl}/api/v1/getVehicleServiceContracts`,
      reqBody
    );
  }
}
