import { Component, OnInit } from '@angular/core';
import { VehicleModel } from 'src/app/services/vehicle/vehicle.model';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-booking-confirmed',
  templateUrl: './booking-confirmed.component.html',
  styleUrls: ['./booking-confirmed.component.less'],
})
export class BookingConfirmedComponent implements OnInit {
  selectedVehicle: VehicleModel;
  wipNumber: number;
  imageUrl: string;
  recallService: boolean;
  isSingapore = false;
  selectedVehicleFromStorage: VehicleModel;
  selectedVehicleImageUrlFromStorage: string;

  constructor(
    private readonly userService: UserService,
    private readonly vehicleService: VehicleService,
    private readonly configService: ConfigService
  ) {}

  ngOnInit() {
    this.isSingapore = this.configService.configSettings.language === 'en_sg';
    if (this.configService.configSettings.isCRM) {

      this.selectedVehicleFromStorage = JSON.parse(
        sessionStorage.getItem('selectedVehicle')
      );

      if (this.configService.configSettings.isCRM) {
        this.vehicleService.zaSelectedVehicleImage.subscribe((vehicleImage) => {
          this.imageUrl = vehicleImage;
        });
      }

    }

    this.selectedVehicle = this.userService.selectedVehicle;
    this.recallService =
      this.userService.selectedServices.find((x) =>
        x.productCode.includes('SOLRECA')
      ) !== undefined;
    this.wipNumber = this.userService.wipNumber;
    if (!this.configService.configSettings.isCRM) {
      this.vehicleService
        .getVehicleCosyImageUrl(this.selectedVehicle, 500)
        .subscribe((result) => {
          this.imageUrl = `${result.url}`;
        });
    }
  }
}
