export class VehicleModel {
    constructor(value) {
        if (value) {
         
            if (value.AccidentDamage)
                this.accidentDamage = new AccidentDamage(value.AccidentDamage);
            this.chassisNo = value.ChassisNo ? value.ChassisNo : value.VIN;
            this.colour = value.Colour;
            this.engineSize = value.EngineSize;
            this.freeDesc = value.FreeDesc ? value.FreeDesc : "No Description Provided";
            this.fuelCode = value.FuelCode;
            this.fuelCode = value.FuelDesc;
            this.isCompanyVehicle = value.IsCompanyVehicle;
            this.lastRegisteredDate = value.LastRegisteredDate ? value.LastRegisteredDate : value.RegDate;
            this.motExpiryDate = value.MOTExpiryDate;
            this.makeCode = value.MakeCode;
            this.makeName = value.MakeName;
            this.modelCode = value.ModelCode;
            this.modelName = value.ModelName;
            this.modelTextDescription = value.ModelTextDescription ? value.ModelTextDescription : value.FreeDesc;
            this.odometer = value.Odometer;
            this.registrationNumber = value.RegistrationNumber ? value.RegistrationNumber : value.RegNumber;
            this.serviceModelGroup = value.ServiceModelGroup;
            this.transmissionCode = value.TransmissionCode;
            this.transmissionDesc = value.TransmissionDesc;
            this.trim = value.Trim;
            this.variantCode = value.VariantCode;
            this.variantName = value.VariantName;
            this.vehicleId = value.VehicleId;
            this.modelYear = value.ModelYear;
            if (value.CosyImageURL) {
                this.CosyImageURL = value.CosyImageURL;
            }
        }
    }
    accidentDamage?: AccidentDamage;
    chassisNo: string;
    colour?: string;
    engineSize?: string;
    freeDesc: string;
    fuelCode?: string;
    fuelDesc?: string;
    isCompanyVehicle?: boolean;
    lastRegisteredDate?: string;
    motExpiryDate?: string;
    makeCode: string;
    makeName: string;
    modelCode: string;
    modelName: string;
    modelYear?: string;
    modelTextDescription: string;
    odometer?: number;
    registrationNumber: string;
    serviceModelGroup?: string;
    transmissionCode?: string;
    transmissionDesc?: string;
    trim?: string;
    variantCode: string;
    variantName: string;
    vehicleId?: number;
    vehicleImageUrl?: string;
    CosyImageURL? : string;
    makeId: number;
}

class AccidentDamage {
    constructor(value) {
        this.code = value.Code;
        this.description = value.Description;
    }
    code: string;
    description: string;
}
