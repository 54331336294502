import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { BookingService } from '../../booking/booking.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  subscription: any;
  remainingMinutes: number = 1 * 30;

  constructor(
    private loginService: LoginService,
    private readonly router: Router,
    private readonly bookingService: BookingService
  ) {
  }

  setInactivityTimer(remainingMinutes: number) {
    this.remainingMinutes = remainingMinutes;
    this.subscription = timer(0, 60000).subscribe(value => {
      if (value >= this.remainingMinutes) {
        this.bookingService.clearBookingData();
        this.loginService.logOut().subscribe(response => {
          if (response) {
            sessionStorage.setItem('LOGOUT', 'INACTIVITY');
            this.resetTimer();
            this.router.navigateByUrl('/user-account/login');
          }
        },
          () => {
            sessionStorage.setItem('LOGOUT', 'INACTIVITY');
            this.resetTimer();
            this.router.navigateByUrl('/user-account/login');
          });
      }
    });
  }

  resetTimer() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
