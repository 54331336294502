import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppLoadService } from './services/app-load/app-load.service';
import { LoginService } from './services/user-account/login/login.service';
import { LoaderService } from './services/loader/loader.service';
import { TitleService } from './services/title/title.service';
import { LoaderInterceptor } from './interceptors/loader/loader.interceptor';
import { AuthenticationInterceptor } from './interceptors/authentication/authentication.interceptor';
import { NgbModule, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { ProgressIndicatorComponent } from './components/shared/progress-indicator/progress-indicator.component';
import { ProgressItemComponent } from './components/shared/progress-indicator/progress-item/progress-item.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { LoginComponent } from './components/user-account/login/login.component';
import { RegisterComponent } from './components/user-account/register/register.component';
import { DealerComponent } from './components/dealer/dealer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectServiceComponent } from './components/select-service/select-service.component';
import { AccordianComponent } from './components/shared/accordian/accordian.component';
import { SelectVehicleComponent } from './components/select-service/select-vehicle/select-vehicle.component';
import { CarouselComponent } from './components/shared/carousel/carousel.component';
import { CarouselContentComponent } from './components/shared/carousel/carousel-content/carousel-content.component';
import { SummaryComponent } from './components/summary/summary.component';
import { SelectedVehicleComponent } from './components/summary/selected-vehicle/selected-vehicle.component';
import { SelectServiceItemComponent } from './components/select-service/select-service-item/select-service-item.component';
import { AddVehicleComponent } from './components/select-service/add-vehicle/add-vehicle.component';
import { ModalComponent } from './components/shared/modal/modal.component';
import { DeleteVehicleComponent } from './components/select-service/delete-vehicle/delete-vehicle.component';
import { SelectComponent } from './components/shared/select/select.component';
import { SelectedServicesComponent } from './components/summary/selected-services/selected-services.component';
import { OptionsComponent } from './components/select-service/options/options.component';
import { ServiceAdvisorComponent } from './components/select-service/service-advisor/service-advisor.component';
import { SelectedOptionsComponent } from './components/summary/selected-options/selected-options.component';
import { BookingConfirmedComponent } from './components/booking-confirmed/booking-confirmed.component';
import { SelectedServiceAdvisorComponent } from './components/summary/selected-service-advisor/selected-service-advisor.component';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { CancelBookingComponent } from './components/cancel-booking/cancel-booking.component';
import { UpdateBookingComponent } from './components/update-booking/update-booking.component';
import { ProfileComponent } from './components/user-account/profile/profile.component';
import { ProfileViewComponent } from './components/user-account/profile/profile-view/profile-view.component';
import { ProfileEditComponent } from './components/user-account/profile/profile-edit/profile-edit.component';
import { FooterComponent } from './components/footer/footer.component';
import { ResetPasswordComponent } from './components/user-account/reset-password/reset-password.component';
import { AppLoadModule } from './app-load.module';
import { BMWFontComponent } from './components/branding/font/bmw-font.component';
import { MINIFontComponent } from './components/branding/font/mini-font.component';
import { BMWControlsComponent } from './components/branding/controls/bmw-controls.component';
import { MINIControlsComponent } from './components/branding/controls/mini-controls.component';
import { BMWColorsComponent } from './components/branding/colors/bmw-colors.component';
import { MINIColorsComponent } from './components/branding/colors/mini-colors.component';
import { MomentModule } from 'ngx-moment';
import { CustomDatepickerI18n } from './services/customDatepickerI18n.service';
import { ErrorInterceptor } from './interceptors/error/error.interceptor';
import { LevelOneBookingComponent } from './components/level-one/level-one-booking.component';
import { MINIFontArComponent } from './components/branding/font/mini-font.component-ar';
import { InactivityService } from './services/user-account/inactivity/inactivity.service';
import { SelectDealerComponent } from './components/select-service/select-dealer/select-dealer.component';
import { VehicleBookingsComponent } from './components/select-service/vehicle-bookings/vehicle-bookings.component';
import { ServiceContractComponent } from './components/shared/service-contract/service-contract.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// const cookieConfig:NgcCookieConsentConfig = {
//   cookie: {
//     domain: 'localhost' // or 'your.domain.com' window.location.hostname
//   },
//   palette: {
//     popup: {
//       background: '#fff'
//     },
//     button: {
//       background: '#f1d600'
//     }
//   },
//   theme: 'edgeless',
//   type: 'opt-out'
// };

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    ProgressIndicatorComponent,
    ProgressItemComponent,
    UserAccountComponent,
    LoginComponent,
    RegisterComponent,
    DealerComponent,
    SelectServiceComponent,
    AccordianComponent,
    SelectVehicleComponent,
    CarouselComponent,
    CarouselContentComponent,
    SummaryComponent,
    SelectedVehicleComponent,
    SelectServiceItemComponent,
    AddVehicleComponent,
    ModalComponent,
    DeleteVehicleComponent,
    SelectComponent,
    SelectedServicesComponent,
    OptionsComponent,
    ServiceAdvisorComponent,
    SelectedOptionsComponent,
    BookingConfirmedComponent,
    SelectedServiceAdvisorComponent,
    SubMenuComponent,
    BookingsComponent,
    CancelBookingComponent,
    UpdateBookingComponent,
    ProfileComponent,
    ProfileEditComponent,
    ProfileViewComponent,
    FooterComponent,
    ResetPasswordComponent,
    BMWFontComponent,
    MINIFontArComponent,
    MINIFontComponent,
    BMWColorsComponent,
    MINIColorsComponent,
    BMWControlsComponent,
    MINIControlsComponent,
    LevelOneBookingComponent,
    SelectDealerComponent,
    VehicleBookingsComponent,
    ServiceContractComponent
  ],
  imports: [
    AppLoadModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDvEKljSS0xHaotQ7JyTS63lWsuCDsK52w'
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [
          HttpClient
        ]
      }
    }),
    NgbModule,
    MomentModule,
    // NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    AppLoadService,
    LoginService,
    LoaderService,
    TitleService,
    CustomDatepickerI18n,
    InactivityService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// Old MAPI key  AIzaSyCI_wY77C1GunWr1d6Y-CPL60Kb_yTP0OM
