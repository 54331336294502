import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config/config.service';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { BookingService } from '../../services/booking/booking.service';

@Component({
  selector: 'app-select-service',
  templateUrl: './select-service.component.html',
  styleUrls: ['./select-service.component.less'],
})
export class SelectServiceComponent implements OnInit {
  switchAdvisorWorkflow: Boolean;
  hasServieContracts = false;
  steps = [
    {
      label: 'steps-vehicle-selection',
      name: '',
      isOpen: true,
    },
    // {
    //   label: 'steps-dealer-selection',
    //   name: '',
    //   isOpen: false
    // },
    {
      label: 'steps-service-type',
      name: '',
      isOpen: false,
    },
    {
      label: 'steps-date',
      name: '',
      isOpen: false,
    },
    {
      label: 'steps-service-advisor',
      name: '',
      isOpen: false,
    },
  ];

  isLevelOneBooking: boolean;
  canSwitchDealer: boolean;
  vehicleIndex: number;

  constructor(
    private readonly translationService: TranslateService,
    private readonly configService: ConfigService,
    private readonly bookingService: BookingService,
    private readonly userService: UserService,
    private readonly vehicleService: VehicleService
  ) {}

  ngOnInit() {
    this.switchAdvisorWorkflow =
      this.configService.configSettings.switchAdvisorWorkflow;
    this.hasServieContracts =
      this.configService.configSettings.hasServiceContracts;
    if (this.switchAdvisorWorkflow) {
      this.switchFlow();
    }
    // TODO: REFACTOR
    // this.router.navigateByUrl('/select-service');

    this.translationService.onLangChange.subscribe(() => {
      this.updateTranslations();
    });
    this.updateTranslations();

    this.bookingService.resetVehicle.subscribe((result) => {
      if (result) {
        for (let i = 0; i < this.steps.length; i++) {
          this.steps[i].isOpen = false;
        }
        this.steps[0].isOpen = true;
      }
    });
  }

  currentVehicleIndex(index) {
    this.currentVehicleIndex = index;
  }

  updateTranslations() {
    this.steps.forEach((step) =>
      this.translationService
        .get(step.label)
        .pipe(take(1))
        .subscribe((result) => (step.name = result))
    );
  }

  changeStepState(event) {
    this.steps
      .filter((step) => step.label !== event.label)
      .forEach((step) => (step.isOpen = false));
  }

  goToPreviousState(currentState: string) {
    const currentIndex = this.steps.indexOf(
      this.steps.filter((x) => x.label === currentState)[0]
    );
    this.steps[currentIndex].isOpen = false;
    this.steps[currentIndex - 1].isOpen = true;
  }

  goToNextState(currentState: string) {

    const currentIndex = this.steps.indexOf(
      this.steps.filter((x) => x.label === currentState)[0]
    );
    this.steps[currentIndex].isOpen = false;
    this.steps[currentIndex + 1].isOpen = true;
  }

  switchFlow() {
    this.steps = [
      {
        label: 'steps-vehicle-selection',
        name: '',
        isOpen: true,
      },
      // {
      //   label: 'steps-dealer-selection',
      //   name: '',
      //   isOpen: false
      // },
      {
        label: 'steps-service-type',
        name: '',
        isOpen: false,
      },
      {
        label: 'steps-service-advisor',
        name: '',
        isOpen: false,
      },
      {
        label: 'steps-date',
        name: '',
        isOpen: false,
      },
    ];
  }
}
