import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-bmw-controls',
    template: '<div></div>',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./../../../styles/controls-bmw.less']
})
export class BMWControlsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}