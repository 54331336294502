import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-bmw-colors',
    template: '<div></div>',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./bmw-colors.less']
})
export class BMWColorsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}