export class MakeModelVariant {

    constructor(value?: any) {
        if (value) {
            this.canOverwriteDescription = value.CanOverwriteDescription;
            this.makeCode = value.MakeCode;
            this.makeName = value.MakeName;
            this.modelCode = value.ModelCode;
            this.modelName = value.ModelName;
            this.modelYear = value.ModelYear;
            this.serviceModelGroup = value.ServiceModelGroup;
            this.subModelCode = value.SubModelCode;
            this.variantCode = value.VariantCode ? value.VariantCode : value.ModelCode;
            this.variantName = value.VariantName ? value.VariantName : value.ModelName;
        }
    }

    canOverwriteDescription: boolean;
    makeCode: string;
    makeName: string;
    modelCode: string;
    modelName: string;
    modelYear: string;
    serviceModelGroup: string;
    subModelCode: string;
    variantCode: string;
    variantName: string;
}
