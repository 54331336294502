import { Component, OnInit, Input } from '@angular/core';
import { ProgressModel } from '../progress-item.model';

@Component({
  selector: 'app-progress-item',
  templateUrl: './progress-item.component.html',
  styleUrls: ['./progress-item.component.less']
})
export class ProgressItemComponent implements OnInit {
  @Input() progressItem: ProgressModel;

  constructor() { }

  ngOnInit() {
  }

}
