import { Component, OnInit } from '@angular/core';
import { UserService, UpdateCustomerInformationContract } from 'src/app/services/user-account/user/user.service';
import { Tranlsation, TitleService } from 'src/app/services/title/title.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.less']
})

export class ProfileEditComponent implements OnInit {
    titleText: string;
    customerId: string;
    titleList: Tranlsation[];
    model: ProfileEditModel;
    mobilePattern: string;
    isTitanDealer: boolean;
    isRtl: boolean;
    validationRequiredKeyProp = "validation-required"

    constructor(
        private readonly titleService: TitleService,
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly configService: ConfigService,
        private readonly loaderService: LoaderService
    ) {

    }

    getTitelList() {
        this.loaderService.show();
        setTimeout(() => {
            this.titleList = this.titleService.getShortTitles();
            if (!this.configService.configSettings.hideMoreTitles) {
                this.translateService.get('more').subscribe(result => {
                    this.titleList.push({ key: 'more', value: `${result}...` });
                    this.loaderService.hide();
                });
            } else {
                this.loaderService.hide();
            }
        }, 2000);
    }

    ngOnInit() {
        this.mobilePattern = this.configService.configSettings.formValidation.mobileTelNo;
        this.translateFields();
        this.getTitelList();
        this.translateService.onLangChange.subscribe((result) => {
            this.translateFields();
            this.getTitelList();
            this.isRtl = result.lang.includes('ar_');
        });
        this.customerId = this.userService.customerId;
        this.model = {
            email: this.userService.userInfo.EmailAddress,
            firstName: this.userService.userInfo.FirstName,
            mobile: this.userService.userInfo.MobileTelNo,
            surname: this.userService.userInfo.SurName,
            title: this.userService.userInfo.Title,
            homeNumber: this.userService.userInfo.HomeTelNo,
            workNumber: this.userService.userInfo.WorkTelNo,
            address1: this.userService.userInfo.Address1,
            address2: this.userService.userInfo.Address1,
            address3: this.userService.userInfo.Address2,
            postcode: this.userService.userInfo.PostalCode
        };
        this.isTitanDealer = this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer;
    }


    translateFields() {
        this.translateService.get('title').subscribe(result => this.titleText = result);
    }

    selectTitle(titleKey: string) {
        if (titleKey === 'more') {
            this.titleList = this.titleService.getExtendedTitles();
            this.translateService.get('less').subscribe(result => {
                this.titleList.push({ key: 'less', value: `${result}...` });
            });
        } else if (titleKey === 'less') {
            this.titleList = this.titleService.getShortTitles();
            this.translateService.get('more').subscribe(result => {
                this.titleList.push({ key: 'more', value: `${result}...` });
            });
        } else {
            this.model.title = this.titleList.filter(x => x.key === titleKey)[0].value;
        }
    }

    cancel() {
        this.router.navigateByUrl('/profile/view');
    }

    updateProfile() {
        const customerInfo: UpdateCustomerInformationContract = {
            CustomerId: this.customerId,
            EmailAddress: this.model.email,
            FirstName: this.model.firstName,
            HomeTelNo: this.model.homeNumber,
            Language: this.userService.languageSelected.substring(0, 2),
            MobileTelNo: this.model.mobile,
            PostalCode: this.model.postcode,
            RooftopId: this.userService.selectedDealer ? this.userService.selectedDealer.RooftopId : this.configService.configSettings.dealerInclusion[0].dealerCode,
            SurName: this.model.surname,
            Address1: this.model.address1,
            Address2: this.model.address2,
            Address3: this.model.address3,
            Title: this.model.title,
            WorkTelNo: this.model.workNumber
        };
        if (this.isTitanDealer) {
            this.userService.updateCustomerInformationTitan(customerInfo).pipe(take(1)).subscribe(() => {
                this.userService.updateUserInfo(customerInfo);
                this.router.navigateByUrl('/profile/view');
            });
        } else {
            this.userService.updateCustomerInformation(customerInfo).pipe(take(1)).subscribe(result => {
                if (result.ErrorCode === 0) {
                    this.userService.updateUserInfo(customerInfo);
                    this.router.navigateByUrl('/profile/view');
                }
            });
        }
    }
}

class ProfileEditModel {
    firstName: string;
    surname: string;
    email: string;
    mobile: number | string;
    homeNumber: number | string;
    workNumber: number | string;
    title: string;
    address1: string;
    address2: string;
    address3: string;
    postcode: string;
}
