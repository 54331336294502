import { Component, OnInit, Input } from '@angular/core';
import { VehicleModel } from 'src/app/services/vehicle/vehicle.model';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { take } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-selected-vehicle',
  templateUrl: './selected-vehicle.component.html',
  styleUrls: ['./selected-vehicle.component.less']
})
export class SelectedVehicleComponent implements OnInit {
  selectedVehicle: VehicleModel;
  selectedDealerName: string;

  @Input() displayVehicleImage: boolean = true;

  constructor(
    private readonly userService: UserService,
    private readonly vehicleService: VehicleService,
    private readonly configService: ConfigService
  ) { }

  ngOnInit() {

    this.userService.SelectedVehicle$.subscribe(vehicle => {
      if (vehicle) {
        this.selectedVehicle = vehicle;
        this.getCozyImage(this.selectedVehicle);
        this.vehicleService.zaSelectedVehicle.next(this.selectedVehicle);
      }
    });

    if (this.userService.selectedVehicle) {
      this.selectedVehicle = this.userService.selectedVehicle;
      this.getCozyImage(this.selectedVehicle);
    }
    this.selectedDealerName = this.userService.selectedDealer.DealershipName;
  }

  getCozyImage(vehicle: VehicleModel) {

    // if (this.configService.configSettings.isCRM) {
    //   vehicle.vehicleImageUrl = vehicle.CosyImageURL
    // }
    if (this.configService.configSettings.isCRM ) {
      
      this.vehicleService.getVehicleCRMCosyImageUrl(vehicle).pipe(take(1)).subscribe((vehicleImage) => {
        vehicle.vehicleImageUrl = vehicleImage.cosyImageUrlList[0]
       
        this.vehicleService.zaSelectedVehicleImage.next(vehicleImage.cosyImageUrlList[0])
        // sessionStorage.setItem("selectedVehicleImage", vehicleImage.cosyImageUrlList[0])
        
      })
    }
    
     else {
      this.vehicleService.getVehicleCosyImageUrl(vehicle).pipe(take(1)).subscribe(result => {
        vehicle.vehicleImageUrl = `${result.url}`;
      });
    }
    
    
  }

}
