import { Component, OnInit, Input } from '@angular/core';
import { ProgressModel } from './progress-item.model';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.less']
})
export class ProgressIndicatorComponent implements OnInit {
  @Input() progressItems: ProgressModel[];

  isRtl: boolean;

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd)
          this.setIsFutureTask();
      });

    this.isRtl = this.translateService.currentLang.includes('ar_');
    this.translateService.onLangChange.subscribe(result => {
      this.isRtl = result.lang.includes('ar_');
    });

  }

  setIsFutureTask() {
    let currentTaskSet: boolean = false;
    for (const item of this.progressItems) {
      if (item.isComplete) {
        item.isFutureTask = false;
      } else if (!currentTaskSet) {
        item.isFutureTask = false;
        currentTaskSet = true;
      } else {
        item.isFutureTask = true;
      }
    }
  }

}
