import { Component, OnInit } from '@angular/core';
import {
  VehicleService,
  VehicleContract,
  TitanAddVehicleContract,
} from 'src/app/services/vehicle/vehicle.service';
import { VehicleModel } from 'src/app/services/vehicle/vehicle.model';
import { Model } from 'src/app/services/vehicle/model.model';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ErrorService } from 'src/app/services/error/error.service';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.less'],
})
export class AddVehicleComponent implements OnInit {
  searchBy: string = 'reg';
  vehicle: VehicleModel;
  searchState: SearchState = SearchState.Search;
  brandList: Brand[] = [];
  modelList: Model[];
  selectedBrand: string;
  selectedModel: string;
  showVinInfo: boolean;
  brandText: string;
  modelText: string;
  vinImg: boolean;
  vinRequired: boolean;
  regRequired: boolean;
  virOrRegInvalid: boolean;
  displayRegNumberMessage: boolean;
  allowManualAddVehicle: boolean;
  validationRequiredKeyProp = "validation-required"

  model: SearchModel = {
    registration: '',
    vin: '',
  };
  phoneNumber: string;
  emailAddress: string;
  showSearchRegMessage: boolean;
  isInTotalLossErrorState = false;
  isAbudhabi = false;
  isAr = false;
  isNotCRM = true;
  isSingapore = false

  constructor(
    private readonly vehicleService: VehicleService,
    private readonly userService: UserService,
    private readonly router: Router,
    public readonly configService: ConfigService,
    private readonly translate: TranslateService,
    private readonly bookingService: BookingService,
    private readonly errorService: ErrorService
  ) {}

  goToVehicleSearch() {
    this.model.registration = '';
    this.searchState = SearchState.Search;
  }

  ngOnInit() {

   this.isNotCRM = !this.configService.configSettings.isCRM
   this.isSingapore = this.configService.configSettings.language.includes('sg');


    if (this.configService.configSettings.communityId === "ADMLIVE" || this.configService.configSettings.communityId === "ADMUAT") {
      this.isAbudhabi = true;
    }
    this.isAr = this.configService.configSettings.language === "ar_ab" ?  true : false


    const currentLang = this.configService.configSettings.languages.find((lang) => {
      return this.configService.configSettings.language === lang.language
    })
    // console.log("currentLang-->", currentLang.direction);
    // this.isAr = currentLang.direction === 'rtr' ? true : false;

    this.showSearchRegMessage =
      this.configService.configSettings.showSearchRegMessage;
    this.phoneNumber = this.userService.selectedDealer.Telephone;
    this.emailAddress = this.userService.selectedDealer.EmailAddress;

    this.displayRegNumberMessage =
      this.configService.configSettings.displayRegNumberMessage;
    this.allowManualAddVehicle = !this.configService.configSettings.noManualAdd;
    this.vinRequired = this.configService.configSettings.vinRequired;
    this.regRequired = true;

    this.translate.get(['brand', 'model']).subscribe((result) => {
      this.brandText = result.brand;
      this.modelText = result.model;
    });
    // console.log("Manuel-->", this.allowManualAddVehicle);
    // debugger
    if (
      this.userService.selectedDealer &&
      (!this.userService.selectedDealer.TitanDealer ||
        this.configService.configSettings.useStaticModelList)
    ) {
      const rooftops = this.configService.configSettings.dealerInclusion.filter(
        (x) =>
          x.dealerCode ===
          String(this.userService.selectedDealer.RooftopId).trim()
      );
      const brands = rooftops.length > 0 ? rooftops[0].brands : undefined;
      // console.log("rofTops-->", rooftops);

      const bmwIFranchiseCode = brands
        ? brands.includes('I') ? 'I': 'E': 'E';

        // debugger
      if (this.configService.configSettings.oemId && !this.isSingapore) {
        this.brandList = this.configService.configSettings.oemId.includes('B')
        ? [
            {
              id: 'BM',
              name: 'BMW',
              franchise: 'B',
            },
            {
              id: 'BI',
              name: 'BMW i',
              franchise: bmwIFranchiseCode,
            },
          ]
        : [
            {
              id: 'MI',
              name: 'MINI',
              franchise: 'M',
            },
          ];
      } else if (this.configService.configSettings.oemId && this.isSingapore) {
        this.brandList = this.configService.configSettings.oemId.includes('B')
        ? [
            {
              id: 'BM',
              name: 'BMW',
              franchise: 'B',
            },

          ]
        : [
            {
              id: 'MI',
              name: 'MINI',
              franchise: 'M',
            },
          ];
      }

    } else {
      this.vehicleService
        .getMakeListTitan()
        .pipe(take(1))
        .subscribe((result) => {
          result
            .filter(
              (x) => x.Description === this.configService.configSettings.oemId
            )
            .forEach((make) => {
              this.brandList.push({
                id: make.Id.toString(),
                name: make.Description,
                franchise: make.MakeCode.toString(),
              });
            });
        });
    }


    if (this.configService.configSettings.vehicleSearchReg) {
      this.searchBy = 'reg';
    } else {
      this.searchBy = 'vin';
    }

    this.vinImg = this.configService.configSettings.vinImg;
  }

  searchVehicle(registration: string, vin: string) {
    // debugger
    if (
      this.userService.selectedDealer &&
      this.userService.selectedDealer.TitanDealer
    ) {
      this.vehicleService
        .getVehicleTitan(registration, vin)
        .subscribe((result) => {
          // console.log('resoo-->', result);
// debugger
          if (result && result.length > 0) {
            for (const res of result) {
              // TODO: REFACTOR
              if (
                res.makeCode + '7' ===
                this.configService.configSettings.titanMakeCode
              ) {
                this.searchState = SearchState.Found;
                this.vehicle = res;
                break;
              } else {
                this.searchState = SearchState.NotFound;
              }
            }
          } else {
            this.searchState = SearchState.NotFound;
          }
        });
    } else {
      this.vehicleService.getVehicle(registration, vin).subscribe(
        (result) => {
          // TODO: Refactor I and refactor Total Loss
          const toalLossVehicle =
            result[0].VehicleSpecDetails.VolVehicleDetails[0].AccidentDamage;
            // this.errorService.show(1000, false);

            if (toalLossVehicle && toalLossVehicle.Description === 'Total Loss') {
              this.isInTotalLossErrorState = true;
              this.searchState =SearchState.TotalLoss
            this.errorService.show(1000, false);
            return;
          }

          // Temporarily duplicating to delete
          if (toalLossVehicle && (toalLossVehicle.Code === 'T' || toalLossVehicle.Code === 'O')) {
            this.isInTotalLossErrorState = true;
            this.searchState =SearchState.TotalLoss
          this.errorService.show(1000, false);
          return;
        }

          // debugger
          if (result && result.length > 0) {
            // console.log("result-->", result);
            for (const res of result) {
              if (res.Result.ErrorCode === 0) {
                if (
                  res.VehicleSpecDetails.VolVehicleDetails[0].VIN &&
                  (res.VehicleSpecDetails.VolVehicleDetails[0].MakeCode ===
                    this.configService.configSettings.oemId.charAt(0) ||
                    res.VehicleSpecDetails.VolVehicleDetails[0].MakeCode.toUpperCase() ===
                      'I') &&
                  res.VehicleSpecDetails.VolVehicleDetails[0].RegNumber
                ) {

                  this.searchState = SearchState.Found;
                  this.vehicle = new VehicleModel(
                    res.VehicleSpecDetails.VolVehicleDetails[0]
                  );
                  break;
                }
                if (
                  this.configService.configSettings.language.indexOf('th') && res.VehicleSpecDetails.VolVehicleDetails[0].VIN &&
                  (res.VehicleSpecDetails.VolVehicleDetails[0].MakeCode ===
                    this.configService.configSettings.oemId.charAt(0) ||
                    res.VehicleSpecDetails.VolVehicleDetails[0].MakeCode.toUpperCase() ===
                      'I')
                ) {
                  this.searchState = SearchState.Found;
                  this.vehicle = new VehicleModel(
                    res.VehicleSpecDetails.VolVehicleDetails[0]
                  );
                  break;
                }
                else {
                  // debugger
                  this.searchState = SearchState.NotFound;
                }
              } else {
                // debugger
                this.searchState = SearchState.NotFound;
              }
            }
          } else {
            // debugger
            this.searchState = SearchState.NotFound;
          }
        },
        (error) => {
          this.searchState = SearchState.NotFound;
        }
      );
    }
  }

  selectBrand(brand: string) {
    // debugger
    this.selectedBrand = brand;
    this.modelList = [];
    if (
      this.userService.selectedDealer &&
      this.userService.selectedDealer.TitanDealer &&
      !this.configService.configSettings.useStaticModelList
    ) {
      this.vehicleService
        .getModelListTitan(
          this.brandList.find((x) => x.franchise === brand).name
        )
        .pipe(take(1))
        .subscribe((result) => {
          result.forEach((model) => {
            this.modelList.push({
              makeCode: this.brandList.find((x) => x.franchise === brand).id,
              modelName: model.Description,
              makeName: this.brandList.find((x) => x.franchise === brand).name,
              variantCode: model.Code,
              variantName: model.Description,
              modelCode: model.Code,
              id: model.Id,
            });
          });
        });
    } else {
      if (this.configService.configSettings.useStaticModelList) {
        this.modelList = this.bookingService
          .getStaticModelList()
          .filter((x) => x.makeCode === brand);
      } else {
        this.vehicleService
          .getMakeModelVariant(brand)
          .pipe(take(1))
          .subscribe((result) => {
            if (this.configService.configSettings.filterModelListBMW) {
              if (brand === 'B')
                this.modelList = result.filter(
                  (x) =>
                    x.makeCode.includes('B') &&
                    !x.variantName.toUpperCase().includes('GENERIC') &&
                    !x.variantName.toUpperCase().includes('TEST') &&
                    x.variantName.includes('BMW')
                );
              else if (brand === 'M')
                this.modelList = result.filter(
                  (x) =>
                    x.makeCode.includes('M') &&
                    !x.variantName.toUpperCase().includes('GENERIC')
                );
              else if (brand === 'E')
                this.modelList = result.filter(
                  (x) =>
                    x.makeCode.includes('E') &&
                    !x.variantName.toUpperCase().includes('GENERIC')
                );
            } else {
              this.modelList = result;
            }

            this.modelList = this.getUnique(this.modelList, 'variantName');
            this.modelList = this.modelList.sort((a, b) =>
              a.variantName < b.variantName ? -1 : 0
            );
          });
      }
    }
  }

  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }

  selectModel(model: string) {
    this.selectedModel = model;
  }

  addVehicle() {
    let vehicle: VehicleContract;

    if (!this.model.registration && !this.model.vin) {
      this.virOrRegInvalid = true;
      return;
    }

    if (
      this.userService.selectedDealer &&
      this.userService.selectedDealer.TitanDealer
    ) {

      if (this.searchState === SearchState.NotFound) {

        const model = this.modelList.find(
          (x) => x.variantCode === this.selectedModel
        );
        const titanVehicle: TitanAddVehicleContract = {
          Family: model.modelCode,
          Description: model.variantName,
          ModelId: this.modelList.find((x) => x.modelCode === model.modelCode)
            .id,
          MakeId: parseInt(
            this.brandList.find((x) => x.franchise === this.selectedBrand).id
          ),
          Vin: this.model.vin.toUpperCase(),
          DealershipId: parseInt(this.userService.selectedDealer.RooftopId),
          ManufactureId: this.brandList.find(
            (x) => x.franchise === this.selectedBrand
          ).id,
          OwnerCustomerId: this.userService.userInfo.CustomerId,
          RegoNo: this.model.registration.toUpperCase(),
        };
        this.vehicleService
          .addVehicleTitan(titanVehicle)
          .pipe(take(1))
          .subscribe(() => {
            const veh = {
              ChassisNo: titanVehicle.Vin,
              MakeName: this.brandList.find(
                (x) => x.franchise === this.selectedBrand
              ).name,
              ModelName: this.modelList.find(
                (x) => x.modelCode === model.modelCode
              ).modelName,
              FreeDesc: titanVehicle.Description,
              MakeCode: this.brandList.find(
                (x) => x.franchise === this.selectedBrand
              ).franchise,
              VariantName: model.variantName,
              RegistrationNumber: titanVehicle.RegoNo,
              VariantCode: model.variantCode,
              ModelCode: model.modelCode,
              ModelTextDescription: model.modelName,
              MakeId: parseInt(
                this.brandList.find((x) => x.franchise === this.selectedBrand)
                  .id
              ),
            };
            this.vehicleAdded(veh);
            // TODO
          });
      } else {
        this.vehicleService
          .linkVehicleTitan(this.vehicle.vehicleId)
          .pipe(take(1))
          .subscribe(() => {
            const veh = {
              ChassisNo: this.vehicle.chassisNo,
              MakeName: this.vehicle.makeName,
              ModelName: this.vehicle.modelName,
              FreeDesc: this.vehicle.modelTextDescription,
              MakeCode: this.vehicle.makeCode,
              VariantName: this.vehicle.variantName,
              RegistrationNumber: this.vehicle.registrationNumber,
              VariantCode: this.vehicle.variantCode,
              ModelCode: this.vehicle.modelCode,
              ModelTextDescription: this.vehicle.modelName,
              MakeId: this.vehicle.makeId,
              VehicleId: this.vehicle.vehicleId,
            };
            this.vehicleAdded(veh);
          });
      }
    } else {
      if (this.searchState === SearchState.NotFound) {
        const model = this.modelList.filter(
          (x) => x.variantCode === this.selectedModel
        )[0];
        if (this.configService.configSettings.switchAdvisorWorkflow) {
          this.vehicleService
            .getMakeModelVariant(this.selectedBrand, model.modelCode)
            .pipe(take(1))
            .subscribe((result) => {
              const varientCode = result[0].variantCode;
              const varientName = result[0].variantName;
              vehicle = {
                MakeCode: this.selectedBrand,
                MakeName: this.brandList.find(
                  (x) => x.franchise === this.selectedBrand
                ).name,
                ModelCode: model.modelCode,
                VariantCode: varientCode,
                VariantName: varientName,
                FreeDesc: model.variantName,
                ModelTextDescription: model.modelName,
                RegistrationNumber: this.model.registration.toUpperCase(),
                ChassisNo: this.model.vin.toUpperCase(),
              };
              this.vehicleService
                .addVehicle(vehicle)
                .pipe(take(1))
                .subscribe((result) => {
                  this.vehicleAdded(result);
                });
            });
        } else {
          // debugger
          vehicle = {
            MakeCode: this.selectedBrand,
            MakeName: this.brandList.find(
              (x) => x.franchise === this.selectedBrand
            ).name,
            ModelCode: this.configService.configSettings.useStaticModelList
              ? this.model.vin.toUpperCase().substring(3, 7)
              : model.modelCode,
            VariantCode: this.configService.configSettings.useStaticModelList
              ? this.model.vin.toUpperCase().substring(3, 7)
              : model.variantCode,
            VariantName: model.variantName,
            FreeDesc: model.variantName,
            ModelTextDescription: model.modelName,
            RegistrationNumber: this.model.registration.toUpperCase(),
            ChassisNo: this.model.vin.toUpperCase(),
          };
        }
      } else
        vehicle = {
          MakeCode: this.vehicle.makeCode,
          MakeName: this.vehicle.makeName,
          ModelCode: this.vehicle.modelCode,
          VariantCode: this.vehicle.variantCode,
          VariantName: this.vehicle.variantName,
          FreeDesc: this.vehicle.freeDesc,
          ModelTextDescription: this.vehicle.modelTextDescription,
          RegistrationNumber: this.vehicle.registrationNumber,
          ChassisNo: this.vehicle.chassisNo,
        };

      this.vehicleService
        .addVehicle(vehicle)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) this.vehicleAdded(result);
        });
    }
  }

  vehicleAdded(vehicle) {
    const newVehicle: VehicleModel = new VehicleModel(vehicle);
    this.userService.selectedVehicle = newVehicle;
    this.vehicleService.addVehicleToVehicleList(newVehicle);
    this.router.navigate([{ outlets: { popup: null } }]);
    // TODO
    // this.router.navigateByUrl('/select-service');
  }

  closePopup() {
    this.router.navigate([{ outlets: { popup: null } }]);
  }
}

class SearchModel {
  registration: string;
  vin: string;
}

class Brand {
  id: string;
  name: string;
  franchise: string;
}

enum SearchState {
  Search = 1,
  Found = 2,
  NotFound = 3,
  TotalLoss = 4,
}
