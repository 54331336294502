import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/services/user-account/login/login.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.less']
})

export class SubMenuComponent implements OnInit {
  @Output() vehicleSelected: EventEmitter<string> = new EventEmitter();
  showSubMenu: boolean;
  confirmLogout: boolean;
  expandMenu: boolean;
  dealerSelected: boolean = false;
  canLogOut: boolean;
  showProfileForCRM = true;
  isCRMIframe = false;
  showOnDealerSelect = true;
  isRtl: boolean;

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly loginService: LoginService,
    private readonly bookingService: BookingService,
    private readonly configService: ConfigService,
    private readonly vehicleService: VehicleService,
    private readonly translationService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.translationService.onLangChange.subscribe(result => {

      this.isRtl = result.lang.includes('ar_');
    });
    this.isCRMIframe = this.configService.configSettings.isCRM;
    this.userService.userInfo$.subscribe(result => {

      if (result !== undefined) {
        this.showSubMenu = true;
        this.canLogOut = true;
      }
    });
    this.canLogOut = this.userService.userInfo !== null;
    this.showSubMenu = this.userService.userInfo !== null;

    this.userService.selectedDealer$.subscribe(result => {
      // debugger

      this.dealerSelected = result;
    });


    if (this.configService.configSettings.isCRM) {

      // this.showSubMenu = true;
      // this.dealerSelected = true;
      // this.canLogOut = false;
      this.canLogOut = !this.configService.configSettings.isCRM;
      this.showProfileForCRM = false;

      this.vehicleService.zaVehicles.subscribe((vehicle) => {
        // console.log("vehilength-->", vehicle);

        if (vehicle.length === 0) {
          this.showSubMenu = false;
        }

        if (vehicle.length > 0) {
          this.showSubMenu = true;
        }

      })
      // if (this.userService.selectedDealer) {

      //   this.dealerSelected = true;
      // }
    }
    // this.canLogOut = !this.configService.configSettings.isCRM

    if (this.userService.selectedDealer)
    // console.log("selectedDealer", this.userService.selectedDealer);

    // console.log("selectedDealer-->", this.userService.selectedDealer);

      this.dealerSelected = true;

    this.loginService.userLoggedOut$.subscribe(result => {
      if (result)
        this.showSubMenu = false;
    });
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(`/${route}`);
  }

  logOut() {
    this.confirmLogout = true;
  }

  cancelLogOut() {
    this.confirmLogout = false;
  }

  bookAService() {
    this.bookingService.clearBookingData(true);
    this.navigateTo('select-dealer');
  }

  doLogOut() {
    if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
      this.bookingService.clearBookingData();
      this.confirmLogout = false;
      this.router.navigateByUrl('/user-account/login');
    } else {
      this.bookingService.clearBookingData();
      this.loginService.logOut().subscribe(result => {
        if (result) {
          this.confirmLogout = false;
          this.router.navigateByUrl('/user-account/login');
        }
      },
        () => {
          this.router.navigateByUrl('/user-account/login');
        });
    }
  }
}
