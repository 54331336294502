import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/services/config/config.model';
import { UserService } from 'src/app/services/user-account/user/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  expandMenu: boolean;
  homeUrl: string;
  servicesUrl: string;
  worldUrl: string;
  menuFiveUrl: string;
  allModelsUrl: string;
  brand: string;
  langauges: Language[];
  selectedLanguage: Language;
  isMalaysia = false;

  link_mini_fastlane_1: string;
  link_mini_fastlane_2: string;
  link_mini_fastlane_3: string;
  link_mini_fastlane_4: string;
  link_mini_fastlane_5: string;

  menu_bmw_header_link_1: string
  menu_bmw_header_link_2: string
  menu_bmw_header_link_3: string
  menu_bmw_header_link_4: string
  menu_bmw_header_link_5: string

  isSingapore: boolean;

  constructor(
    private readonly configService: ConfigService,
    private readonly translate: TranslateService,
    private readonly userService: UserService
  ) { }

  ngOnInit() {
    this.isSingapore = this.configService.configSettings.country === "Singapore"
    this.brand = this.configService.brand;
    this.langauges = this.configService.configSettings.languages;
    this.homeUrl = this.configService.configSettings.homeUrl;
    this.allModelsUrl = this.configService.configSettings.allModelsUrl;
    this.servicesUrl = this.configService.configSettings.servicesUrl;
    this.worldUrl = this.configService.configSettings.worldUrl;
    this.menuFiveUrl = this.configService.configSettings.menuFiveUrl;
    this.isMalaysia = this.configService.configSettings.language === 'en_my';

    this.getTranslations();
    this.translate.onLangChange.subscribe(() => {
      this.getTranslations();

    });

    if (!this.selectedLanguage) {
      this.selectedLanguage = this.langauges.filter(lang => lang.language === this.translate.getDefaultLang())[0];
      this.userService.languageSelected = this.selectedLanguage.language;
    }
  }

  setlanguage(langauge: Language) {
    // debugger
    this.translate.use(langauge.language);
    this.selectedLanguage = langauge;
    this.userService.languageSelected = this.selectedLanguage.language;

  }

  getTranslations() {
    this.translate.get(
      [
        'link_mini_fastlane_1',
        'link_mini_fastlane_2',
        'link_mini_fastlane_3',
        'link_mini_fastlane_4',
        'link_mini_fastlane_5',
        'menu_bmw_header_link_1',
        'menu_bmw_header_link_2',
        'menu_bmw_header_link_3',
        'menu_bmw_header_link_4',
        'menu_bmw_header_link_5'
      ]
    ).subscribe(result => {
      this.link_mini_fastlane_1 = result.link_mini_fastlane_1;
      this.link_mini_fastlane_2 = result.link_mini_fastlane_2;
      this.link_mini_fastlane_3 = result.link_mini_fastlane_3;
      this.link_mini_fastlane_4 = result.link_mini_fastlane_4;
      this.link_mini_fastlane_5 = result.link_mini_fastlane_5;
      this.menu_bmw_header_link_1 = result.menu_bmw_header_link_1;
      this.menu_bmw_header_link_2 = result.menu_bmw_header_link_2;
      this.menu_bmw_header_link_3 = result.menu_bmw_header_link_3;
      this.menu_bmw_header_link_4 = result.menu_bmw_header_link_4;
      this.menu_bmw_header_link_5 = result.menu_bmw_header_link_5;

    });
  }
}
