import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { VehicleModel } from 'src/app/services/vehicle/vehicle.model';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from '../../../services/booking/booking.service';
import { CustomerAppointments } from '../../../services/booking/booking.model';
import { ServiceContractService } from '../../shared/service-contract/service-contract.service';

@Component({
  selector: 'app-select-vehicle',
  templateUrl: './select-vehicle.component.html',
  styleUrls: ['./select-vehicle.component.less'],
})
export class SelectVehicleComponent implements OnInit {
  vehicleList: VehicleModel[] = [];
  vehicleIndex: number = 0;
  bookingsList: CustomerAppointments[];
  bookingsListTitan: CustomerAppointments[];
  @Output() vehicleSelected: EventEmitter<string> = new EventEmitter();
  isRtl: boolean;
  blockBooking: boolean;
  currentBookingNumber: string;
  currentBookingDate: string;
  nameSwitch: boolean;
  showButtonIfNotCRM = true;
  crmUserToken: string;
  crmUserSelectedVehicle;
  isNewZealand: boolean;
  featureReady = true;

  constructor(
    private readonly vehicleService: VehicleService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService,
    private readonly bookingService: BookingService,
    private readonly serviceContractService: ServiceContractService
  ) {}

  ngOnInit() {
    // console.log("->",this.configService.brand)
    // this.vehicleIndex = 2
    this.isNewZealand =
      this.configService.configSettings.country === 'New Zealand';

    if (this.configService.configSettings.isCRM) {
      // this.showButtonIfNotCRM = !this.configService.configSettings.isCRM
      this.showButtonIfNotCRM = false;
    }

    if (this.configService.configSettings.isCRM) {
      this.userService.crmUserToken.subscribe((userToken) => {
        this.crmUserToken = userToken;
      });

      this.userService.crmSelectedVehicle.subscribe((selectedVIN) => {
        this.crmUserSelectedVehicle = selectedVIN;
      });
    }
    this.isRtl = this.translateService.currentLang.includes('ar_');
    this.getNameSwitch(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((result) => {
      this.isRtl = result.lang.includes('ar_');
      this.getNameSwitch(result.lang);
    });

    this.vehicleService.VehicleList$.subscribe((vehicleResult) => {
      if (vehicleResult && vehicleResult.length > 0) {
        this.vehicleList = vehicleResult;
        // TODO: delete after vehicle list redesign
        console.log('vehicleResult-->', vehicleResult);

        this.userService.usersVehicleList.next(vehicleResult);

        this.vehicleList.forEach((vehicle) => this.getCosyImages(vehicle));
      } else if (
        this.vehicleService.vehicleList &&
        this.vehicleService.vehicleList.length > 0
      ) {
        this.vehicleList = this.vehicleService.vehicleList;
        this.vehicleList.forEach((vehicle) => this.getCosyImages(vehicle));
      }
    });

    this.vehicleService.VehicleDeleted$.subscribe((result) => {
      if (result) {
        this.vehicleList = this.vehicleList.filter(
          (x) => x.vehicleId !== result.vehicleId
        );
        this.setVehicleIndex(0);
      }
    });

    if (
      this.userService.selectedDealer &&
      this.userService.selectedDealer.TitanDealer
    ) {
      this.userService.userInfo$.subscribe((result) => {
        if (result)
          this.vehicleService
            .getVehicleListTitan(result.CustomerId)
            .pipe(take(1))
            .subscribe((result) => {
              if (!result || result.length === 0)
                // TODO:

                this.addVehicle();
            });
      });
      this.bookingService
        .getListOfAppointmentsTitan(this.userService.userInfo.CustomerId)
        .pipe(take(1))
        .subscribe((result) => {
          this.bookingsListTitan = result.CustomerAppointments.filter(
            (x) =>
              x.RoofTopId ===
              this.userService.selectedDealer.RooftopId.toString()
          );
        });
    } else {

      this.vehicleService
        .getMakeModelVariant(this.configService.brand)
        .pipe(take(1))
        .subscribe((result) => {
          this.vehicleService.VehicleAdded$.subscribe((result) => {
            if (result) {
              this.vehicleList.push(result);
              const index = this.vehicleList.findIndex(
                (x) => x.registrationNumber === result.registrationNumber
              );

              this.setVehicleIndex(index);
            }
          });

          const deepLinkVin = sessionStorage.getItem('deepLinkVin');
          const deepLinkRegNum  = sessionStorage.getItem('deepRegNum');

          if (this.featureReady && this.isNewZealand && deepLinkVin) {
            this.vehicleService.getVehicleList().subscribe((vehicleResult) => {
              // do not unsubscribe
              this.vehicleIndex = vehicleResult.findIndex(
                (vehicle) => vehicle.chassisNo === deepLinkVin.trim() ||  vehicle.registrationNumber.trim() === deepLinkRegNum
              );

              if (this.vehicleIndex >= 0) {
                this.selectVehicle();
              }

              if (!vehicleResult || vehicleResult.length === 0) {
                this.addVehicle();
              }

              if (this.vehicleIndex < 0) {
                this.vehicleIndex = 0;
                this.addVehicle();
              }
            });
          }

          if (this.configService.configSettings.isCRM) {
            this.vehicleService.getVehicleList().subscribe((vehicleResult) => {
              // do not unsubscribe

              if (this.configService.configSettings.isCRM) {
                if (this.crmUserSelectedVehicle.length > 2) {
                  this.vehicleIndex = vehicleResult.findIndex(
                    (vehicle) =>
                      vehicle.chassisNo === this.crmUserSelectedVehicle
                  );
                }
              }

              if (!vehicleResult || vehicleResult.length === 0) {
                this.addVehicle();
              }
            });
          } else {
            this.vehicleService.getVehicleList().subscribe((vehicleResult) => {
              this.setVehicleIndex(0)
              // do not unsubscribe

              // if (this.configService.configSettings.isCRM) {
              //   this.vehicleIndex = vehicleResult.findIndex(vehicle => vehicle.chassisNo === "WBAJU8100M9H84249");

              // }
              if (!vehicleResult || vehicleResult.length === 0) {
                this.addVehicle();
              }
            });
          }

          this.route.paramMap.subscribe((params: Params) => {
            if (params.params && params.params.vehicleId) {
              this.vehicleList = this.vehicleService.vehicleList;
              this.vehicleList.forEach((vehicle) =>
                this.getCosyImages(vehicle)
              );
              const index = this.vehicleList.findIndex(
                (x) =>
                  x.vehicleId.toString() === params.params.vehicleId.toString()
              );
              this.setVehicleIndex(index);
              this.selectVehicle();
            }
            if (params.params && params.params.closeModal) {
              this.router.navigate([{ outlets: { popup: null } }]);
            }
          });
        });
    }
  }

  getCosyImages(vehicle: VehicleModel) {
    // debugger
    if (this.configService.configSettings.isCRM) {
      this.vehicleService
        .getVehicleCRMCosyImageUrl(vehicle)
        .pipe(take(1))
        .subscribe((vehicleImage) => {
          vehicle.vehicleImageUrl = vehicleImage.cosyImageUrlList[0];
        });

      // vehicle.vehicleImageUrl = vehicle.CosyImageURL
    } else {
      this.vehicleService
        .getVehicleCosyImageUrl(vehicle)
        .pipe(take(1))
        .subscribe((result) => {
          vehicle.vehicleImageUrl = `${result.url}`;
        });
    }
  }

  setVehicleIndex(index) {
    this.blockBooking = false
    this.vehicleIndex = index;
    if (this.configService.configSettings.hasServiceContracts && this.vehicleList.length > 0 ) {
      this.serviceContractService
        .getServiceContractItems(this.vehicleList[index].chassisNo)
        .subscribe((vehicleContract) => {
          this.vehicleService.vehicleServiceContracts.next(vehicleContract);
        });
    }
  }

  getNameSwitch(setLang: string) {
    this.nameSwitch =
      this.configService.configSettings.languages.find(
        (x) => x.language === setLang && x.switch
      ) !== undefined;
  }

  selectVehicle() {
    let selectedVehicle: VehicleModel;
    // if (this.isRtl)
    //   selectedVehicle = this.vehicleList.reverse()[this.vehicleIndex];
    // else

    selectedVehicle = this.vehicleList[this.vehicleIndex];

    if (
      this.configService.configSettings.preventMultipleBookings &&
      !this.userService.bookingId
    ) {
      this.bookingService
        .getListOfAppointments(selectedVehicle.vehicleId)
        .pipe(take(1))
        .subscribe((result) => {
          if (!result.CustomerAppointments) {
            this.doSelectVehicle(selectedVehicle);
          } else {
            const booking = result.CustomerAppointments.find(
              (x) => new Date(x.JobDate) >= new Date()
            );
            if (booking) {
              this.blockBooking = true;
              this.currentBookingNumber = booking.WIPNo.toString();
              this.currentBookingDate = booking.JobDate;
            } else {
              this.blockBooking = false;
              this.doSelectVehicle(selectedVehicle);
            }
          }
        });
    } else {
      this.doSelectVehicle(selectedVehicle);
    }
  }

  doSelectVehicle(selectedVehicle: VehicleModel) {
    if (
      !this.userService.bookingId &&
      this.userService.selectedDealer &&
      !this.userService.selectedDealer.TitanDealer
    ) {
      /** remove delivery address  */
      this.userService.selectedServiceMobilityDeliveryOptionAddress = '';
      this.userService.selectedServiceMobilityOptionAddress = '';
      /**End of remove delivery address*/
      this.bookingService
        .createBooking()
        .pipe(take(1))
        .subscribe((result) => {
          this.userService.bookingId = result;
          this.setSelectedVehciel(selectedVehicle);
        });
    } else this.setSelectedVehciel(selectedVehicle);
  }

  setSelectedVehciel(selectedVehicle: VehicleModel) {
    this.userService.selectedVehicle = selectedVehicle;
    this.vehicleSelected.emit('steps-vehicle-selection');
  }

  addVehicle() {
    this.router.navigate([{ outlets: { popup: ['add-vehicle'] } }]);
  }

  deleteVehicle() {
    if (
      this.userService.selectedDealer &&
      this.userService.selectedDealer.TitanDealer
    ) {
      this.bookingsList = this.bookingsListTitan.filter(
        (x) =>
          x.VehicleChassisNo ===
            this.vehicleList[this.vehicleIndex].chassisNo &&
          x.VehicleRegistrationNumber ===
            this.vehicleList[this.vehicleIndex].registrationNumber
      );
      if (this.bookingsList.length) {
        this.router.navigate([{ outlets: { popup: ['vehicle-bookings'] } }]);
      } else {
        this.router.navigate([
          {
            outlets: {
              popup: [
                'delete-vehicle',
                this.vehicleList[this.vehicleIndex].registrationNumber,
              ],
            },
          },
        ]);
      }
    } else {
      this.bookingService
        .getListOfAppointments(this.vehicleList[this.vehicleIndex].vehicleId)
        .pipe(take(1))
        .subscribe((result) => {
          if (result.Result.ErrorCode === 0) {
            this.bookingsList = result.CustomerAppointments.filter(
              (x) => x.RoofTopId === this.userService.selectedDealer.RooftopId
            );
            if (this.bookingsList.length) {
              this.router.navigate([
                { outlets: { popup: ['vehicle-bookings'] } },
              ]);
            } else {
              this.router.navigate([
                {
                  outlets: {
                    popup: [
                      'delete-vehicle',
                      this.vehicleList[this.vehicleIndex].registrationNumber,
                    ],
                  },
                },
              ]);
            }
          } else {
            this.router.navigate([
              {
                outlets: {
                  popup: [
                    'delete-vehicle',
                    this.vehicleList[this.vehicleIndex].registrationNumber,
                  ],
                },
              },
            ]);
          }
        });
    }
  }
}
