import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

export class TitleService {
    titleList: Tranlsation[];

    constructor(private readonly translationService: TranslateService) {
        this.translationService.onLangChange.subscribe(() => {
            this.getTitleList();
        });
    }

    getShortTitles(): Tranlsation[] {
        return this.titleList.filter(x => x.key.indexOf('title_sh') > -1);
    }

    getExtendedTitles(): Tranlsation[] {
        return this.titleList.filter(x => x.key.indexOf('title_ext') > -1);
    }

    getTitleList() {
        setTimeout(() => {
            const translations = this.translationService.translations[this.translationService.currentLang];
            this.titleList = [];
            for (const tranlsation of Object.keys(translations)) {
                if (tranlsation.indexOf('title') > -1)
                    this.titleList.push({
                        key: tranlsation,
                        value: translations[tranlsation]
                    });
            }
        }, 2000);

    }
}

export class Tranlsation {
    key: string;
    value: string;
}
