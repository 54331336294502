import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-mini-colors',
    template: '<div></div>',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./../../../styles/controls-mini.less']
})
export class MINIColorsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}