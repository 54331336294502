import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user-account/user/user.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServiceDatesModel } from './service-dates.model';
import { OptionsModel } from './option.model';
import { DateFormatter } from 'src/app/helpers/date-format.helper';
import { SabOptionsModel } from './sab-options.model';
import { utc } from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OptionsService {
    sabAvailability: SabOptionsModel;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly userService: UserService,
        private readonly configService: ConfigService
    ) { }

    GetAppointmentDates(): Observable<ServiceDatesModel> {
        const serviceDates: ServiceDatesModel = new ServiceDatesModel();
        return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetAppointmentDates`
            , new GetAppointmentDatesContract(this.userService, this.configService)
        ).pipe(map(result => {
            if (result.Result.ErrorCode === 0) {
                if (this.configService.configSettings.ignoreWorkshopCapacity) {
                    result.WorksDiaryDetail.forEach(date => {
                        const non = [...result.NonWorkingDates];
                        if (non.includes(date.Date))
                            serviceDates.nonWorkingDates.push(new Date(date.Date));
                        else
                            serviceDates.workingDates.push(new Date(date.Date));
                    });
                } else {
                    result.WorksDiaryDetail.forEach(date => {
                        if (date.CapacityFree > 0)
                            serviceDates.workingDates.push(new Date(date.Date));
                        else
                            serviceDates.nonWorkingDates.push(new Date(date.Date));
                    });
                }
            }
            return serviceDates;
        }));
    }

    GetAppointmentDatesTitan(startDate: Date, endDate: Date): Observable<ServiceDatesModel> {
        const datedModel: ServiceDatesModel = new ServiceDatesModel();
        let leadTimeStartDate = new Date();
        leadTimeStartDate.setDate(leadTimeStartDate.getDate() + 1);

        return this.httpClient.get<UnavailableServiceDatesModelTitan>(`${environment.titanUrl}Booking/UnavailableBookingDays?locationId=${this.userService.selectedLocation.Id}`).pipe(map(result => {
            for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                if (result.UnavailableDaysOfWeek.find(x => x === d.getDay()) === undefined)
                    if (new Date(d) > leadTimeStartDate)
                        datedModel.workingDates.push(new Date(d));
            }
            datedModel.nonWorkingDates = result.UnavailableDates;
            return datedModel;
        }));
    }

    GetAvailableDropOffTimesTitan(date: Date): Observable<Date[]> {
        const dateList: Date[] = [];
        const bookingDate: string = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
        return this.httpClient.get<string[]>(`${environment.titanUrl}Booking/TimeSlots?locationId=${this.userService.selectedLocation.Id}&bookingDate=${bookingDate}`).pipe(map(result => {
            result.forEach(x => {
                const timeSlot = new Date(x);
                if (timeSlot > new Date())
                    dateList.push(timeSlot);
            });
            return dateList;
        }));
    }

    GetAvailableDropOffTimes(date: Date): Observable<OptionsModel[]> {
        const serviceDates: OptionsModel[] = [];
        return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetAppOptionsAndTime`
            , new GetAppOptionsAndTimeContract(this.userService, date)
        ).pipe(map(result => {
            if (result.Result.ErrorCode === 0) {
                result.Option.forEach(option => {
                    serviceDates.push(new OptionsModel(option));
                });
            }
            return serviceDates;
        }));
    }

    getTimeSlots(date, dealerId) {
        const intervals = [];
        const dealerInfo = this.configService.configSettings.dealerInclusion.filter(dealer => dealer.dealerCode === dealerId)[0];
        const interval = dealerInfo && dealerInfo.levelOne ? dealerInfo.levelOne.intervals : this.configService.configSettings.nonAutoline.intervals;
        const levelOneInfo = dealerInfo && dealerInfo.levelOne ? dealerInfo.levelOne : this.configService.configSettings.nonAutoline;

        let specialDay = !!levelOneInfo.specialDays ?
            levelOneInfo.specialDays.filter(day => day.day === date.getDay())[0] :
            !!this.configService.configSettings.nonAutoline.specialDays ?
                this.configService.configSettings.nonAutoline.specialDays.filter(day => day.day === date.getDay())[0] : undefined;

        let startTime = utc().set({
            hour: !!specialDay ? parseInt(specialDay.startTime.split(':')[0]) : parseInt(levelOneInfo.startTime.split(':')[0]),
            minute: !!specialDay ? parseInt(specialDay.startTime.split(':')[1]) : parseInt(levelOneInfo.startTime.split(':')[1])
        });
        let endTime = utc().set({
            hour: !!specialDay ? parseInt(specialDay.endTime.split(':')[0]) : parseInt(levelOneInfo.endTime.split(':')[0]),
            minute: !!specialDay ? parseInt(specialDay.endTime.split(':')[1]) : parseInt(levelOneInfo.endTime.split(':')[1])
        });

        let exclusions = !!specialDay ? specialDay.exclusionTimes : levelOneInfo.exclusionTimes || [];

        while (startTime.format('HH:mm') < endTime.format('HH:mm')) {
            let pushToArray = true;
            exclusions.forEach(slot => {

                let excludeTimeslotStart = utc().set({
                    hour: parseInt(slot.startTime.split(':')[0]),
                    minute: parseInt(slot.startTime.split(':')[1])
                });
                let excludeTimeslotEnd = utc().set({
                    hour: parseInt(slot.endTime.split(':')[0]),
                    minute: parseInt(slot.endTime.split(':')[1])
                });

                if (startTime.format('HH:mm') >= excludeTimeslotStart.format('HH:mm') && startTime.format('HH:mm') < excludeTimeslotEnd.format('HH:mm')) {
                    pushToArray = false;
                    startTime.add(interval, 'minutes')
                }
            });
            if (pushToArray)
                intervals.push(`${startTime.format('HH:mm')} - ${startTime.add(interval, 'minutes').format('HH:mm')}`);
        }
        return intervals;
    }


}

class UnavailableServiceDatesModelTitan {
    UnavailableDates: Date[];
    UnavailableDaysOfWeek: number[];
}

class GetAppOptionsAndTimeContract {
    constructor(userService: UserService, jobDate: Date) {
        this.RooftopId = userService.selectedDealer.RooftopId;
        this.Language = userService.languageSelected.substring(0, 2);
        if (userService.selectedServices)
            userService.selectedServices.forEach(x => {
                this.JobCode.push(x.jobCode.toString());
            });
        // this.JobCode = ["51"]
        this.JobDate = DateFormatter.formatDate(jobDate);
        this.ModelCode = userService.selectedVehicle.modelCode;
        this.DisregardWipNo = userService.wipNumberToUpdate;
    }

    RooftopId: string;
    Language: string;
    JobCode: string[] = [];
    JobDate: string;
    ModelCode: string;
    DisregardWipNo: number;
}

class GetAppointmentDatesContract {
    constructor(userService: UserService, configService: ConfigService) {
        this.RooftopId = userService.selectedDealer.RooftopId;
        if (userService.selectedServices)
            userService.selectedServices.forEach(x => {
                this.JobCode.push(x.jobCode.toString());
            });
        if (userService.serviceNotes && userService.serviceNotesItem) {
            userService.serviceNotesItem.jobComments = userService.serviceNotes;
            this.JobCode.push(
                userService.serviceNotesItem.jobCode.toString(),
            );
        }
        this.InitialDate = DateFormatter.formatDate(new Date());
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + configService.configSettings.calendarDays);
        this.EndDate = DateFormatter.formatDate(endDate);
        this.DisregardWipNo = userService.wipNumberToUpdate;
        this.Language = userService.languageSelected.substring(0, 2);
    }

    RooftopId: string;
    Language: string;
    JobCode: string[] = [];
    InitialDate: string;
    EndDate: string;
    DisregardWipNo: number;
}
