import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.less']
})
export class UserAccountComponent implements OnInit {
  register: boolean;
  altImage: boolean;
  sgMiniImage: boolean;
  sgBMWImage: boolean;
  constructor(
    private readonly router: Router,
    private readonly configService: ConfigService
  ) { }

  ngOnInit() {
    this.altImage = this.configService.configSettings.altImage;
    this.sgMiniImage = this.configService.configSettings.language.indexOf('sg') > -1 && this.configService.brand === "MINI";
    this.sgBMWImage = this.configService.configSettings.language.indexOf('sg') > -1 && this.configService.brand === "BMW";
    this.register = this.router.url.indexOf('register') > -1;
    this.router.events.subscribe(() => {
      this.register = this.router.url.indexOf('register') > -1;
    });
  }
}
